import React, { useEffect, useState } from "react";
import { getData } from "../util/api-calls";
export const UserContext = React.createContext({});
export const UserContextProvider = ({ children = {} }) => {
  const [userAccessToken, setUserAccessToken] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [accountDetail , setAccountDetail] = useState({})
  let user_id = localStorage.getItem("u_id")
  const getAccountDetails = () => {
    getData(`admin/user/account/${user_id}`).then((res)=>{
      setAccountDetail(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  useEffect(()=>{
      if(user_id !== null){
      getAccountDetails()
      }
  },[])
  const defaultContext = {
  userAccessToken,
  setUserAccessToken,
  setUser,accountDetail
  };

  return (
    <UserContext.Provider value={defaultContext}>
      {children}
    </UserContext.Provider>
  );
};
