import React, { useState, useRef, useContext } from "react";
import "./posts.scss";
import dayjs from "dayjs";
import { Col, Row, Progress, Select, DatePicker, Button } from "antd";
import Plus from "../../../assets/images/plus-squared.svg";
import Calendar from "../../../assets/images/calendar.svg";
import Comment from "../../../assets/images/comment.svg";
import Profile from "../../../assets/images/avatar.svg";
import Play from "../../../assets/images/play.svg";
import Video from "../../../assets/Snail.mp4";
import { PostsContextProvider , PostsContext } from "../../../context/group-context/posts-context";

const PostsPage = () => {
  const {createPost} = useContext(PostsContext)
  const [playButton, setPlayButton] = useState(true);
  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    setPlayButton(false);
    vidRef.current.play();
  };

  const dateFormatList = "DD MMM YYYY";

  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => console.log(value);
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <div className="posts-main">
      <h1>Posts & Comments</h1>
      {/* <Button onClick={createPost}>Create</Button> */}
      <hr />
      <div
        style={{ display: "flex", paddingTop: "40px", marginBottom: "24px" }}
      >
        <h2>
          <span>15 Post(s) </span>
          <span style={{ color: "#7e8299" }}>in</span>
          <span> Woodview High School</span>
        </h2>
        <div style={{ marginLeft: "auto" }}>
          <Select
            showSearch
            placeholder="Newly Added"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            className="select-1"
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
          <DatePicker
            picker="month"
            className="month-picker"
            suffixIcon={<img src={Calendar} />}
            defaultValue={dayjs("01 JAN 2015", dateFormatList)}
            format={dateFormatList}
          />
          <DatePicker
            onChange={onChange}
            picker="year"
            className="year-picker"
            placeholder="Year"
          />
        </div>
      </div>
      <div className="posts">
        <div style={{ display: "flex" }}>
          <img src={Profile}></img>
          <div className="name-div">
            <h3 className="user-name">Jacob Jones</h3>
            <h4 className="date">Yestarday at 5:06 PM</h4>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          {playButton && (
            <button className="play-btn" onClick={handlePlayVideo}>
              <img src={Play} />
            </button>
          )}
          <video ref={vidRef}>
            <source src={Video} type="video/mp4" />
          </video>
        </div>
        <div className="content">
          Outlines keep you honest. They stop you from indulging in poorly
          thought-out metaphors about driving and keep you focused on the
          overall structure of your post
        </div>
        <div className="comments">
          <img src={Comment} />
          <div>120</div>
        </div>
      </div>
      <div className="posts">
        <div style={{ display: "flex" }}>
          <img src={Profile}></img>
          <div className="name-div">
            <h3 className="user-name">Jacob Jones</h3>
            <h4 className="date">Yestarday at 5:06 PM</h4>
          </div>
        </div>
        <div className="content">
          Outlines keep you honest. They stop you from indulging in poorly
          thought-out metaphors about driving and keep you focused on the
          overall structure of your post
        </div>
        <div className="comments">
          <img src={Comment} />
          <div>120</div>
        </div>

        <div className="comment-box">
          <div style={{ marginTop: "43px", display: "flex" }}>
            <img src={Profile}></img>
            <div className="name-div">
              <h3 className="user-name">
                Jacob Jones <span className="time">1 day(s) ago</span>{" "}
              </h3>
              <h4 className="comment">
                Long before you sit dow to put digital pen to paper you need to
                make sure you have to sit down and write
              </h4>
            </div>
            <span className="reply">Reply</span>
          </div>
          <div style={{ marginTop: "43px", display: "flex" }}>
            <img src={Profile}></img>
            <div className="name-div">
              <h3 className="user-name">
                Jacob Jones <span className="time">1 day(s) ago</span>{" "}
              </h3>
              <h4 className="comment">
                Long before you sit dow to put digital pen to paper you need to
                make sure you have to sit down and write
              </h4>
            </div>
            <span className="reply">Reply</span>
          </div>
          <div style={{ marginTop: "43px", display: "flex" }}>
            <img src={Profile}></img>
            <div className="name-div">
              <h3 className="user-name">
                Jacob Jones <span className="time">1 day(s) ago</span>{" "}
              </h3>
              <h4 className="comment">
                Long before you sit dow to put digital pen to paper you need to
                make sure you have to sit down and write
              </h4>
            </div>
            <span className="reply">Reply</span>
          </div>
        </div>
      </div>
      <div className="posts">
        <div style={{ display: "flex" }}>
          <img src={Profile}></img>
          <div className="name-div">
            <h3 className="user-name">Jacob Jones</h3>
            <h4 className="date">Yestarday at 5:06 PM</h4>
          </div>
        </div>
        <div className="content">
          Outlines keep you honest. They stop you from indulging in poorly
          thought-out metaphors about driving and keep you focused on the
          overall structure of your post
        </div>
        <div className="comments">
          <img src={Comment} />
          <div>120</div>
        </div>
      </div>
      <div style={{textAlign:"center"}}>
      <button className="red-base-btn">
      More Feeds
            </button>
      </div>

    </div>
  );
};



const Posts = () => {
  return (
    <PostsContextProvider>
      <PostsPage />
    </PostsContextProvider>
  );
};
export default Posts;
