import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Radio,
  Select,
  Upload,
  DatePicker,
  Divider,
  Spin,
  InputNumber,
} from "antd";
import "./create-profile.scss";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import Calendar from "../../../assets/images/calendar.svg";
import { useNavigate } from "react-router-dom";
import {
  CreateProfileContext,
  CreateProfileContextProvider,
} from "../../../context/user-context/user-context";
import DownArrow from "../../../assets/images/arrows.svg";
const dateFormat = "ddd. MMMM D, YYYY";

const formItemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};
const CreateProfilePage = () => {
  const {
    createProfile,
    type,
    setImageUrl,
    imgSrc,
    setImgSrc,
    loading,
    sportsList,
    setSport,
    profileType,
    setProfileType,
    gender,
    setGender,
    shootingHand,
    setShootingHand,
    imageUpload,
    setImageUpload,
    nameError,
    setUsername,
  } = useContext(CreateProfileContext);
  const navigate = useNavigate();
  const [bsaCredit, setBsaCredit] = useState();
  const [newSport, setNewSport] = useState(false);
  const [form] = Form.useForm();
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    setImageUpload(false);
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  };
  let sportOption = [];
  for (let i = 0; i < sportsList.length; i++) {
    sportOption.push({
      value: sportsList[i].id,
      label: sportsList[i].name,
    });
  }
  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  return (
    <>
      {loading && loading == true ? (
        <Spin
          className="loader"
          size="large"
          style={{
            position: "absolute",
            left: "49%",
            top: "39%",
          }}
        />
      ) : (
        <div className="create-profile-main">
          <div className="heading-row">
          <FaArrowLeftLong className= "left-arrow" onClick={() => navigate("/home/users")} />
            <h1>Create New Profile</h1>
          </div>
          <Form
            form={form}
            name="dynamic_rule"
            className="profile-form"
            onFinish={createProfile}
            // initialValues={{
            //   type: type,
            //   level: "Level 1: Beginners (age < 10)",
            //   sport: "Basketball",
            //   bsa_credit: "none",
            //   "members-limit": false,
            //   display_announcements_section: true,
            //   display_post_section: true,
            //   allow_members_post: "owners",
            //   accessible_leaderboards: ["display_workout_leaderboard"],
            // }}
          >
            <Form.Item
              {...formItemLayout}
              name="avatar"
              label="Display Photo"
              className="img-div"
            >
              <div className="image-previewer">
                {!imgSrc ? (
                  <Upload
                    className="uploader"
                    showUploadList={false}
                    type="file"
                    customRequest={dummyRequest}
                    beforeUpload={handleBeforeUpload}
                  >
                    Upload pic
                  </Upload>
                ) : (
                  <>
                    <img
                      src={imgSrc}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      className="img"
                    />
                    <Upload
                      className="avatar-uploader"
                      showUploadList={false}
                      type="file"
                      customRequest={dummyRequest}
                      beforeUpload={handleBeforeUpload}
                    >
                      <img src={Edit} alt="edit" className="edit"></img>
                    </Upload>
                    <img
                      src={Close}
                      alt="close"
                      className="delete"
                      onClick={removeImage}
                    />
                  </>
                )}
              </div>

              <span className="img-type">
                Allowed file types: PNG, JPG, JPEG.
              </span>
              {imageUpload === true ? (
                <span className="error-message" style={{ color: "red" }}>
                  Please upload image
                </span>
              ) : (
                ""
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="profile_type"
              label="Profile Type"
              className="shooting-div"
            >
              <Radio.Group
                onChange={(e) => setProfileType(e.target.value)}
                value={profileType}
                defaultValue={"player"}
                className="custom-radio"
              >
                <Radio value={"player"}>Player</Radio>
                <Radio value={"coach"}>Coach</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="first_name"
              label="First Name"
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter first name.",
                },
              ]}
            >
              <Input
                placeholder="Please input first name"
                className="custom-input"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="last_name"
              label="Last Name"
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter last name.",
                },
              ]}
            >
              <Input
                placeholder="Please input last name"
                className="custom-input"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="username"
              label="User Name"
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter username.",
                },
              ]}
            >
              <Input
                placeholder="Please input username"
                className="custom-input"
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Item>
            {nameError && (
              <span
                className="error-message"
                style={{ marginBottom: 20, marginLeft: 293 }}
              >
                Username already exists
              </span>
            )}
            <Form.Item
              {...formItemLayout}
              name="dob"
              label="Date of Birth"
              rules={[
                {
                  required: true,
                  message: "Please input date of birth",
                },
              ]}
            >
              <DatePicker
                className="date-picker"
                // defaultValue={dayjs("Jan 01, 2022", dateFormat)}
                format={dateFormat}
                suffixIcon={<img src={Calendar} />}
                style={{ height: "48px" }}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="gender"
              label="Gender"
              className="gender-div"
              rules={[
                {
                  required: false,
                  message: "Please input your name",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => setGender(e.target.value)}
                value={gender}
                defaultValue={"male"}
                // defaultChecked={"male"}

                className="custom-radio"
              >
                <Radio value={"male"}>Male</Radio>
                <Radio value={"female"}>Female</Radio>
                <Radio value={"undisclosed"}>Undisclosed</Radio>
              </Radio.Group>
            </Form.Item>
            {profileType === "player" ? (
              <>
                <Form.Item
                  {...formItemLayout}
                  label="Measurements"
                  className="measurements-input"
                >
                  <div style={{ display: "flex", gap: 8 }}>
                    <label style={{ padding: "15px 0" }}>Height:</label>
                    <Form.Item
                      name="height_feet"
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          max: 8,
                          pattern: new RegExp("^[0-9]*$"),
                          message: "Feet must be between ${min} to ${max}.",
                        },
                      ]}
                    >
                      <InputNumber
                        addonBefore={"ft"}
                        placeholder="Feet"
                        type="number"
                        onKeyDown={(e) => e.key === "." && e.preventDefault()}
                      />
                    </Form.Item>
                    <Form.Item
                      name="height_inch"
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          max: 11,
                          message: "Inches must be between ${min} to ${max}.",
                        },
                      ]}
                    >
                      <InputNumber
                        addonBefore={"in"}
                        placeholder="Inch"
                        type="number"
                        onKeyDown={(e) => e.key === "." && e.preventDefault()}
                      />
                    </Form.Item>
                    {/* <Form.Item
                  name="height_dec"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      max: 75,
                      message: "Decimal must be between ${min} to ${max}.",
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={"dec"}
                    placeholder="Decimal"
                    type="number"
                    onKeyDown={(e) => e.key === "." && e.preventDefault()}
                  />
                </Form.Item> */}
                  </div>
                  <div className="weight-div">
                    <Form.Item
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <div style={{ display: "flex", gap: 8 }}>
                        <label style={{ padding: "15px 0" }}>Weight:</label>
                        <Form.Item
                          name="weight_lbs"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: false,
                              message: "Please input weight",
                            },
                          ]}
                        >
                          <InputNumber
                            addonBefore={"lbs"}
                            placeholder="Lbs"
                            className="weight-input"
                            type="number"
                            onKeyDown={(e) =>
                              e.key === "." && e.preventDefault()
                            }
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </div>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  {...formItemLayout}
                  name="school"
                  label="School/Program"
                  className="name-row"
                  rules={[
                    {
                      required: false,
                      message: "Please enter school/program.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please input school/program"
                    className="custom-input"
                    // onChange={(e)=>setUsername(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="job_title"
                  label="Job Title"
                  className="name-row"
                  rules={[
                    {
                      required: false,
                      message: "Please enter job title.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please input job title"
                    className="custom-input"
                    // onChange={(e)=>setUsername(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="years_experience"
                  label="Year"
                  className="name-row"
                  rules={[
                    {
                      required: false,
                      message: "Please enter year.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Years"
                    className="weight-input"
                    // type="number"
                    style={{ width: "100%", height: 48 }}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              {...formItemLayout}
              name="location"
              className="location-div"
              label="Location"
              rules={[
                {
                  required: false,
                  message: "Please input your location",
                },
              ]}
            >
              <Input
                placeholder="Please input location"
                className="custom-input"
              />

              {/* <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Please input your name",
                  },
                ]}
              >
                <Select
                  placeholder="Select a state"
                  optionFilterProp="children"
                  // onChange={onChangeSelect}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  suffixIcon={<img src={DownArrow} />}
                  className="location-select"
                  options={[
                    {
                      value: "jack",
                      label: "Jack",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "tom",
                      label: "Tom",
                    },
                  ]}
                />
              </Form.Item> */}
              {/* <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Please input your name",
                  },
                ]}
              >
                <Select
                  placeholder="Select a city"
                  optionFilterProp="children"
                  // onChange={onChangeSelect}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  suffixIcon={<img src={DownArrow} />}
                  className="location-select"
                  options={[
                    {
                      value: "jack",
                      label: "Jack",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "tom",
                      label: "Tom",
                    },
                  ]}
                />
              </Form.Item> */}
            </Form.Item>
            <Divider />

            <h2>Sports</h2>
            {/* <h3>Sport1</h3> */}
            <Form.Item
              {...formItemLayout}
              name="sport"
              label="Sport"
              className="sport-div"
              rules={[
                {
                  required: true,
                  message: "Please select sport",
                },
              ]}
            >
              <Select
                placeholder="Select sport"
                optionFilterProp="children"
                // onChange={onChangeSelect}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                suffixIcon={<img src={DownArrow} />}
                className="location-select"
                options={sportOption}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="sport_level"
              className="location-select"
              label="Skill Level"
              rules={[
                {
                  required: true,
                  message: "Please select level",
                },
              ]}
            >
              <Select
                placeholder="Level 2: Intermediate (age 11-15)"
                optionFilterProp="children"
                // onChange={onChangeSelect}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                suffixIcon={<img src={DownArrow} />}
                className="location-select"
                options={[
                  {
                    value: "beginner",
                    label: "Level 1: Beginners (age < 10)",
                  },
                  {
                    value: "intermediate",
                    label: "Level 2: Intermediate (age 11-15)",
                  },
                  {
                    value: "advanced",
                    label: "Level 3: Advance (age 16+)",
                  },
                ]}
              />
            </Form.Item>
            {profileType === "player" && (
              <Form.Item
                {...formItemLayout}
                name="shooting_hand"
                label="Shooting Hand"
                className="shooting-div"
              >
                <Radio.Group
                  onChange={(e) => setShootingHand(e.target.value)}
                  value={shootingHand}
                  defaultValue={"right"}
                  className="custom-radio"
                >
                  <Radio value={"right"}>Right</Radio>
                  <Radio value={"left"}>Left</Radio>
                </Radio.Group>
              </Form.Item>
            )}
            <Form.Item
              {...formItemLayout}
              name="positions"
              label="Positions"
              className="sport-div position-div"
            >
              <Checkbox.Group className="custom-checkbox">
                <Checkbox value="pg">PG</Checkbox>
                <Checkbox value="sg">SG</Checkbox>
                <Checkbox value="sf">SF</Checkbox>
                <Checkbox value="pf">PF</Checkbox>
                <Checkbox value="c">C</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            {newSport && (
              <>
                <h3>Sport 2</h3>
                <Form.Item
                  {...formItemLayout}
                  name="sport"
                  label="Sport"
                  className="sport-div"
                >
                  <Select
                    placeholder="Select sport"
                    optionFilterProp="children"
                    // onChange={onChangeSelect}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    suffixIcon={<img src={DownArrow} />}
                    className="location-select"
                    options={[
                      {
                        value: "jack",
                        label: "Jack",
                      },
                      {
                        value: "lucy",
                        label: "Lucy",
                      },
                      {
                        value: "tom",
                        label: "Tom",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  className="location-select"
                  label="Skill Level"
                  rules={[
                    {
                      required: false,
                      message: "Please input your name",
                    },
                  ]}
                >
                  <Form.Item
                    rules={[
                      {
                        required: false,
                        message: "Please input your name",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Level 2: Intermediate (age 11-15)"
                      optionFilterProp="children"
                      // onChange={onChangeSelect}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      suffixIcon={<img src={DownArrow} />}
                      className="location-select"
                      options={[
                        {
                          value: "jack",
                          label: "Jack",
                        },
                        {
                          value: "lucy",
                          label: "Lucy",
                        },
                        {
                          value: "tom",
                          label: "Tom",
                        },
                      ]}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  name="shooting-hand"
                  label="Shooting Hand"
                  className="shooting-div"
                >
                  <Radio.Group
                    // onChange={onChange}
                    // value={value}
                    // defaultValue={value}
                    className="custom-radio"
                  >
                    <Radio value={1}>Right</Radio>
                    <Radio value={2}>Left</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="positions"
                  label="Positions"
                  className="sport-div position-div"
                >
                  <Checkbox.Group className="custom-checkbox">
                    <Checkbox value="pg">PG</Checkbox>
                    <Checkbox value="sg">SG</Checkbox>
                    <Checkbox value="sf">SF</Checkbox>
                    <Checkbox value="pf">PF</Checkbox>
                    <Checkbox value="c">C</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </>
            )}
            {/* <span className="add-another" onClick={() => setNewSport(true)}>
              Add Another Sport
            </span> */}
            <Divider />
            <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
              {/* <Button
                htmlType="submit"
                type="primary"
                className="discard-btn"
                onClick={() => form.resetFields()}
              >
                Discard
              </Button> */}
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  margin: "0 8px",
                }}
                className="dark-base-btn"
              >
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

const CreateProfile = () => {
  return (
    <CreateProfileContextProvider>
      <CreateProfilePage />
    </CreateProfileContextProvider>
  );
};
export default CreateProfile;
