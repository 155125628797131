import React, { useContext, useState } from "react";
import { Table, Tabs, Input, Button, Select, DatePicker, Dropdown, message } from "antd";
import "../challenges-home/challenges-home.scss";
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
import { useNavigate } from "react-router-dom";
import { getDate, getDateWithoutDay } from "../../../util/config";
import DownArrow from "../../../assets/images/arrows.svg";
import Calendar from "../../../assets/images/calendar.svg";
import RowVertical from "../../../assets/images/row-vertical.svg";
import Filter from "../../../assets/images/filter.svg";
import {
  GroupContext,
  GroupContextProvider,
} from "../../../context/group-context/group-context";
import { WorkoutContext, WorkoutContextProvider } from "../../../context/challenge-workout-context/workout-context";
import DeleteWorkoutModal from "../delete-workout/delete-workout-modal";
import { UserContext } from "../../../context/user-context";
const WorkoutsPage = () => {
  const dateFormat = "ddd. MMMM D, YYYY";
  const {workoutsData , loading , sort , setSort ,
    handleTableChange , pageNumber , pageSize , totalGroups , selectedWorkout , setSelectedWorkout , setWorkoutsSearch , setWorkoutsData} = useContext(WorkoutContext)
  // const { pageNumber, pageSize, groups, totalGroups, handleTableChange } =
  //   useContext(GroupContext);
  const {accountDetail} = useContext(UserContext)
    const [deleteWorkoutModal , setDeleteWorkoutModal] = useState(false)
  const navigate = useNavigate();
  const onChange = (key) => {
  };
  const tabItems = [
    {
      key: "all",
      label: `All`,
      //   children: `Content of Tab Pane 1`,
      // children: <Profile />
    },
    // {
    //   key: "archived",
    //   label: `Archived`,
    //   // children: `Content of Tab Pane 2`,
    //   //   children: <UserAccountTable />
    // },
  ];
  const info = () => {
    message.info("You don't have permissions. Contact to Admin");
  };
  const items = [
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },
    {
      key: "detail",
      label: "View Detail",
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { RangePicker } = DatePicker;
  const onChangeSelect = (value) => {
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis:true
    },
    // {
    //   title: "Sport",
    //   dataIndex: "sport_name",
    //   key: "sport_name",
    // },
    {
      title: "tasks",
      dataIndex: "tasks",
      key: "tasks",
      render: (text, key) => { 
        return(
          <div>{text.length}</div>
      )}
    },

    // {
    //   title: "taken by",
    //   dataIndex: "taken",
    //   key: "taken",
    // },

    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, key) => (
        <div>{getDateWithoutDay(text)}</div>
      )

    },
    {
      title: "Author",
      dataIndex: "group_name",
      key: "group_name",
      ellipsis:true

      // render: (text, key) => (
      //   <div>{text ? text : "N/A"}</div>
      // )

    },
    {
      title: "ACTIONS",
      dataIndex: "action",
      key: "action",
      render: (text , record, key) => (
        <Dropdown
          placeholder="Actions"
          className="custom-dropdown"
          menu={{
            items,
            onClick: ({key} ) => {
              setSelectedWorkout(record)
              if (key === "delete") {
                if(accountDetail.role !== null){
                  for (let item of accountDetail.role?.role_permission) {
                  if(item.module.name === "Challenges / Workouts" && item.permission.code_name === "DELETE"){
                    if(item.value === false){
                      info();
                    }
                    else{
                      setDeleteWorkoutModal(true)
                      setSelectedWorkout(record)                   
                     }
                  }
                }
              }else{
                setDeleteWorkoutModal(true)
                setSelectedWorkout(record)    
              }
              }else if(key === "detail"){
                navigate({
                  pathname: `./workout-detail`,
                  search: `?id=${record.id}`,
                })
              } 
              else {
                if(accountDetail.role !== null){
                for (let item of accountDetail.role?.role_permission) {
                  if(item.module.name === "Challenges / Workouts" && item.permission.code_name === "EDIT"){
                    if(item.value === false){
                      info();
                    }
                    else{
                      navigate({
                        pathname: `./edit-workout`,
                        search: `?id=${record.id}`,
                      })
                     }
                  }
                }
              }
              else{
                navigate({
                  pathname: `./edit-workout`,
                  search: `?id=${record.id}`,
                })
               }
                // setSelectedSport(record);
                // setEditSportModal(true);
              }
            },
          }}
          overlayClassName="workout-dropdown-menu"
        >
          <a onClick={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const gotoChallengeDetail = (record) => {
    navigate({
      pathname: `./${record.name}`,
      search: `?id=${record.id}`,
    });
  };

  const searchWorkout= (e) => {
    if (e.target.value.length >= 3) {
      setWorkoutsData([]);
      setWorkoutsSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setWorkoutsData((workoutsData) => []);
      setWorkoutsSearch("");
    }
  };
  return (
    <div className="challenges-home-main">
      {/* <Tabs defaultActiveKey="1" items={tabItems} onChange={onChange} /> */}
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchWorkout}
          />
          <div className="right-div">
          <Select
            placeholder="Newest"
            onChange={(e) => setSort(e)}
            value={sort}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            options={[
              {
                value: true,
                label: "Newest",
              },
              {
                value: false,
                label: "Oldest",
              },
            ]}
          />
            {/* <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
            /> */}
            {/* <button className="red-base-btn">
              <img src={Filter} alt="filter" />
            </button> */}
          </div>
        </div>
        <Table
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => gotoChallengeDetail(record),
          //   };
          // }}
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: totalGroups,
          }}
          loading={loading}
          columns={columns}
          dataSource={workoutsData}
          className="table-container"
        />
      </div>
      <DeleteWorkoutModal deleteWorkoutModal={deleteWorkoutModal} setDeleteWorkoutModal={setDeleteWorkoutModal}
      selectedWorkout={selectedWorkout}
      setSelectedWorkout={setSelectedWorkout}/>
    </div>
  );
};

const Workouts = () => {
  return(
    <WorkoutContextProvider>
      <WorkoutsPage />
    </WorkoutContextProvider>
  )
}
export default Workouts;
