import { Button, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { PurchasesContext } from "../../../context/user-context/purchses-context";
import { getDateWithTimeWithoutDay } from "../../../util/config";

const BSA = () => {
  const {
    bsaPurchaseList,
    loading,
    getBsaPurchaseList,
    setCreditModal,
    handleTableChange,
    bsaPageNumber,
    bsaPageSize,
    totalBsaPurchases,
  } = useContext(PurchasesContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    // {
    //   title: "price",
    //   dataIndex: "price",
    // },
    {
      title: "purchased",
      dataIndex: "purchase_date",
      render: (text, record, key) => (
        <span key={key}>{getDateWithTimeWithoutDay(record.purchase_date)}</span>
      ),
    },
    {
      title: "mode of purchase",
      dataIndex: "purchase_from",
    },
    {
      title: "credits",
      dataIndex: "purchase_count",
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    getBsaPurchaseList();
  }, [bsaPageNumber]);
  return (
    <div className="bsa-main">
      <div className="table-header">
        <h3 style={{ margin: "11px 0" }}>BSA</h3>
        <div className="right-div" style={{ display: "flex" }}>
          {/* <div style={{margin:"11px 0"}}>          Balance:
          <span className="credits">   
        {bsaPurchaseList.length > 0 ? bsaPurchaseList.reduce((prev, current) => prev + current.purchase_count , 0) : 0} Credit(s)</span></div> */}
          <Button
            className="red-base-btn"
            style={{ height: 38 }}
            onClick={() => setCreditModal(true)}
          >
            Add Credits
          </Button>
        </div>
      </div>
      <div className="table-div">
        <Table
          // rowSelection={rowSelection}
          rowKey={(obj) => obj.id}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: bsaPageNumber,
            pageSize: bsaPageSize,
            total: totalBsaPurchases,
          }}
          onChange={handleTableChange}
          loading={loading}
          columns={columns}
          dataSource={bsaPurchaseList}
          className="table-container"
        />
      </div>
    </div>
  );
};

export default BSA;
