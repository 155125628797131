import React from "react";
import { Row, Col } from "antd";
import "./general-details.scss";
const GeneralDetails = ({showEditProfile}) => {
  return (
    <div className="general-details-main">
      <div style={{ display: "flex" }}>
        <h1>General Details</h1>
        <button className="dark-base-btn" onClick={()=> showEditProfile(true)}>Edit Profile</button>
      </div>
      <hr />
      <Row className="data-row">
        <Col span={10} className="label-col">
          <h3>Full Name</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>Lucy Kunic</h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Date of Birth</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>Aug 19, 2000</h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Gender</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>Male</h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Measurements</h3>
        </Col>
        <Col span={14} className="data-col">
          <div>
            Height:<h3>6’3.25”</h3>
          </div>
          <div style={{ marginTop: "16px" }}>
            Weight:<h3>160lbs</h3>
          </div>
        </Col>
        <Col span={10} className="label-col">
          <h3>Location</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>Cleveland, OH, USA</h3>
        </Col>
        <Col span={24} style={{ margin: "56px 0 14px 0" }}>
          <h2>Sports</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Sport</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>1 (Basketball)</h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Skill Level</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>Level 2: Intermediate (age 11-15)</h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Shooting Hand</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>Right</h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Positions</h3>
        </Col>
        <Col span={14} className="data-col">
          <div className="tags">
            <span>PG</span>
            <span>SG</span>
            <span>SF</span>
          </div>
        </Col>
        <Col span={24} style={{ margin: "56px 0 14px 0" }}>
          <h2>Subscription</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Subscription Status</h3>
        </Col>
        <Col span={14} className="data-col">
          <div>
            <span className="green-tag">Active</span>
            <span className="manage-subscription">Manage Subscription</span>
          </div>
        </Col>
        <Col span={10} className="label-col">
          <h3>BSA Credit</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>No BSA Credit</h3>
        </Col>
        <Col span={24} style={{ margin: "56px 0 14px 0" }}>
          <h2>Delete Account</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Delete Profile</h3>
        </Col>
        <Col span={14} className="data-col">
          <span className="orange-tag">Delete Profile</span>
        </Col>
      </Row>
    </div>
  );
};
export default GeneralDetails;
