import React, { useState, useRef, useContext } from "react";
import {Tabs, Button } from "antd";
import "./owners-members.scss";
import Owners from "./owners";
import Members from "./member";
import MembersModal from "./members-modal";
import {
  MemberContextProvider,
  MemberContext,
} from "../../../coach-context/group-context/members-context";
import Pending from "./pending-members";

const OwnersMembersPage = ({generalDetail , getGroupDetail}) => {
  const {
    memberModal,
    setMemberModal,
    selectedTab,
    onChangeTabs,
    setMemberPageNumber,
    setOwnerPageNumber,
  } = useContext(MemberContext);
  const items = [
    {
      key: "1",
      label: `Owners`,
      children: <Owners generalDetail={generalDetail} getGroupDetail={getGroupDetail}/>,
    },
    {
      key: "2",
      label: `Members`,
      children: <Members generalDetail={generalDetail} getGroupDetail={getGroupDetail}/>,
    },
    {
      key: "3",
      label: `Pending`,
      children: <Pending generalDetail={generalDetail} getGroupDetail={getGroupDetail}/>,
    },
  ];
  const addMembers = () => {
    setMemberPageNumber(1);
    setOwnerPageNumber(1);
    setMemberModal(true);
  };
  return (
    <div className="owners-members-main">
      <h1>Owners/Members</h1>
      <div className="owners-members">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChangeTabs}
          className="owners-members-tabs"
          destroyInactiveTabPane
        />
        {selectedTab !== "3" &&
        <Button
          className="dark-base-btn add-button"
          onClick={() => addMembers()}
          disabled={generalDetail.group_expiry}
        >
          Invite Group {selectedTab === "1" ? " Owners" : " Members"}
        </Button>
}
      </div>
      {memberModal === true &&
      <MembersModal
        memberModal={memberModal}
        setMemberModal={setMemberModal}
        selectedTab={selectedTab}
        generalDetail={generalDetail}
      />
      }
    </div>
  );
};
const OwnersMembers = ({generalDetail,getGroupDetail}) => {
  return (
    <MemberContextProvider>
      <OwnersMembersPage generalDetail={generalDetail} getGroupDetail={getGroupDetail}/>
    </MemberContextProvider>
  );
};
export default OwnersMembers;
