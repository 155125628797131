import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select, message } from "antd";
import Info from "../../../assets/images/info.svg";
import DownArrow from "../../../assets/images/down-arrow.svg";
import User from "../../../assets/images/avatar.svg";
import { getData, jsonPostDataTest } from "../../../util/api-calls";

const AssigneeModal = ({ assigneeModal, setAssigneeModal, selectedTest }) => {
  const [form] = Form.useForm();
  const [usersList, setUsersList] = useState([]);
  const [profileId, setProfileId] = useState("");
  const getAssignees = () => {
    getData(`admin/user/account`)
      .then((res) => {
        setUsersList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const postAssignee = () => {
    let data = {
      profile_id: profileId,
      pending_task_id: selectedTest.id,
    };
    jsonPostDataTest(`assign/bsa/test/`, data)
      .then((res) => {
        message.success({
          content:res.data.message
        })
        setAssigneeModal(false);
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancel = () => {
    setAssigneeModal(false);
  };
  useEffect(() => {
    getAssignees();
  }, []);
  return (
    <Modal
      className="assignee-modal"
      wrapClassName="assignee-modal"
      open={assigneeModal}
      onOk={postAssignee}
      onCancel={handleCancel}
      okText={"Save"}
      cancelText={"Cancel"}
      width={"624px"}
    >
      <h1>Assign for Review</h1>
      <p>Assign this workout for review</p>
      <Form className="assignee-form" form={form}>
        <Form.Item
          name="assignee"
          label="Assign to"
          rules={[
            {
              required: true,
              message: "Please select role",
            },
          ]}
        >
          <Select
            placeholder="Please select assignee"
            size={"large"}
            style={{ width: "100%", height: 38 }}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            onChange={(e) => setProfileId(e)}
          >
            {usersList.map((item , index) => (
              <Select.Option value={item.id} key={index}>
                {item.first_name + " " + item.last_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssigneeModal;
