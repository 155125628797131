import React, { useState } from "react";
import { Tabs } from "antd";
import "./index.scss";
import SubmissionsTable from "./submission-table/submission-table";
import { SubmissionContext, SubmissionContextProvider } from "../../context/submission-context/submission_context";
// import Challenges from "./challenges-home/challenges-home";
const SubmissionsMainPage = () => {
  const [activeTab, setActiveTab] = useState("bsa");
  const onChange = (key) => {
    setActiveTab(key);
  };
  const items = [
    {
      key: "bsa",
      label: `BSA`,
      //   children:<Challenges/>
      children: <SubmissionsTable activeTab={activeTab} />,
    },
    // {
    //   key: "challenges",
    //   label: `Challenges`,
    //   // children: `Content of Tab Pane 2`,
    //   //   children: <UserAccountTable />
    //   children: <SubmissionsTable activeTab={activeTab} />,
    // },
  ];
  return (
    <div className="submissions-main">
      <Tabs defaultActiveKey={activeTab} items={items} onChange={onChange} />
    </div>
  );
};

const SubmissionsMain = () => {
  return (
    <SubmissionContextProvider>
      <SubmissionsMainPage />
    </SubmissionContextProvider>
  );
};
export default SubmissionsMain;
