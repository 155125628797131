import React, { useContext, useState, Fragment } from "react";
import "./announcements.scss";
import dayjs from "dayjs";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Input,
  Button,
  Spin,
  Dropdown,
  Empty,
} from "antd";
import Calendar from "../../../assets/images/calendar.svg";
import Comments from "../../../assets/images/comment.svg";
import Avatar from "react-avatar";
import {
  AnnouncementsContextProvider,
  AnnouncementsContext,
} from "../../../context/group-context/announcements-context";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { getTime, getDateWithTime } from "../../../util/config";
import CreateAnnouncement from "./create-announcement-modal";
import DownArrow from "../../../assets/images/down-arrow.svg";
import EditAnnouncement from "./edit-announcements-modal";
import DeleteAnnouncementModal from "./delete-announcement-modal";

TimeAgo.addDefaultLocale(en);
const { TextArea } = Input;
const AnnouncementsPage = () => {
  const timeAgo = new TimeAgo("en-US");

  const {
    sort,
    setSort,
    announcementsList,
    totalAnnouncements,
    addComment,
    getComments,
    allComments,
    comment,
    commentValue,
    setCommentValue,
    modal,
    setModal,
    loading,
    editAnnouncementModal,
    setEditAnnouncementModal,
    setClickedAnnouncement,
    deleteModal,
    setDeleteModal
  } = useContext(AnnouncementsContext);
  const [commentLine, setCommentLine] = useState([{ commentId: "", text: "" }]);
  const dateFormatList = "DD MMM YYYY";
  let commentCounter = 1;
  const onChangeSelect = (value) => {
  };
  const onSearch = (value) =>{} 
  const onChange = (date, dateString) => {
  };
  const enterCommentLine = (e) => {
    if (e.charCode === 13) {
      setCommentLine([
        ...commentLine,
        { commentId: commentCounter++, text: commentValue },
      ]);
      setCommentValue("");
    }
  };

  const handleCommentValue = (e) => {
    setCommentValue(e.target.value);
  };
  const items = [
    {
      key: "edit",
      label: "Edit Announcement",
    },
    {
      key: "delete",
      label: "Delete Announcement",
    },
  ];
  return (
    <div className="announcements-main">
      <div style={{ display: "flex" ,marginBottom:32}}>
        <h1>Announcements</h1>
        {/* <button className="dark-base-btn" 
            // onClick={showModal}
            >
          <img src={Plus} />
          Add Workout
        </button> */}
        <Button className="dark-base-btn" onClick={() => setModal(true)}>
          Create Announcement
        </Button>
      </div>
      {loading && loading == true ? (
        <Spin
          className="loader"
          size="large"
          style={{
            position: "absolute",
            left: "49%",
          }}
        />
      ) : 
      announcementsList && announcementsList.length > 0 ?
      <>
      <hr />
       <div style={{ display: "flex", paddingTop: "40px", marginBottom: 32 }}>
        <h2>{announcementsList.length} Announcements</h2>
      {/*  <div style={{ marginLeft: "auto" }}>
          <Select
            placeholder="Newest"
            onChange={(e) => setSort(e)}
            value={sort}
            suffixIcon={<img src={DownArrow} />}
            className="select-1"
            options={[
              {
                value: true,
                label: "Newest",
              },
              {
                value: false,
                label: "Oldest",
              },
            ]}
          />

          <DatePicker
            // picker="month"
            placeholder="Select Date"
            className="month-picker"
            suffixIcon={<img src={Calendar} />}
            // defaultValue={dayjs("01 JAN 2015", dateFormatList)}
            format={dateFormatList}
          />
          <DatePicker
            onChange={onChange}
            picker="year"
            className="year-picker"
            placeholder="Year"
          />
        </div>*/}
      </div> 

        {announcementsList.toReversed().map(function (item, index) {
          let recentComment =
            Object.keys(comment).length > 0 && comment.post.split("- ");
          return (
            <Row className="announcements" key={index}>
              {/* <Col span={1}>
              <span className="dot"></span>
            </Col> */}
              <Col span={24}>
                <div style={{ display: "flex" }}>
                  <h3 className="title">{item.title}</h3>

                  <Dropdown
                    placeholder="Actions"
                    className="custom-dropdown"
                    menu={{
                      items,

                      onClick: ({ key }) => {
                        setClickedAnnouncement(item)
                        if (key === "delete") {
                          setDeleteModal(true)
                        }else {
                          setEditAnnouncementModal(true);
                        }
                      },
                    }}
                    overlayClassName="workout-dropdown-menu"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      Actions
                      <img src={DownArrow} />
                    </a>
                  </Dropdown>
                </div>
                <div className="writer">
                  {/* Posted: */}
                  {/* <span className="time"> {getTime(item.created_at)}</span> */}
                  <span style={{ marginRight: "5px" }}>by</span>
                  <span
                    className="name"
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.profile.username}
                  </span>
                </div>
                <div className="content">{item.text}</div>
                <div className="writer">
                  Posted:
                  <span className="time">
                    {" "}
                    {getDateWithTime(item.schedule_start)}
                  </span>
                  {/* <span style={{ margin: "0 5px" }}>by</span> */}
                  {/* <span className="name" style={{textTransform:"capitalize"}}>{item.profile.username}</span> */}
                </div>
                <div className="writer">
                  Expiring:
                  <span className="time">
                    {" "}
                    {getDateWithTime(item.schedule_end)}
                  </span>
                  {/* <span style={{ margin: "0 5px" }}>by</span> */}
                  {/* <span className="name" style={{textTransform:"capitalize"}}>{item.profile.username}</span> */}
                </div>
                {/* <div className="comments">
                  <img src={Comments} />
                  <div onClick={() => getComments(item.id)}>
                    {item.total_comments} comments
                  </div>
                </div>
              {item.id === allComments.id ? (
                <ul className="comment-box">
                  {allComments.comments
                    .slice(0)
                    .reverse()
                    .map((item, index) => {
                      return (
                        <li
                          style={{ marginTop: "20px", display: "flex" }}
                          key={index}
                        >
                          {!item.profile.avatar ? (
                            <Avatar
                              name={item.profile.username}
                              color="#F15A29"
                              className="user-avatar"
                            />
                          ) : (
                            <img src={item.profile.avatar}></img>
                          )}
                          <div className="name-div">
                            <h3 className="user-name">
                              {item.profile.username}{" "}
                              <span className="time">
                                {timeAgo.format(new Date(item.created_at))}
                              </span>{" "}
                            </h3>
                            <h4 className="comment">{item.comment}</h4>
                          </div>
                          <span className="reply">Reply</span>
                        </li>
                      );
                      // }
                    })}
                </ul>
              ) : null}
                {Object.keys(comment).length > 0 &&
                recentComment[1] === item.title ? (
                  <div
                    className="comment-box"
                    style={{ marginTop: "20px", display: "flex" }}
                  >
                    {!item.profile.avatar ? (
                      <Avatar
                        name={item.profile.username}
                        color="#F15A29"
                        className="user-avatar"
                      />
                    ) : (
                      <img src={item.profile.avatar}></img>
                    )}
                    <div className="name-div">
                      <h3 className="user-name">
                        {comment.profile.username}{" "}
                        <span className="time">
                          {timeAgo.format(new Date(comment.created_at))}
                        </span>{" "}
                      </h3>
                      <h4 className="comment">{comment.comment}</h4>
                    </div>
                    <span className="reply">Reply</span>
                  </div>
                ) : null}
                <div className="write-comment">Write your comments</div>
                <TextArea
                  value={commentValue[[item.id]]}
                  onChange={handleCommentValue}
                  placeholder="Your response here..."
                  autoSize={{
                    minRows: 5,
                    maxRows: 6,
                  }}
                  onKeyPress={enterCommentLine}
                />
                <div style={{ textAlign: "end" }}>
                  <Button
                    className="dark-base-btn"
                    onClick={() => addComment(commentValue, item.id)}
                  >
                    Send
                  </Button>
                </div> */}
              </Col>
            </Row>
          )
        })
    
    
      }
      </>
       : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ marginBlock: 130 }}
        />
      )
    }
      {editAnnouncementModal && (
        <EditAnnouncement
          editAnnouncementModal={editAnnouncementModal}
          setEditAnnouncementModal={setEditAnnouncementModal}
        />
      )}
      {modal &&
      <CreateAnnouncement modal={modal} setModal={setModal} />
      }
      {deleteModal &&
      <DeleteAnnouncementModal setDeleteModal={setDeleteModal} deleteModal={deleteModal}/>
      } 
    </div>
  );
};

const Announcements = () => {
  return (
    <AnnouncementsContextProvider>
      <AnnouncementsPage />
    </AnnouncementsContextProvider>
  );
};
export default Announcements;
