import React, { useContext, useRef, useState } from "react";
import {
  Input,
  Row,
  Col,
  Dropdown,
  Space,
  Divider,
  Skeleton,
  Checkbox,
  Button,
  Popover,
  Empty,
} from "antd";
import Avatar from "react-avatar";
import DownArrow from "../../../assets/images/down-arrow.svg";
import { MemberContext } from "../../../coach-context/group-context/members-context";
import SearchIcon from "../../../assets/images/search.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Delete from "../../../assets/images/delete1.svg";
import { CopyOutlined, ReloadOutlined } from "@ant-design/icons";
import ExpiryModal from "./expiry-modal/expiry-modal";
import { patchData } from "../../../util/coach-api-calls";
import Copy from "../../../assets/images/copy.svg"
import Refresh from "../../../assets/images/refresh.svg"
import { useNavigate } from "react-router-dom";
const { Search } = Input;

const Owners = ({ generalDetail  , getGroupDetail}) => {
  const navigate = useNavigate();
  const {
    ownerTotal,
    ownersList,
    updateMemberStatus,
    imageLoaded,
    setImageLoaded,
    setOwnerPageNumber,
    setOwnerSearch,
    setOwnersList,
    deleteMemberOwner,
    hasMoreOwners,
    getGroupOwners,
    isCheckMembers,
    // isCheckMembers,
    setIsCheckMembers,
    deleteMembers,
    setExpiryModal,
    expiryModal,
  } = useContext(MemberContext);
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const items = [
    {
      key : "3",
      label: "View Profile Detail"
    },
    {
      key: "1",
      label: "Remove Ownership",
    },
    {
      key: "2",
      label: " Remove from group",
    },
  ];
  const handleOwnersSearch = (e) => {
    if (e.target.value.length >= 3) {
      setOwnerPageNumber(1);
      setOwnersList((ownersList) => []);
      setOwnerSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setOwnersList((ownersList) => []);
      setOwnerSearch("");
    }
  };
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckMembers([...isCheckMembers, id]);
    if (!checked) {
      setIsCheckMembers(isCheckMembers.filter((item) => item !== id));
    }
  };
  const updateAccessCode = () => {
    patchData(`user/access-code` , {
      "group_id": parseInt(params.id),
      "is_member": false
    }).then((res)=>{
      getGroupDetail(false);
    }).catch((err)=>{
      console.log(err)
    })
  }
  return (
    <>
      <div style={{ display: "flex" }}>
        <h2 className="access-code">
          Owner access code:{" "}
          <span style={{ color: "#181c32", cursor: "default" ,marginLeft:5 }}>
            {generalDetail?.owner_code}{" "}
            <Popover
              content={"Copied"}
              trigger="click"
              className="copied-popover"
              overlayClassName="copied-popover"
            >
              <img src={Copy} onClick={() =>navigator.clipboard.writeText(generalDetail?.owner_code)} style={{marginBottom:"-5px"}}/>
            </Popover>
          </span>
          <img src={Refresh} onClick={() =>  updateAccessCode()} style={{marginBottom:"-5px",cursor:generalDetail?.group_expiry === true ? "not-allowed" : "pointer",opacity:generalDetail?.group_expiry === true ? 0.5 : 1}}  /> 
        </h2>
        {generalDetail.is_purchased_group === true &&
        <div style={{ marginLeft: "auto", color: "#3f4254" }}>
          <h2 className="access-code">
            Access code validity:{" "}
            <span style={{ color: "#181c32", cursor: "default" }}>
              {" "}
              {generalDetail.member_code_expiry} months
            </span>
          </h2>
        </div>
}
      </div>
      <div className="owners-main">
        <div style={{ display: "flex", width: "100%" }}>
          <h2>Owners ({ownerTotal}) </h2>
          {isCheckMembers.length > 0 ? (
            <Button
              className="red-base-btn"
              icon={<img src={Delete} />}
              style={{ marginLeft: "auto" }}
              onClick={deleteMembers}
            >
              Remove from group
            </Button>
          ) : (
            <Input
              addonBefore={<img src={SearchIcon} />}
              placeholder="Search owners by name..."
              className="search-input-md"
              onChange={handleOwnersSearch}
              style={{ width: 240, marginLeft: "auto", marginTop: 5 }}
            />
          )}
        </div>
        <div style={{ width: "100%" }}>
          <InfiniteScroll
            dataLength={ownersList.length}
            next={getGroupOwners}
            height={400}
            hasMore={hasMoreOwners}
            loader={<h4>Loading...</h4>}
          >
            {ownersList.length > 0 ?  ownersList &&
              ownersList.map(function (item, index) {
                // console.log(item)
                return (
                  <Row className="owner-row" key={index}>
                    <Col span={20} style={{ display: "flex" }}>
                      <Checkbox
                        key={index}
                        type="checkbox"
                        name={item.member.first_name}
                        id={item.member.id}
                        className="custom-checkbox"
                        onChange={handleClick}
                        style={{ marginRight: 26 }}
                        checked={isCheckMembers.includes(item.member.id)}
                      />
                      {item.member.avatar !== null && imageLoaded === "" && (
                        <Skeleton.Image
                          active={true}
                          className="user-skeleton"
                        />
                      )}
                      {!item.member.avatar ? (
                        <Avatar
                          name={
                            item.member.first_name + " " + item.member.last_name
                          }
                          color="#F15A29"
                          className="user-avatar"
                        />
                      ) : (
                        <img
                          src={item.member.avatar}
                          style={
                            imageLoaded === ""
                              ? { display: "none" }
                              : { display: "block" }
                          }
                          onLoad={() => setImageLoaded("loaded")}
                        ></img>
                      )}
                      <div className="name-div">
                        <h3 className="user-name">
                          {item.member.first_name + " " + item.member.last_name}
                        </h3>
                        <h4 className="user-handle">@{item.member.username}</h4>
                      </div>
                    </Col>
                    <Col span={4} style={{ textAlign: "end" }}>
                      <Dropdown
                        disabled={generalDetail.group_expiry}
                        className="custom-dropdown"
                        menu={{
                          items,
                          onClick: ({ key }) => {
                            if (key === "1") {
                              updateMemberStatus(item, false);
                            } else if (key === "2") {
                              deleteMemberOwner(item);
                            } else {
                              navigate({
                                pathname: `../profile-detail`,
                                search: `?profile_type=owner&id=${params.id}&profile_id=${item.member.id}`,
                              });
                            }
                          },
                        }}
                        overlayClassName="custom-dropdown-menu"
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          Actions
                          <img src={DownArrow} />
                        </a>
                      </Dropdown>
                    </Col>
                    <Divider dashed />
                  </Row>
                );
              }) :         <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginBlock: 130 }}
            />}
          </InfiniteScroll>
        </div>
        <ExpiryModal
          expiryModal={expiryModal}
          setExpiryModal={setExpiryModal}
          generalDetail={generalDetail}
          owner={true}
          getGroupDetail={getGroupDetail}
        />
      </div>
    </>
  );
};

export default Owners;
