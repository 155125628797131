import React, { useState, useRef, useContext, useEffect } from "react";
import { Row, Col, Empty } from "antd";
import "./groups.scss";
import GroupIcon from "../../../assets/images/group-icon.svg";
import Avatar from "react-avatar";
import { UserDetailContext } from "../../../context/user-module-context/user-detail-context";
const Groups = () => {
  const { getProfileGroups, profileGroups } = useContext(UserDetailContext);
  const style = {
    padding: "5px 0",
  };

  return (
    <div className="group-main">
      <h1>Groups</h1>
      <hr />
      <Row gutter={16} className="groups-row">
        {profileGroups?.length > 0 ? (
          profileGroups.map((item , index) => (
            <Col className="gutter-row" span={8} style={{ paddingBottom: 16 }} key={index}>
              <div style={style} className="div-1">
                <div style={{ display: "flex" }}>
                  {!item.avatar ? (
                    <Avatar
                      initials={2}
                      name={item.name}
                      color="#F15A29"
                      className="group-avatar"
                    />
                  ) : (
                    <img src={item.avatar} className="group-logo" />
                  )}
                  <span
                    className={item.active === true ? "green-tag" : "pink-tag"}
                    style={{ marginTop: "unset", marginLeft: "auto" }}
                  >
                    {item.active === true ? "Active" : "InActive"}
                  </span>
                </div>
                <h4>{item.name}</h4>
                <div className="div-2">
                  <span>Sport:</span>
                  {item.sport_name}
                  <div className="vl"></div>
                  {item.is_owner === true ? "Owner" : "Member"}
                </div>
              </div>
            </Col>
          ))
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: "10% auto" }}
          />
        )}
      </Row>
    </div>
  );
};
export default Groups;
