import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Radio,
  Select,
  Upload,
  DatePicker,
  Divider,
  Spin,
  Row,
  Col,
} from "antd";
import "./create-user.scss";
import PhoneInput from "react-phone-number-input";
import { FaArrowLeftLong } from "react-icons/fa6";
import Archive from "../../../assets/images/archive.svg";
import Users from "../../../assets/images/users.svg";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import Calendar from "../../../assets/images/calendar.svg";
import { useNavigate } from "react-router-dom";
import {
  CreateProfileContext,
  CreateProfileContextProvider,
} from "../../../context/user-context/user-context";
import DownArrow from "../../../assets/images/arrows.svg";
import {
  UserModuleContext,
  UserModuleContextProvider,
} from "../../../context/user-module-context/user-module-context";
const dateFormat = "MMMM D, YYYY";
const formItemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};
const CreateUserPage = () => {
  const { createUser, loading, phoneNumber, setPhoneNumber , profileType , setProfileType } =
    useContext(UserModuleContext);
  const navigate = useNavigate();
  const [bsaCredit, setBsaCredit] = useState();
  const [profileOption, setProfileOption] = useState(false);
  const [form] = Form.useForm();

  return (
    <>
      {loading && loading == true ? (
        <Spin
          className="loader"
          size="large"
          style={{
            position: "absolute",
            left: "49%",
            top: "39%",
          }}
        />
      ) : (
        <div className="create-user-main">
          <div className="heading-row">
          <FaArrowLeftLong className= "left-arrow" onClick={() => navigate("/home/users")} />
            <h1>Create User</h1>
          </div>
          {!profileOption ? (
            <Form
              form={form}
              name="dynamic_rule"
              className="user-form"
              onFinish={createUser}
            >
              <Form.Item
                {...formItemLayout}
                name="first_name"
                label="First Name"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name.",
                  },
                ]}
              >
                <Input
                  placeholder="Please input your first name"
                  className="custom-input"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="last_name"
                label="Last Name"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name.",
                  },
                ]}
              >
                <Input
                  placeholder="Please input your last name"
                  className="custom-input"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="dob"
                label="Date of Birth"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter date of birth.",
                  },
                ]}
              >
                 <DatePicker
                className="date-picker"
                // defaultValue={dayjs("Jan 01, 2022", dateFormat)}
                format={dateFormat}
                suffixIcon={<img src={Calendar} />}
                style={{ height: "48px" }}
              />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="email"
                label="Email Address"
                className="name-row"
                rules={[
                  {
                    type: "email",
                    message: "Please enter valid Email!",
                  },
                  {
                    required: true,
                    message: "Please enter Email",
                  },
                ]}
              >
                <Input
                  placeholder="Please input your email"
                  className="custom-input"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="phone"
                label="Phone Number"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number.",
                  },
                ]}
              >
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  placeholder="Please input your phone number"
                />
              </Form.Item>
              <Form.Item
              {...formItemLayout}
              name="profile_type"
              label="Type"
              className="shooting-div"
            >
              <Radio.Group
                onChange={(e) => setProfileType(e.target.value)}
                value={profileType}
                defaultValue={"player"}
                className="custom-radio"
              >
                <Radio value={"player"}>Player</Radio>
                <Radio value={"coach"}>Coach</Radio>
              </Radio.Group>
            </Form.Item>
              <Divider />
              <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="discard-btn"
                  onClick={() => form.resetFields()}
                >
                  Discard
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    margin: "0 8px",
                  }}
                  className="dark-base-btn"
                  // onClick={()=>setProfileOption(true)}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Row className="profile-option-row">
              <Col>
                {" "}
                <Button
                  className="without-profile"
                  icon={<img src={Archive} />}
                >
                  Save without a profile
                </Button>
              </Col>
              <Col>
                {" "}
                <Button className="create-profile" icon={<img src={Users} />}>
                  {" "}
                  Continue to create profile
                </Button>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

const CreateUser = () => {
  return (
    // <CreateProfileContextProvider>
    <UserModuleContextProvider>
      <CreateUserPage />
    </UserModuleContextProvider>
    // </CreateProfileContextProvider>
  );
};
export default CreateUser;
