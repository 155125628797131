import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Input, Divider, Spin, Radio, DatePicker } from "antd";
import "./edit-user.scss";

import LeftArrow from "../../../assets/images/left-arrow.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Calendar from "../../../assets/images/calendar.svg";
import {
  UserDetailContext,
  UserDetailContextProvider,
} from "../../../context/user-module-context/user-detail-context";
import PhoneInput from "react-phone-number-input";
import dayjs from "dayjs";
const dateFormat = "MMMM D, YYYY";
const formItemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};
const EditUserPage = () => {
  const {
    userData,
    loading,
    getSingleUser,
    updateUser,
    phoneNumber,
    setPhoneNumber,
    profileType,
    setProfileType
  } = useContext(UserDetailContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  useEffect(() => {
    getSingleUser();
  }, []);
  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        phone: userData.phone,
        profile_type : userData.type,
        dob:userData.dob !== null && dayjs(userData.dob)
      });
      setPhoneNumber(userData.phone);
    }
  }, [userData]);
  return (
    <>
      {loading && loading == true ? (
        <Spin
          className="loader"
          size="large"
          style={{
            position: "absolute",
            left: "49%",
            top: "39%",
          }}
        />
      ) : (
        <div className="create-user-main">
          <div className="heading-row">
          <FaArrowLeftLong className= "left-arrow" onClick={() => navigate("/home/users")} />
            <h1>Edit User</h1>
          </div>
          <Form
            form={form}
            name="dynamic_rule"
            className="user-form"
            onFinish={updateUser}
            initialValues={{
              first_name: userData.first_name,
            }}
          >
            <Form.Item
              {...formItemLayout}
              name="first_name"
              label="First Name"
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter first name.",
                },
              ]}
            >
              <Input
                placeholder="Please input your first name"
                className="custom-input"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="last_name"
              label="Last Name"
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter last name.",
                },
              ]}
            >
              <Input
                placeholder="Please input your last name"
                className="custom-input"
              />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                name="dob"
                label="Date of Birth"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter date of birth.",
                  },
                ]}
              >
                 <DatePicker
                className="date-picker"
                // defaultValue={dayjs("Jan 01, 2022", dateFormat)}
                format={dateFormat}
                suffixIcon={<img src={Calendar} />}
                style={{ height: "48px" }}
              />
              </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="email"
              label="Email Address"
              className="name-row"
              rules={[
                {
                  type: "email",
                  message: "Please enter valid Email!",
                },
                {
                  required: true,
                  message: "Please enter Email",
                },
              ]}
            >
              <Input
                placeholder="Please input your email"
                className="custom-input"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="phone"
              label="Phone Number"
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number.",
                },
              ]}
            >
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                value={phoneNumber}
                onChange={setPhoneNumber}
                placeholder="Please input your phone number"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="profile_type"
              label="Type"
              className="shooting-div"
            >
              <Radio.Group
                onChange={(e) => setProfileType(e.target.value)}
                value={profileType}
                defaultValue={"player"}
                className="custom-radio"
              >
                <Radio value={"player"}>Player</Radio>
                <Radio value={"coach"}>Coach</Radio>
              </Radio.Group>
            </Form.Item>
            <Divider />
            <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
              <Button
                htmlType="submit"
                type="primary"
                className="discard-btn"
                onClick={() => navigate("../")}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  margin: "0 8px",
                }}
                className="dark-base-btn"
                // onClick={()=>setProfileOption(true)}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

const EditUser = () => {
  return (
    <UserDetailContextProvider>
      <EditUserPage />
    </UserDetailContextProvider>
  );
};
export default EditUser;
