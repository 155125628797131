import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Input,
  Radio,
  Form,
  Upload,
  message,
  Button,
  DatePicker,
} from "antd";
import Calendar from "../../../assets/images/calendar.svg";

import { AnnouncementsContext } from "../../../context/group-context/announcements-context";
import dayjs from "dayjs";
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const EditAnnouncement = ({ editAnnouncementModal,setEditAnnouncementModal}) => {
  const [form] = Form.useForm();
  const { updateAnnouncement , loading ,clickedAnnouncement} = useContext(AnnouncementsContext);
  const [value, setValue] = useState(1);
  const dateFormat = "MMM D, YYYY h:mm A";
  const handleOk = () => {
    // setEditAnnouncementModal(false);
  };
  const handleCancel = () => {
    setEditAnnouncementModal(false);
  };
  useEffect(()=>{
    if(editAnnouncementModal === false){
      form.resetFields()
    }
  },[[editAnnouncementModal]])
useEffect(()=>{
  form.setFieldsValue({
    title:clickedAnnouncement.title,
    text:clickedAnnouncement.text,
    schedule_start: dayjs(clickedAnnouncement.schedule_start),
    schedule_end: dayjs(clickedAnnouncement.schedule_end)

  })
},[clickedAnnouncement])
  return (
    <Modal
      className="announcement-modal"
      open={editAnnouncementModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Update"}
      cancelText={"Cancel"}
      width={"699px"}
      footer={false}
    >
      <h1>Edit Announcement</h1>
      <Form
        form={form}
        name="dynamic_rule"
        className="announcement-form"
        onFinish={updateAnnouncement}
      >
        {" "}
        <Form.Item
          {...formItemLayout}
          name="title"
          label="Announcement Title"
          rules={[
            {
              required: true,
              message: "Please enter title",
            },
          ]}
        >
          <Input placeholder="Announcement Title..." className="custom-input" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="schedule_start"
          label="Scheduled Start"
          rules={[
            {
              required: true,
              message: "Please enter start date",
            },
          ]}
        >
          <DatePicker
          showTime
            className="date-picker"
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{height:"38px"}}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="schedule_end"
          label="Scheduled End"
          rules={[
            {
              required: true,
              message: "Please enter end date",
            },
          ]}
        >
          <DatePicker
          showTime
            className="date-picker"
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{height:"38px"}}

          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="text"
          label="Announcement Text"
          rules={[
            {
              required: true,
              message: "Please enter announcement detail",
            },
          ]}
        >
          <TextArea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Announcement Details..."
            autoSize={{
              minRows: 5,
              maxRows: 6,
            }}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
          <Button
            htmlType="button"
            type="primary"
            className="discard-btn"
            onClick={() => setEditAnnouncementModal(false)}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            style={{
              margin: "0 8px",
            }}
            className="dark-base-btn"
            loading={loading}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAnnouncement;
