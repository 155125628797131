import React, { useContext } from "react";
import {
  Input,
  Row,
  Col,
  Dropdown,
  Space,
  Skeleton,
  Checkbox,
  Button,
  Popover,
} from "antd";
import SearchIcon from "../../../assets/images/search.svg";
import Avatar from "react-avatar";
import DownArrow from "../../../assets/images/down-arrow.svg";
import Delete from "../../../assets/images/delete1.svg";
import { MemberContext } from "../../../context/group-context/members-context";
import InfiniteScroll from "react-infinite-scroll-component";
import {CopyOutlined , ReloadOutlined } from "@ant-design/icons";
import ExpiryModal from "./expiry-modal/expiry-modal";
import { patchData } from "../../../util/api-calls";
import Copy from "../../../assets/images/copy.svg"
import Refresh from "../../../assets/images/refresh.svg"
const { Search } = Input;
const Members = ({generalDetail,getGroupDetail}) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const {
    imageLoaded,
    setImageLoaded,
    membersTotal,
    membersList,
    setMemberSearch,
    updateMemberStatus,
    deleteMemberOwner,
    setMembersList,
    setMemberPageNumber,
    hasMoreMembers,
    getGroupMembers,
    deleteMembers,
    isCheckMembers,
    setIsCheckMembers,
    isCheckAll,
    setIsCheckAll,
    setExpiryModal,
    expiryModal
  } = useContext(MemberContext);

  const items = [
    {
      key: "1",
      label: "Make Owner",
    },
    {
      key: "2",
      label: "Remove from group",
    },
  ];
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    for (let i = 0; i < membersList.length; i++) {
      setIsCheckMembers((prevMembers) => [
        ...prevMembers,
        membersList[i].member.id,
      ]);
    }
    if (isCheckAll) {
      setIsCheckMembers([]);
    }
  };
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckMembers([...isCheckMembers, id]);
    if (!checked) {
      setIsCheckMembers(isCheckMembers.filter((item) => item !== id));
    }
  };
  React.useEffect(() => {
    if (isCheckMembers.length === 0) {
      setIsCheckAll(false);
    } else {
      setIsCheckAll(true);
    }
  }, [isCheckMembers]);
  const handleSearchMember = (e) => {
    if (e.target.value.length >= 3) {
      setMemberPageNumber(1);
      setMembersList([]);
      setMemberSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setMembersList((membersList) => []);
      setMemberSearch("");
    }
  };
  const updateAccessCode = () => {
    patchData(`user/access-code` , {
      "group_id": parseInt(params.id),
      "is_member": true
    }).then((res)=>{
      console.log(res)
      getGroupDetail(false);
    }).catch((err)=>{
      console.log(err)
    })
  }
  return (
    <>
          <div style={{ display: "flex" }}>
      <h2 className="access-code">
        Member access code: <span style={{ color: "#181c32", cursor: "default",marginLeft:5 }}>
          {generalDetail?.member_code}
          <Popover
            content={"Copied"}
            trigger="click"
            className="copied-popover"
            overlayClassName="copied-popover"
          >
            <img src={Copy} onClick={() =>navigator.clipboard.writeText(generalDetail?.member_code)} style={{marginBottom:"-5px"}} /></Popover>
        </span>
          <img src={Refresh} onClick={() => generalDetail.is_purchased_group === false ? updateAccessCode() : setExpiryModal(true)} style={{marginBottom:"-5px",marginLeft:5}}  />
        </h2>
        {generalDetail.is_purchased_group === true &&
        <div style={{ marginLeft: "auto", color: "#3f4254" }}>
          <h2 className="access-code">
            Access code validity:{" "}
            <span style={{ color: "#181c32", cursor: "default" }}>
              {" "}
              {generalDetail.member_code_expiry} months
            </span>
          </h2>
        </div> 
}     
        </div>
        <div className="owners-main">
        <div style={{ display: "flex", width: "100%" }}>
          <Checkbox
            type="checkbox"
            name="selectAll"
            id="selectAll"
            className="custom-checkbox"
            onChange={handleSelectAll}
            checked={isCheckAll}
            style={{ marginRight: 16 }}
          />
          <h2>
            {isCheckMembers.length === 0
              ? "Members" + "(" + membersTotal + ")"
              : isCheckMembers.length + " Member(s) Selected"}{" "}
          </h2>
          {isCheckMembers.length > 0 ? (
            <Button
              className="red-base-btn"
              icon={<img src={Delete} />}
              style={{ marginLeft: "auto" }}
              onClick={deleteMembers}
            >
              Remove from group
            </Button>
          ) : (
            <Input
              addonBefore={<img src={SearchIcon} />}
              placeholder="Search members by name..."
              className="search-input-md"
              onChange={handleSearchMember}
              style={{ width: 240, marginLeft: "auto", marginTop: 5 }}
            />
          )}
        </div>
        <div style={{ width: "100%" }}>
          <InfiniteScroll
            dataLength={membersList.length}
            next={getGroupMembers}
            height={400}
            hasMore={hasMoreMembers}
            loader={<h4>Loading...</h4>}
          >
            {membersList.map(function (item, index) {
              return (
                <Row className="owner-row" key={index}>
                  <Col span={20} style={{ display: "flex" }}>
                    <Checkbox
                      key={index}
                      type="checkbox"
                      name={item.member.first_name}
                      id={item.member.id}
                      className="custom-checkbox"
                      onChange={handleClick}
                      style={{ marginRight: 26 }}
                      checked={isCheckMembers.includes(item.member.id)}
                    />
                    {item.member.avatar !== null && imageLoaded === "" && (
                      <Skeleton.Image active={true} className="user-skeleton" />
                    )}
                    {!item.member.avatar ? (
                      <Avatar
                        name={
                          item.member.first_name + " " + item.member.last_name
                        }
                        color="#F15A29"
                        className="user-avatar"
                      />
                    ) : (
                      <img
                        src={item.member.avatar}
                        style={
                          imageLoaded === ""
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        onLoad={() => setImageLoaded("loaded")}
                      ></img>
                    )}
                    <div className="name-div">
                      <h3 className="user-name">
                        {item.member.first_name + " " + item.member.last_name}
                      </h3>
                      <h4 className="user-handle">@{item.member.username}</h4>
                    </div>
                  </Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <Dropdown
                      className="custom-dropdown"
                      menu={{
                        items,
                        onClick: ({ key }) => {
                          if (key === "1") {
                            updateMemberStatus(item, true);
                          } else {
                            deleteMemberOwner(item);
                          }
                        },
                      }}
                      overlayClassName="custom-dropdown-menu"
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        Actions
                        <img src={DownArrow} />
                      </a>
                    </Dropdown>
                  </Col>
                  <hr />
                </Row>
              );
              // }
            })}
          </InfiniteScroll>
        </div>
        <ExpiryModal
          expiryModal={expiryModal}
          setExpiryModal={setExpiryModal}
          generalDetail={generalDetail}
          owner={false}
          getGroupDetail={getGroupDetail}
        />
      </div>
    </>
  );
};

export default Members;
