import React, { useContext, useState } from "react";
import { Modal, Input, Radio, Form, Upload, message, Button, Divider, DatePicker, Select, Table } from "antd";
import Info from "../../../assets/images/info.svg";
import TaskList from "../tasks-list/tasks-list";
import UploadFile from "../../../assets/images/upload.svg";
import "./subscription-history.scss"
import Calendar from "../../../assets/images/calendar.svg";
import Search from "../../../assets/images/search.svg";
import DownArrow from "../../../assets/images/arrows.svg";
import { GroupDetailContext } from "../../../coach-context/group-context/group-detail-context";
import dayjs from "dayjs";
import {getDateWithTime } from "../../../util/config";
const {RangePicker } = DatePicker
const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};
const {TextArea } = Input
const SubscriptionHistory = ({     setSubscriptionHistory,subscriptionHistory ,setSubscriptionStatus }) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {subscriptionRecord , setSort,
    setStartDate,setEndDate} = useContext(GroupDetailContext)
  const [value, setValue] = useState(1);
  const dateFormat = "ddd. MMMM D, YYYY";

  const handleOk = () => {
    setSubscriptionHistory(false);
    setSubscriptionStatus("active")
  };
  const handleCancel = () => {
    setSubscriptionHistory(false);
  };
  const onSearch = (value) => console.log(value);
  const onChangeSelect = (value) => {
    if(value === "newest"){
      setSort(true)
    }else{
      setSort(false)
    }
  };
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const columns = [
    {
      title: "SUB. START",
      dataIndex: "start_date",
      key:'start_date',
      // width: "30%",
      render: (text, record, index) => {
        return <div key={index}>{dayjs(text).format('MMM D, YYYY')}<br/>{dayjs(text).format('h:mm A')}</div>
      },
    },
    {
      title: "SUB. EXPIRATION",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (text, record, index) => {
        return <div key={index}>{dayjs(text).format('MMM D, YYYY')}<br/>{dayjs(text).format('h:mm A')}</div>
      },
    },
    // {
    //   title: "SUB. NOTES",
    //   dataIndex: "notes",
    //   key:"notes",
    //   render: (text, record, index) => {
    //     return <div key={index}>{text}</div>
    //   },
    // },
    // {
    //   title: "ACTIONS",
    //   dataIndex: "member_limit",
    //   key:'member_limit',
    //   render: (text , key) =>  <Select
    //   showSearch
    //   index={key}
    //   placeholder="Actions"
    //   optionFilterProp="children"
    //   onChange={onChange}
    //   onSearch={onSearch}
    //   filterOption={(input, option) =>
    //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    //   }
    //   suffixIcon={<img src={DownArrow} />}

    //   options={[
    //     {
    //       value: 'jack',
    //       label: 'Jack',
    //     },
    //     {
    //       value: 'lucy',
    //       label: 'Lucy',
    //     },
    //     {
    //       value: 'tom',
    //       label: 'Tom',
    //     },
    //   ]}
    // />,

    // }
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const data = [];
  for (let i = 0; i < 5; i++) {
    data.push({
      key: i,
      reps: "11/22",
      completion: "96%",
    });
  }
  const changeRangePicker = (e) => {
    if(e){
      setStartDate(e[0].format(
        "YYYY-MM-DD"
      ))
    setEndDate(e[1].format(
      "YYYY-MM-DD"
    ))
    }else{
      setStartDate("")
      setEndDate("")
    }
  }
  return (
    <Modal
      className="subscription-history"
      open={subscriptionHistory}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Create Subscription"}
      cancelText={"Close"}
      width={"999px"}
    //   footer={false}
    wrapClassName="subscription-history"
    >
      <h1>Subscription History</h1>
      <div style={{ display: "flex" }}>
          {/* <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
          />
          */}
          <div className="right-div">
        {/*  <Select
            placeholder="Newest"
            optionFilterProp="children"
            onChange={onChangeSelect}
            // onSearch={onSearch}
            // filterOption={(input, option) =>
            //   (option?.label ?? "")
            //     .toLowerCase()
            //     .includes(input.toLowerCase())
            // }
            suffixIcon={<img src={DownArrow} />}
            style={{height:40}}
            className="select-input"
            options={[
              {
                value: 'newest',
                label: 'Newest',
              },
              {
                value: 'oldest',
                label: 'Oldest',
              },
            ]}
          /> */}
            <RangePicker
            className="range-picker"
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            onChange={changeRangePicker}
          />
          </div>
        </div>
        <Table
        //    onRow={(record, rowIndex) => {
        //     return {
        //       onClick:() => gotoGroupDetail(record)
        //     };
        //   }}
        //   onChange={handleTableChange}
          rowKey={obj => obj.id}
          // rowSelection={rowSelection}
          pagination={false}
        //   pagination={{
        //     pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
        //     showSizeChanger: true,
        //     locale: { items_per_page: "" },
        //     defaultPageSize: 10,
        //     current: pageNumber,
        //     pageSize: pageSize,
        //     total:totalGroups,
        //   }}
          columns={columns}
          dataSource={subscriptionRecord}
          className="table-container"
        />
        <Divider />
        <div style={{textAlign:"end"}}>
        <Button className="close-btn" onClick={()=>setSubscriptionHistory(false)}>Close</Button>

        </div>
    </Modal>
  );
};

export default SubscriptionHistory;
