import React, { useContext, useEffect } from "react";
import {
  Input,
  Modal,
  Form,
  Select,
  Button,
} from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import { AdminUserContext } from "../../../context/setting-context/admin_user_context";
import { patchData } from "../../../util/api-calls";
const EditUserModal = ({ editUserModal, setEditUserModal }) => {
  const [form] = Form.useForm();
  const { createUser, roleList , selectedUser , updateUser , setRoleId} = useContext(AdminUserContext);
  const handleCancel = () => {
    setEditUserModal(false);
  };
  useEffect(()=>{
    console.log(selectedUser?.role?.id)
    form.setFieldsValue({
        full_name: selectedUser.first_name + " " + selectedUser.last_name,
        username: selectedUser?.profile?.username,
        email : selectedUser.email,
        phone : selectedUser.phone ,
        role_id :selectedUser?.role?.role_name
    })
    setRoleId(selectedUser?.role?.id)
  } , [selectedUser])

  return (
    <Modal
      wrapClassName="add-user-modal"
      className="add-user-modal"
      open={editUserModal}
      onCancel={handleCancel}
      okText={"Create"}
      cancelText={"Cancel"}
      width={"624px"}
      footer={false}
    >
      <div className="add-user-main">
        <h1>Edit User</h1>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="add-user-form"
          onFinish={updateUser}
        >
          <Form.Item
            name="full_name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: "Please enter Full Name",
              },
            ]}
          >
            <Input placeholder="Please enter full name" />
          </Form.Item>
          <Form.Item
            name="username"
            label="User Name"
            rules={[
              {
                required: true,
                message: "Please enter User Name",
              },
            ]}
          >
            <Input placeholder="Please enter user name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              {
                type: 'email',
                message: 'Please enter valid Email!',
              },
              {
                required: true,
                message: "Please enter Email",
              },
            ]}
          >
            <Input placeholder="Please enter email" disabled/>
          </Form.Item>
          {/* <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter Password",
              },
            ]}
          >
            <Input placeholder="Please enter password" />
          </Form.Item> */}
         
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please enter Phone Number",
              },
            ]}
          >
            <Input placeholder="Please enter phone number" />
          </Form.Item> 
          <Form.Item
            name="role_id"
            label="Role"
            rules={[
              {
                required: true,
                message: "Please select role",
              },
            ]}
          >
            <Select
              placeholder="Please select role"
              size={"large"}
              style={{ width: "100%", height: 38 }}
              suffixIcon={<img src={DownArrow} />}
              className="select-input"
              onChange={(e)=>setRoleId(e)}
            >
              {roleList.map((item) => (
                <Select.Option value={item.id}>{item.role_name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ textAlign: "end" }}>
            <Button
              type="default"
              className="discard-btn"
              onClick={() => {
                setEditUserModal(false);
                form.resetFields();
              }}
              style={{
                margin: "0 8px",
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="dark-base-btn"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditUserModal;
