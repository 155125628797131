import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Image,
  Input,
  Progress,
  Select,
  Steps,
  Upload,
} from "antd";
import Compressor from 'compressorjs';
import DownArrow from "../../../assets/images/arrows.svg";
import UploadFile from "../../../assets/images/upload.svg";
import Calendar from "../../../assets/images/calendar.svg";
import Player from "../../Player/Player";
import ReactQuill from "react-quill";
import "./edit-rotator.scss";
import {
  EditRotatorContext,
  EditRotatorContextProvider,
} from "../../../context/home-rotator-context/edit-rotator-context";
import { CloseOutlined } from "@ant-design/icons";
import Expand from "../../../assets/images/expand_video.svg";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const { Dragger } = Upload;
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const { Meta } = Card;

const dateFormat = "ddd. MMMM D, YYYY";

const EditRotatorPage = ({ existingTaskModal, setExistingTaskModal }) => {
  const {
    updateRotator,
    uploadVideo,
    sponsorsList,
    sportsList,
    sport,
    setSport,
    sponsor,
    setSponsor,
    sponsorUrl,
    setSponsorUrl,
    rotatorDetail,
    endDate,
    startDate,
    resetForm,
    setDetailButtonLink,
    setDetailButtonText,
    detailContent,
    detailImageUrl,
    videoUrl,
    detailImgSrc,
    detailPreview,
    setDetailTitle,
    imgSrc,
    imageUrl,
    imagePreview,
    setButtonText,
    setDetailImgSrc,
    setDetailContent,
    setStartDate,
    setEndDate,
    form,
    form1,
    setTitle,
    title,
    setDetailPreview,
    setImageUrl,
    setImgSrc,
    setImagePreview,
    setMediaType,
    setDetailImageUrl,
    setVideoUrl,
    setDetailVideoId,
    mediaType,
    setAddMore,
    buttonDisabled,
    detailVideoId,
    abortVideo,
    videoId,
    setVideoId
  } = useContext(EditRotatorContext);
  const wrapperRef = React.useRef(null);
  const navigate = useNavigate();
  const initialItems = [
    {
      label: "Rotator 1",
      key: "1",
      closable: false,
    },
  ];

  const [current, setCurrent] = useState(0);
  const [uploadingStart, setUploadingStart] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoDeleted , setVideoDeleted] = useState(false)
  const [detailImagePreview, setDetailImgaePreview] = useState(false);
  const [activeKey, setActiveKey] = useState(initialItems[0].key);
  const [items, setItems] = useState(initialItems);
  const onChange = (value) => {
    setCurrent(value);
  };
  let sportOption = [];
  for (let i = 0; i < sportsList.length; i++) {
    sportOption.push({
      value: sportsList[i].id,
      label: sportsList[i].name,
    });
  }
  let sponsorOption = [];

  for (let i = 0; i < sponsorsList.length; i++) {
    sponsorOption.push({
      value: sponsorsList[i].id,
      label: sponsorsList[i].name,
    });
  }
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': ['Neue Haas Grotesk Display']}],
      ["bold", "italic", "underline"],
      // [{ 'font': [] }],
      // [{ 'font': ['Neue Haas Grotesk Display']}],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ["link", "image"],
    ],
  };

  const formats = ["header", "bold", "italic", "underline", "link", "image" , "list" , "indent" , "font"];

  const handleProcedureContentChange = (content) => {
    setDetailContent(content);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  async function handleBeforeUploadHome(file) {
    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      width : 2796,
      height : 1290 ,
      success: (compressedResult) => {
        setImageUrl(compressedResult); 
      },
    });
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    setImagePreview(true);
    reader.readAsDataURL(file);
    return false;
  }
  async function handleBeforeUpload(file) {
    setVideoDeleted(false);
    if (file.type === "image/png" || file.type === "image/jpeg") {
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        width : 2796,
        height : 1290 ,
        success: (compressedResult) => {
          setDetailImageUrl(compressedResult);
        },
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        setDetailImgSrc(reader.result);
      };
      setMediaType("image");
      setVideoUrl();
      setDetailPreview("image");
      reader.readAsDataURL(file);
      return false;
    } else {
      setMediaType("video");
      setDetailImgSrc();
      setUploadingStart(true);
      setVideoUrl(file);
      setDetailPreview("video");
      await uploadVideo(file, handleSuccessfullBytes, uploadCompleteDone);
    }
  }
  useEffect(() => {
    if (videoDeleted === true) {
      setDetailPreview(false);
    }
  }, [detailPreview]);
  const handleSuccessfullBytes = (bytes, totalBytes) => {
    const bytesInPercentage = Math.round((bytes / totalBytes) * 100);
    setPercentage(bytesInPercentage);
  };
  const uploadCompleteDone = (isCompleted, videoId, url) => {
    setVideoSrc(url);
    setDetailPreview("video");
    setUploadComplete(isCompleted);
    const video_id = videoId.split("/");
    setDetailVideoId(video_id[2]);
    setUploadingStart(false);
  };
  const changeStartDate = (e) => {
    if (e === null) {
      setStartDate("");
    } else {
      let start_date = new Date(e).toISOString();
      setStartDate(start_date);
    }
  };
  const changeEndDate = (e) => {
    if (e === null) {
      setEndDate("");
    } else {
      let end_date = new Date(e).toISOString();
      setEndDate(end_date);
    }
  };
  const onChangeSport = (value) => {
    setSport(value);
  };
  const onChangeSponsor = (value) => {
    setSponsor(value);
    for (let i = 0; i < sponsorsList.length; i++) {
      if (value === sponsorsList[i].id) {
        setSponsorUrl(sponsorsList[i].website);
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue({ sponsor_url: sponsorUrl });
  }, [sponsorUrl]);

  let newTabIndex = useRef(2);
  const onChangeTabs = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };
  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    newTabIndex = `${newTabIndex.current--}`;
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };
  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };
  const add = () => {
    setAddMore(true);
    const newActiveKey = `${newTabIndex.current++}`;
    setItems([
      ...items,
      {
        label: `Rotator ${newActiveKey}`,
        // children: 'New Tab Pane',
        closable: true,
        key: newActiveKey,
      },
    ]);
    setActiveKey(newActiveKey);
  };
  const handleFullscreen = () => {
    console.log(wrapperRef.current);
    screenfull.toggle(findDOMNode(wrapperRef.current));
  };

  React.useEffect(() => {
    if (Object.keys(rotatorDetail).length > 0) {
      const regex = /(<([^>]+)>)/gi;
      const result = rotatorDetail.detail_content.replace(regex, "");
      form1.setFieldsValue({
        sport: rotatorDetail && rotatorDetail.sport.id,
      });
      form.setFieldsValue({
        title: rotatorDetail.title,
        action_button_text: rotatorDetail.action_button_text,
        start_date: dayjs(rotatorDetail.start_date),
        end_date: dayjs(rotatorDetail.end_date),
        sponsor_id: rotatorDetail.sponsor && rotatorDetail.sponsor.id,
        sponsor_url: rotatorDetail.sponsor && rotatorDetail.sponsor.website,
        detail_title: rotatorDetail.detail_title,
        detail_action_button_text: rotatorDetail.detail_action_button_text,
        detail_action_button_link: rotatorDetail.detail_action_button_link,
        // editor: result,
        editor: rotatorDetail.detail_content
        // sponsor_id :
      });
      setTitle(rotatorDetail.title);
      setDetailButtonText(rotatorDetail.action_button_text);
      setStartDate(rotatorDetail.start_date);
      setEndDate(rotatorDetail.end_date);
      // setSponsor(rotatorDetail.sponsor && rotatorDetail.sponsor.id)
      setDetailTitle(rotatorDetail.detail_title);
      setDetailButtonText(rotatorDetail.detail_action_button_text);
      setDetailButtonLink(rotatorDetail.detail_action_button_link);
      setButtonText(rotatorDetail.action_button_text);
      setSport(rotatorDetail.sport.id);
      // setMediaType(rotatorDetail.detail_media_type)
      setStartDate(rotatorDetail.start_date);
      setEndDate(rotatorDetail.end_date);
      if (rotatorDetail.media_image) {
        setImagePreview(true);
        setImgSrc(rotatorDetail.media_image);
        // setImageUrl(rotatorDetail.media_image)
      }
      if (rotatorDetail.detail_media_type === "image") {
        setDetailPreview("image");
        setDetailImgSrc(rotatorDetail.detail_image);
      } else if (rotatorDetail.detail_media_type === "video") {
        setDetailPreview("video");
      }
    }
  }, [rotatorDetail]);
  const resetHomeDragger = () => {
    // setVideoUrl();
    // setDetailVideoId("");
    // setDetailImageUrl();
    // setVideoPreview(false);
    // setDetailImgSrc();
    setImageUrl();
    setImgSrc();
    setImagePreview(false);
  };
  const deleteVideo = async () => {
    const response = await axios.delete(`https://api.vimeo.com${videoId}`, {
      headers: {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        "Content-Type": "application/json",
        Authorization: `Bearer be82d2e853d829ed867903ec070a64f7`,
      },
    });
    setVideoId();
    setVideoDeleted(true);
    return response?.data;
  };
  const resetLandingDragger = () => {
    abortVideo();
    setPercentage(0)
    setDetailImgSrc();
    setDetailImageUrl();
    // setVideoPreview(false)
    setDetailPreview("");
    setVideoUrl();
    setDetailVideoId("");
    if (videoId) {
      deleteVideo();
    }
  };
  return (
    <div className="edit-rotator-main">
      <Form form={form1}>
        <Form.Item {...formItemLayout} name="sport" label="Sport">
          <Select
            optionFilterProp="children"
            placeholder="Basketball"
            onChange={onChangeSport}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size={"large"}
            suffixIcon={<img src={DownArrow} />}
            style={{ height: 38, width: "100%", marginTop: 8 }}
            className="custom-select"
            options={sportOption}
          />
        </Form.Item>
      </Form>

      <div className="tasks-list">
        <h3>Rotators</h3>
        {/* <div className="edit-rotator-div">

          <Tabs
            hideAdd
            type="editable-card"
            onChange={onChangeTabs}
            activeKey={activeKey}
            onEdit={onEdit}
            items={items}
            style={{ width: newTabIndex.current >= 6 ? "81%" : "auto" }}
          />
          {newTabIndex.current <= 3 && 
          <Button onClick={add} className="new-rotator"
           disabled={addMore}
           >
            + Add more
          </Button>
}
        </div> */}
        <Steps
          current={current}
          onChange={onChange}
          // direction="vertical"
          className="rotator-form-steps"
          items={[
            {
              description: "Home Screen",
              // description,
              // disabled: true,
            },
            {
              description: "Landing Screen",
              // description,
            },
          ]}
        />

        <Form
          form={form}
          name="dynamic_rule"
          className="edit-rotator-form"
          onFinish={updateRotator}
        >
          {" "}
          {current === 0 ? (
            <>
              <Form.Item
                {...formItemLayout}
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Please enter title.",
                  },
                ]}
              >
                <Input
                  className="title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  maxLength={255}
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label="Add Media"
                className="media-div"
              >
                <div className={imagePreview ? "uploader-div" : ""}>
                  <Dragger
                    type="file"
                    customRequest={dummyRequest}
                    beforeUpload={handleBeforeUploadHome}
                    accept="image/png, image/jpeg"
                    showUploadList={false}
                  >
                    <p className="ant-upload-drag-icon">
                      <img src={UploadFile} alt="" />
                    </p>
                    <div style={{ textAlign: "left", padding: "12px 0" }}>
                      {" "}
                      <p className="ant-upload-text">
                        Drag and Drop or
                        <span>Browse</span>
                      </p>
                      <p className="ant-upload-hint">
                        Upload photo (16:9 ratio)
                      </p>
                    </div>
                  </Dragger>
                  {imagePreview && (
                    <Card
                      hoverable
                      className="video-cards"
                      cover={
                        <div
                          style={{
                            overflow: "hidden",
                            height: "250px",
                            borderRadius: 8,
                          }}
                        >
                          <span className="top-cancel-btn">
                            <CloseOutlined onClick={resetHomeDragger} />
                          </span>
                          <Image
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            className="img"
                            src={imgSrc}
                          />
                        </div>
                      }
                    >
                      <div
                        className="name"
                        title={
                          rotatorDetail.media_name && rotatorDetail.media_name
                        }
                      >
                        {imageUrl
                          ? imageUrl?.name
                          : rotatorDetail?.media_name
                          ? rotatorDetail?.media_name
                          : ""}
                      </div>
                      <div className="size">
                        {imageUrl
                          ? (imageUrl?.size / 1000).toFixed(1) + "KB"
                          : rotatorDetail?.media_size &&
                            rotatorDetail?.media_size + "KB"}
                      </div>
                    </Card>
                  )}
                </div>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="action_button_text"
                label="Button Text"
                className="custom-label"
                rules={[
                  {
                    required: true,
                    message: "Please enter button text.",
                  },
                ]}
              >
                <Input
                  className="title"
                  onChange={(e) => setButtonText(e.target.value)}
                  maxLength={255}
                />
              </Form.Item>
              <h3>Time Limit</h3>
              <div style={{ display: "flex", gap: 12 }}>
                <Form.Item
                  {...formItemLayout}
                  name="start_date"
                  label="Starting on"
                  style={{ width: 400 }}
                  className="custom-label"
                  rules={[
                    {
                      required: true,
                      message: "Please select start date.",
                    },
                  ]}
                >
                  <DatePicker
                    className="date-picker"
                    format={dateFormat}
                    suffixIcon={<img src={Calendar} />}
                    style={{ height: "38px" }}
                    onChange={(e) => changeStartDate(e)}
                  />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="end_date"
                  label="Ending on"
                  className="custom-label"
                  style={{ width: 400 }}
                  rules={[
                    {
                      required: true,
                      message: "Please select end date.",
                    },
                  ]}
                >
                  <DatePicker
                    className="date-picker"
                    format={dateFormat}
                    suffixIcon={<img src={Calendar} />}
                    style={{ height: "38px" }}
                    onChange={(e) => changeEndDate(e)}
                  />
                </Form.Item>
              </div>
              <Form.Item
                {...formItemLayout}
                name="sponsor_id"
                label="Presented by"
                className="custom-label"
              >
                <Select
                  optionFilterProp="children"
                  onChange={onChangeSponsor}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size={"large"}
                  suffixIcon={<img src={DownArrow} />}
                  style={{ height: 38, width: "100%", marginTop: 8 }}
                  className="custom-select"
                  options={sponsorOption}
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="sponsor_url"
                label="Sponsor’s URL"
                className="custom-label"
              >
                <Input className="title" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className="discard-btn"
                  // onClick={resetForm}
                  onClick={() => navigate("/home/home-rotator")}
                >
                  Cancel
                </Button>
                <Button
                  type="default"
                  htmlType="submit"
                  // type="text"
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => setCurrent(1)}
                  className="dark-base-btn"
                >
                  Next
                </Button>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                {...formItemLayout}
                name="detail_title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Please enter title.",
                  },
                ]}
              >
                <Input
                  className="title"
                  onChange={(e) => setDetailTitle(e.target.value)}
                  maxLength={255}
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label="Add Media"
                className="media-div"
              >
                <div
                  className={
                    detailPreview || detailImagePreview ? "uploader-div" : ""
                  }
                >
                  <Dragger
                    type="file"
                    customRequest={dummyRequest}
                    beforeUpload={handleBeforeUpload}
                    showUploadList={false}
                  >
                    <p className="ant-upload-drag-icon">
                      <img src={UploadFile} alt="" />
                    </p>
                    <div style={{ textAlign: "left", padding: "12px 0" }}>
                      {" "}
                      <p className="ant-upload-text">
                        Drag and Drop or
                        <span>Browse</span>
                      </p>
                      <p className="ant-upload-hint">
                        Upload photo or video (16:9 ratio)
                      </p>
                    </div>
                  </Dragger>
                  {detailPreview !== "" && (
                    <Card
                      hoverable
                      className="video-cards"
                      cover={
                        detailImgSrc ? (
                          <div
                            style={{
                              overflow: "hidden",
                              height: "250px",
                              borderRadius: 8,
                            }}
                          >
                            <span className="top-cancel-btn" 
                              style={{
                                display:
                                  !videoId || videoId === "" ? "none" : "block",
                              }}>
                              <CloseOutlined onClick={resetLandingDragger} />
                            </span>
                            <Image
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                              className="img"
                              src={detailImgSrc}
                            />
                          </div>
                        ) : rotatorDetail.detail_media_type === "video" &&
                          !videoUrl ? (
                          <>
                            <span className="top-cancel-btn">
                              <CloseOutlined onClick={resetLandingDragger} />
                            </span>
                            <div id="video-container" style={{ height: 250 }}>
                              <Player
                                url={rotatorDetail.detail_media_url}
                                percentage={100}
                                wrapperRef={wrapperRef}
                                fromUrl={true}
                                light={rotatorDetail.detail_media_thumbnail}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <span className="top-cancel-btn">
                              <CloseOutlined onClick={resetLandingDragger} />
                            </span>
                            <div
                              id="video-container"
                              style={{ height: percentage === 100 ? 240 : 240 }}
                            >
                              {percentage !== 100 && (
                                <div
                                  className="progress-div"
                                  style={{
                                    zIndex: percentage !== "100" ? "1" : "0",
                                    cursor: "default",
                                  }}
                                >
                                  {" "}
                                  <Progress
                                    percent={percentage}
                                    status="active"
                                    className="custom-progress-bar"
                                  />
                                </div>
                              )}
                              {videoUrl && (
                                <video width="400" controls height="100%">
                                  <source src={URL.createObjectURL(videoUrl)} />
                                </video>
                              )}
                            </div>
                          </>
                        )
                      }
                    >
                      {/* <div onClick={handleFullscreen}>
        <img src={Expand} style={{ fontSize: "2rem", color: "white" }} />
      </div> */}

                      <div
                        className="name"
                        // title={videoUrl ? videoUrl.name : detailImageUrl.name}
                        title={
                          videoUrl
                            ? videoUrl?.name
                            : detailImageUrl
                            ? detailImageUrl?.name
                            : rotatorDetail?.detail_media_name
                            ? rotatorDetail?.detail_media_name
                            : ""
                        }
                      >
                        {videoUrl
                          ? videoUrl?.name
                          : detailImageUrl
                          ? detailImageUrl?.name
                          : rotatorDetail?.detail_media_name
                          ? rotatorDetail?.detail_media_name
                          : ""}
                      </div>
                      <div className="size">
                        {videoUrl
                          ? ((videoUrl?.size / 1024) * 2).toFixed(1) + "MB"
                          : detailImageUrl
                          ? (detailImageUrl?.size / 1000).toFixed(1) + "KB"
                          : rotatorDetail.detail_media_size
                          ? rotatorDetail.detail_media_type === "video"
                            ? (
                                (rotatorDetail?.detail_media_size / 1024) *
                                2
                              ).toFixed(1) + "MB"
                            : rotatorDetail?.detail_media_size + "KB"
                          : ""}
                        {/* {rotatorDetail.detail_media_size} */}
                      </div>
                    </Card>
                  )}
                </div>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="editor"
                label="Body Text"
                className="custom-label"
              >
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={detailContent}
                  className="custom-toolbar"
                  onChange={handleProcedureContentChange}
                  placeholder="Describe the home rotator..."
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="detail_action_button_text"
                label="Button Text"
                className="custom-label"
                rules={[
                  {
                    required: true,
                    message: "Please enter button text.",
                  },
                ]}
              >
                <Input
                  className="title"
                  onChange={(e) => setDetailButtonText(e.target.value)}
                  maxLength={255}
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="detail_action_button_link"
                label="Button URL"
                className="custom-label"
                rules={[
                  {
                    required: true,
                    message: "Please enter button url",
                  },
                  {
                    type: "url",
                    message: "Please enter valid url",
                  },
                ]}
              >
                <Input
                  className="title"
                  onChange={(e) => setDetailButtonLink(e.target.value)}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="discard-btn"
                  // onClick={resetForm}
                  onClick={() => navigate("/home/home-rotator")}
                >
                  Cancel
                </Button>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldsValue }) => {
                    const {
                      detail_action_button_link,
                      detail_action_button_text,
                      detail_title,
                    } = getFieldsValue();
                    const detailMedia =
                      detailImgSrc ||
                      detailVideoId !== "" ||
                      detailImageUrl ||
                      detailPreview === "video"
                        ? true
                        : false;
                    const formIsComplete =
                      !!detail_action_button_link &&
                      !!detail_action_button_text &&
                      !!detail_title &&
                      !form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0 &&
                      !!detailMedia;
                    return (
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                          !formIsComplete ||
                          uploadingStart ||
                          startDate === "" ||
                          endDate === "" ||
                          buttonDisabled
                        }
                        style={{
                          margin: "0 8px",
                        }}
                        className="dark-base-btn"
                      >
                        Save
                      </Button>
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

const EditRotator = () => {
  return (
    <EditRotatorContextProvider>
      <EditRotatorPage />
    </EditRotatorContextProvider>
  );
};

export default EditRotator;
