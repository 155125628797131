import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Modal,
  Progress,
  Row,
  Spin,
} from "antd";
import React from "react";
import "./task-detail-modal.scss";
import Edit from "../../../assets/images/edit_pen.svg";
import Delete from "../../../assets/images/red_delete.svg";
import ReactPlayer from "react-player";
import Video from "../../../assets/Snail.mp4";
import Avatar from "../../../assets/images/group-avatar.svg";
import { getDate } from "../../../util/config";
import Play from "../../../assets/images/play.svg";
const TaskDetail = ({ taskDetailModal, setTaskDetailModal, selectedTask }) => {
  const handleOk = () => {
    setTaskDetailModal(false);
  };
  const handleCancel = () => {
    setTaskDetailModal(false);
  };
  const convertSeconds = (sec) => {
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    if (minutes > 0 && seconds > 0) {
      return `${minutes} min  ${seconds} sec`;
    } else if (minutes > 0 && seconds <= 0) {
      return `${minutes} min`;
    } else if (seconds > 0) {
      return `${seconds} sec`;
    }
  };
  let tags = selectedTask.tags.split(",");

  return (
    <Modal
      className="task-detail-modal"
      wrapClassName="task-detail-modal"
      open={taskDetailModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Report"}
      cancelText={"Cancel"}
      width={"750px"}
      footer={false}
    >
      <h1>Task Details</h1>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              {" "}
              <h3>{selectedTask.title}</h3>
            </Col>

            {/* <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
              {" "}
              <Button className="red-base-btn" icon={<img src={Delete} />}>
                Delete
              </Button>
              <Button className="edit-btn" icon={<img src={Edit} />}>
                Edit
              </Button>
            </Col> */}
            <Row className="creator">
              <Col span={12} className="label" style={{ marginBottom: 24 }}>
                Author
              </Col>
              <Col span={12} className="value">
                {selectedTask.created_by?.username}
              </Col>
              <Col span={12} className="label">
                Created on
              </Col>
              <Col span={12} className="value">
                {getDate(selectedTask.created_at)}
              </Col>
            </Row>
            {selectedTask.media_type &&
              // selectedTask.media_type === "embedded" 
              //  || selectedTask.media_type === "image"
              selectedTask.media_type !== "undefined"  && (
                <>
                  <Col span={24} className="label">
                    Media
                  </Col>
                  <Card hoverable className="media-card">
                    <>
                      {selectedTask.image && selectedTask.media_type !== "embedded" ? (
                        <div
                          style={{
                            overflow: "hidden",
                            height: "15Private0px",
                            borderRadius: 8,
                          }}
                        >
                          <Image
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            className="img"
                            src={selectedTask.image}
                          />
                        </div>
                      ) : (
                        <div id="video-container">
                          {selectedTask.media_url ? (
                            <ReactPlayer
                              key={selectedTask.media_url}
                              url={selectedTask.media_url}
                              light={selectedTask.media_thumbnail}
                              playIcon={<img src={Play} />}
                              // style={{width:100}}
                              width={"100%"}
                              height={"100%"}
                              controls
                            />
                          ) : (
                            <Spin
                              className="loader"
                              size="large"
                              style={{
                                position: "absolute",
                                left: "45%",
                                top: "41%",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </>
                  </Card>
                </>
              )}
            <Row className="detail">
              <Col span={12} className="label">
                Task Type
              </Col>
              <Col span={12} className="value">
                {selectedTask.score_based_test === true ? "Scored Task" : "Training Task"}
              </Col>
              <Col span={12} className="label">
                Time Limit
              </Col>
              <Col span={12} className="value">
                {selectedTask.is_time_limit === true
                  ? convertSeconds(selectedTask.time_limit_seconds)
                  : "N/A"}
              </Col>
              <Col span={12} className="label">
                {" "}
                Task Library Status
              </Col>
              <Col span={12} className="value">
                {selectedTask.is_public === true ? "Public" : "Private"}
              </Col>
              <Col span={12} className="label">
                Tags:
              </Col>
              <Col span={12} className="value">
                {tags.length > 0 && !tags.includes("")
                  ? tags.map((item, index) => (
                      <Button
                        className="purple-tag"
                        key={index}
                        style={{
                          textTransform: "capitalize",
                          marginLeft: 0,
                          marginRight: 8,
                        }}
                      >
                        {item}
                      </Button>
                    ))
                  : ""}
              </Col>
              <Col span={12} className="label">
                Skill Level
              </Col>
              <Col
                span={12}
                className="value"
                style={{ textTransform: "capitalize" }}
              >
                {selectedTask.skill_level}
              </Col>
            </Row>
          </Row>
        </Col>
        {/* <Col span={2}>
          <Divider type="vertical" />
        </Col>
        <Col span={11}>
          <h4>Used in 5 Group(s)</h4>
          <h5>This workout is in use in the following groups</h5>
          <div className="task-card">
            <Row>
              <img src={Avatar} />
              <h3>Woodview High School</h3>
            </Row>
            <Row style={{ width: "100%" }}>
              <h5>Completed by 12/25 members</h5>
              <h4>2 Workout(s)</h4>
            </Row>

            <Progress
              percent={80}
              showInfo={false}
              className="orange-progress-bar"
            />
          </div>
          <div className="task-card">
            <Row>
              <img src={Avatar} />
              <h3>Woodview High School</h3>
            </Row>
            <Row style={{ width: "100%" }}>
              <h5>Completed by 12/25 members</h5>
              <h4>2 Workout(s)</h4>
            </Row>

            <Progress
              percent={80}
              showInfo={false}
              className="orange-progress-bar"
            />
          </div>
          <div className="task-card">
            <Row>
              <img src={Avatar} />
              <h3>Woodview High School</h3>
            </Row>
            <Row style={{ width: "100%" }}>
              <h5>Completed by 12/25 members</h5>
              <h4>2 Workout(s)</h4>
            </Row>

            <Progress
              percent={80}
              showInfo={false}
              className="orange-progress-bar"
            />
          </div>
          <div className="task-card">
            <Row>
              <img src={Avatar} />
              <h3>Woodview High School</h3>
            </Row>
            <Row style={{ width: "100%" }}>
              <h5>Completed by 12/25 members</h5>
              <h4>2 Workout(s)</h4>
            </Row>

            <Progress
              percent={80}
              showInfo={false}
              className="orange-progress-bar"
            />
          </div>
        </Col> */}
      </Row>
    </Modal>
  );
};

export default TaskDetail;
