import React, { useState } from "react";
import "./tasks.scss";
import TaskList from "../../Groups/tasks-list/tasks-list";
import { Select  , Dropdown} from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import ReplaceTask from "../replace-task-modal/replace-task-modal";
import EditTask from "../../Tasks/edit-task-modal/edit-task-modal"
import DeleteTaskModal from "../../Tasks/delete-task/delete-task-modal";
const Tasks = () => {
  const [replaceTaskModal , setReplaceTaskModal] = useState(false)
  const [editTaskModal , setEditTaskModal] = useState(false)
  const [deleteTaskModal , setDeleteTaskModal] = useState(false)
  const onChange = (value) => {
  };
  const items = [
    {
      key: "replace",
      label: "Replace",
    },
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },
  ]
  return (
    <div className="tasks-main">
      <div style={{ display: "flex" }}>
        <h1>Overview</h1>
        {/* <button
          className="dark-base-btn"
          onClick={() => setGeneralDetail(false)}
        >
          Edit Group
        </button> */}
      </div>
      {/* <TaskList/> */}
      <div className="tasks-list">
        <div className="list">
          <div style={{ display: "flex", width: "100%" }}>
            <h3>Shooting License</h3>
            {/* <Select
              placeholder="Actions"
              optionFilterProp="children"
              popupClassName="action-select-dropdown"
              onChange={onchange}
              className="action-select"
              placement="bottomRight"
              options={[
                {
                  value: "replace",
                  label: "Replace",
                },
                {
                  value: "edit",
                  label: "Edit",
                },
                {
                  value: "delete",
                  label: "Delete",
                },
              ]}
            /> */}
                 <Dropdown
          placeholder="Actions"
          className="action-dropdown"
          menu={{
            items,

            onClick: ({ key }) => {
              if (key === "replace") {
                setReplaceTaskModal(true);
              }else if(key === "edit"){
                setEditTaskModal(true)
              } 
              else {
                setDeleteTaskModal(true)
                // setSelectedSport(record);
                // setEditSportModal(true);
              }
            },
          }}
          overlayClassName="workout-dropdown-menu"
        >
          <a onClick={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="task-added">
              Added on:
              <span className="time"> 4:23 PM </span>by
              <span className="name"> Kenny Thompson</span>
            </div>
            <div className="score" style={{ marginLeft: "auto" }}>
              Scored Task
              <span className="green-tag"> Beginners</span>
            </div>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="tags">
              Tags:
              <span className="purple-tag">Shooting</span>
              <span className="purple-tag">Dribbling</span>
              <span className="purple-tag">Conditioning</span>
            </div>

            <div
              className="task-added"
              style={{ padding: "14px 0", marginLeft: "auto" }}
            >
              Taken by:
              <span className="name">24 Members</span>
            </div>
          </div>
        </div>
      </div>
      <ReplaceTask replaceTaskModal={replaceTaskModal} setReplaceTaskModal={setReplaceTaskModal}/>
      <EditTask editTaskModal={editTaskModal} setEditTaskModal={setEditTaskModal} />
      <DeleteTaskModal deleteTaskModal={deleteTaskModal} setDeleteTaskModal={setDeleteTaskModal}/>
    </div>
  );
};
export default Tasks;
