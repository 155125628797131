import { useContext } from "react";
import {
    Modal,
    Row,
    Col,
} from "antd";
const CategoryModal = ({ categoryModal, setCategoryModal , allTags, selectedCategory, setSelectedCategory , categoryType  }) => {
    return (
        <Modal
            className="category-modal"
            open={categoryModal}
            //   onOk={handleOk}
            onCancel={() => setCategoryModal(false)}
            okText={"Finish Editing"}
            cancelText={"Cancel"}
            width={"769px"}
            footer={false}
        >
            <h1>Select {categoryType === "task" ? "Task" : "Workout"} category</h1>
            <p>Select category below to assign existing {categoryType === "task" ? "Task" : "Workout"}</p>
            <Row gutter={[14, 14]}>
                <Col className="gutter-row" span={8} onClick={() => { setSelectedCategory(""); setCategoryModal(false) }}>
                    <div className={"card " + (selectedCategory === "" ? "bordered" : "")} style={{ color: "#3f4254" }}>
                        All {categoryType === "task" ? "Tasks" : "Workouts"}
                    </div>
                </Col>
                {allTags.map((item, index) => (
                    <Col className={"gutter-row"} span={8} key={index} onClick={() => { setSelectedCategory(item.tag); setCategoryModal(false) }}>
                        <div style={{ backgroundImage: `url(${item.image})` }} className={"card " + (selectedCategory === item.tag ? "bordered" : "")}>
                            {item.tag}
                        </div>
                    </Col>
                ))}
            </Row>
        </Modal>
    );
};
export default CategoryModal;
