import React, { useEffect, useState } from "react";
import { Divider, Row, Tabs, Col, Input, Button } from "antd";
import "./subscriptions.scss";
import { getData } from "../../../util/api-calls";
import {
  SubscriptionContextProvider,
  SubscriptionContext,
} from "../../../context/setting-context/subscription_context";
import { useContext } from "react";
const TabPane = Tabs.TabPane;

const SubscriptionsPage = ({ sportsList }) => {
  console.log(sportsList);

  const Test = ({ prop }) => {
    console.log(prop);
    return (
      <div>
        <h1>{prop}</h1>
        <Divider />
        <h3>Subscription Price</h3>
        <Row style={{ marginTop: 32 }}>
          <Col span={8}>
            <h3>Get all Challenges for</h3>
          </Col>
          <Col span={16}>
            <label>Annually</label>
            <br />
            <span className="description">
              The price of all the challenges if the user wants to purchase an
              annual subscription
            </span>
            <Input addonBefore={"$"} defaultValue="3" placeholder="Please enter price" />
            <label>Monthly</label>
            <br />
            <span className="description">
              The price of all the challenges if the user wants to purchase an
              annual subscription
            </span>
            <Input addonBefore={"$"} defaultValue="3" placeholder="Please enter price"/>
            <Button className="dark-base-btn">Save</Button>
          </Col>
        </Row>
      </div>
    );
  };

  var oneTimeOptions = [];

  if (sportsList.length > 0) {
    for (let i = 0; i < sportsList.length; i++) {
      console.log(sportsList[i]);
      oneTimeOptions.push({
        key: sportsList[i].id,
        label: sportsList[i].name,
        children: <Test prop={sportsList[i].name} />,
      });
    }
  }
  return (
    <div className="subscriptions-main">
      <Tabs
        tabPosition="left"
        // onChange={onChange}
        type="card"
        className="sport-tabs"
        items={oneTimeOptions}
      />
    </div>
  );
};
const Subscriptions = ({ sportsList }) => {
  return (
    <SubscriptionContextProvider>
      <SubscriptionsPage sportsList={sportsList} />
    </SubscriptionContextProvider>
  );
};
export default Subscriptions;
