import { useContext } from "react";
import { Modal } from "antd";
import { NewWorkoutContext } from "../../../context/new-workout-context";
const WorkoutPrivacyModal = ({ privacyModal, setPrivacyModal ,setPrivacy}) => {
  const handleOk = () => {
    setPrivacy(true)
    setPrivacyModal(false);
  };
  const handleCancel = () => {
    setPrivacyModal(false);
    setPrivacy(false)
  };
  return (
    <Modal
      className="privacy-modal"
      open={privacyModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Share all Tasks in this Workout"}
      cancelText={"Do not share this Workout"}
      width={"608px"}
    >
      <h1>Task/Workout Privacy Adjustments Required</h1>
      <p>Task(s) within this workout are set to private, while the Workout Template as a whole is shared with other coaches. </p>
      <p>How would you like to proceed?
      </p>
    </Modal>
  );
};
export default WorkoutPrivacyModal;
