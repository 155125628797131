import React from "react";
import { Col, Modal, Row } from "antd";
import "./instructions.scss"
import set1 from "../../../assets/images/set_1.svg"
import set2_1 from "../../../assets/images/set_2.1.svg"
import set2_2 from "../../../assets/images/set_2.2.svg"
import set3 from "../../../assets/images/set_3.svg"
import set4_1 from "../../../assets/images/set_4.1.svg"
import set4_2 from "../../../assets/images/set_4.2.svg"
import set5 from "../../../assets/images/set_5.svg"


const Instructions = ({instructionsModal , setInstructionsModal}) =>{

    const handleOk = () => {
        setInstructionsModal(false);
      };
      const handleCancel = () => {
        setInstructionsModal(false);
      };
    return(
        <Modal  className="instructions-modal" 
        wrapClassName="instructions-modal"
        open={instructionsModal} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        okText={"Close"}
        width={"699px"}>
         <h1>Instructions</h1>
         <Row className="instruction-row">
            <Col span={8}>EQUIPMENT</Col>
            <Col span={16}>1-2 Basketbals</Col>
            <Col span={8}>ASSISTANCE</Col>
            <Col span={16}>1 Rebounder, 1 Passer, 1 Director*</Col>
            <Col span={8}>TOTAL SHOTS</Col>
            <Col span={16}>40</Col>
            <Col span={8}>TIME LIMIT</Col>
            <Col span={16}>4 minute(s)</Col>
         </Row>
          <p>* The “director” role can be used to monitor the recording time limit and call out instructions for which shot comes next. This position is not mandatory, but is encouraged.</p>
            <div className="instruction-detail">
            <label>SET 1 : LAYUPS (2 SHOTS)</label>
                <p>The BSA begins with a right-wing layup (Shot 1) and a left-wing layup (Shot 2). Each shot is worth 1 point. Start each drive from the wing behind the 3-point line. You can shoot these layups with either hand, with as much style and variation as you’d like. Think of the BSA as your “demo tape.” Show off the full scope of your abilities!</p>
            <img src={set1} />
            <label>SET 2 : ONE-DRIBBLE PULL-UPS (10 SHOTS)</label>
            <p>Start behind the 3-point line. Your first five shots are one-dribble pull-ups moving to your left (dribble with left hand). They are from the right corner, right wing, top, left wing, and left corner. The following five shots are one-dribble pull-ups moving to your right (dribble with your right hand). They are from the left corner, left wing, top, right wing, and right corner. Each of these ten shots are worth 2 points.</p>
            <img src={set2_1} />
            <img src={set2_2} />
            <label>SET 3 : CATCH-AND-SHOOT/FLY-BY 3S (15 SHOTS)</label>
            <p>From each of the five spots (beginning in the right corner and advancing to the right wing, top, left wing and left corner), you will attempt the same 3-shot sequence. The first two shots from each location are simple catch-and-shoot jumpers. The third shot from each spot is a pump-fake, side-dribble in either direction (your choice), aka a “fly-by.” This is a game-situation shot where your defender bites on your fake and you relocate to get off an uncontested shot. Each of these fifteen shots is worth 3 points.</p>
            <img src={set3} />
            <label>SET 4 : STAR SHOOTING 3S (10 SHOTS)</label>
            <p>Beginning in the left corner, you will attempt catch-and-shoot 3-pointers in a sequence that draws the shape of a star: left corner, right wing, left wing, right corner, and top. This five-shot sequence repeats once more, giving you ten total shots worth 3 points each.</p>
            <img src={set4_1} />
            <img src={set4_2} />
            <label>SET 5 : FREE THROWS (3 SHOTS)</label>
            <p>The BSA concludes with 3 free throws, each worth 1 point.</p>
            <img src={set5} />
            </div>

         
          {/* <TextArea
                // value={commentValue[[item.id]]}
                // onChange={handleCommentValue}
                placeholder="Your response here..."
                autoSize={{
                  minRows: 8,
                  maxRows: 9,
                }}
                // onKeyPress={enterCommentLine}
              /> */}
        </Modal>
    )
}
export default Instructions;