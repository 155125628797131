import { useContext } from "react";
import { Modal } from "antd";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { EditTaskContext } from "../../../context/edit-task-context";
const UpdateTaskModal = ({
  updateTaskModal,
  setUpdateTaskModal,
  selectedTask,
  setNewTaskArray,
  setEditTaskModal,
  newTaskArray,
  setExistingTaskModal,
  curriculum_group
}) => {
  const { setUpdateTask, postUpdateTask } = useContext(EditTaskContext);
  const handleOk = () => {
    setUpdateTask(false);
    setUpdateTaskModal(false);
    setEditTaskModal(false);
    setExistingTaskModal(false)
    postUpdateTask(
      selectedTask,
      setNewTaskArray,
      newTaskArray,
      setEditTaskModal,
      curriculum_group
    );
  };
  const handleCancel = () => {
    setUpdateTaskModal(false);
    setUpdateTask(true);
    setExistingTaskModal(false)
    postUpdateTask(
      selectedTask,
      setNewTaskArray,
      newTaskArray,
      setEditTaskModal,
      curriculum_group
    );
    setEditTaskModal(false);
  };
  return (
    <Modal
      className="privacy-modal"
      open={updateTaskModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Just This Assignment"}
      cancelText={"Update This Task Template"}
      width={"608px"}
    >
      <h1>Update Task Options</h1>
      <p>You made modifications to this Task.</p>
      <p>
        Would you like to use them just this once, or save the Task template for
        future use?
      </p>
      <div className="info-div">
        {/* <img src={Support} className="loader"/> */}
        <ExclamationCircleTwoTone
          twoToneColor="#F15A29"
          style={{ fontSize: 20 }}
        />
        <p className="status">
          Current and previous assignments of this task will NOT be affected
        </p>
      </div>
    </Modal>
  );
};
export default UpdateTaskModal;
