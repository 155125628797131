import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Collapse,
  DatePicker,
  Select,
  Button,
  Input,
  Spin,
  Empty,
} from "antd";
import "./index.scss";
import dayjs from "dayjs";
import CollapseIcon from "../../../assets/images/collapse.svg";
import Calendar from "../../../assets/images/calendar.svg";
import Expand from "../../../assets/images/expand.svg";
import Plus from "../../../assets/images/plus-squared.svg";
import Edit from "../../../assets/images/edits.svg";
import DownArrow from "../../../assets/images/arrows.svg";
import CombineMeasurementModal from "./combine-measurement-modal/new-cm-modal";
import { getDateWithoutDay } from "../../../util/config";
import { getData } from "../../../util/api-calls";
import EditCombineMeasurementModal from "./combine-measurement-modal/edit-cm-modal";
import {
  CombineMeasurementContext,
  CombineMeasurementContextProvider,
} from "../../../context/user-context/combine-measurement-context";

const { Panel } = Collapse;

const CombineMeasurementsPage = () => {
  const {
    editModal,
    setEditModal,
    setMeasurementDate,
    allProfileMeasurements,
    isModalOpen,
    setIsModalOpen,
    loading,
  } = useContext(CombineMeasurementContext);
  const style = {
    padding: "5px 0",
  };
  const colStyles = {
    width: "14.28%",
    padding: "0 5px",
    height: "86px",
  };
  const dateFormatList = "DD MMM YYYY";

  const divStyles = {
    borderRadius: "12px",
    textAlign: "left",
    border: "1px dashed var(--gray-gray-300, #E1E3EA)",
    padding: "20px 10px",
    height: "100%",
  };
  const onChange = (key) => {
  };
  const onSearch = (value) => console.log(value);
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  return (
    <div className="combine-measurements-main">
      <div style={{ display: "flex" }}>
        <h1>Combine Measurements</h1>
        <button className="dark-base-btn" onClick={showModal}>
          <img src={Plus} />
          Add Measurements
        </button>
      </div>

      <div className="filter-div">
        <DatePicker
          picker="month"
          className="month-picker"
          suffixIcon={<img src={Calendar} />}
          // defaultValue={dayjs("01 JAN 2015", dateFormatList)}
          format={dateFormatList}
        />
        <Select
          placeholder="Newly Added"
          optionFilterProp="children"
          onChange={onChangeSelect}
          suffixIcon={<img src={DownArrow} />}
          className="select-input"
          options={[
            {
              value: "jack",
              label: "Jack",
            },
            {
              value: "lucy",
              label: "Lucy",
            },
            {
              value: "tom",
              label: "Tom",
            },
          ]}
        />
      </div>
      {loading && loading == true ? (
        <Spin
          className="loader"
          size="large"
          style={{
            position: "absolute",
            left: "50%",
            top: "180%",
          }}
        />
      ) : Object.keys(allProfileMeasurements).length > 0 ? (
        <Collapse
          onChange={onChange}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            !isActive ? <img src={Expand} /> : <img src={CollapseIcon} />
          }
          className="collapsible-rows"
        >
          {Object.keys(allProfileMeasurements).map(
            (item) => {
              return (
                <Panel
                  header={
                    <div className="collapse-label">
                      {getDateWithoutDay(item)}
                    </div>
                  }
                >
                  <div className="combine-measurements">
                    <Row type="flex" gutter={16} style={{ margin: "0 -5px" }}>
                      {allProfileMeasurements[item].map((data) => (
                        <Col style={{ ...colStyles }}>
                          <div style={{ ...divStyles }} className="inner-div">
                            <div className="measurements">
                              {data.measurement.title === "Weight"
                                ? data.value +  " lbs"
                                : data.measurement.title ===
                                    "3/4 Court Sprint" ||
                                  data.measurement.title === "Lane Agility"
                                ? data.value + " sec"
                                : data.measurement.title === "Max. Vertical" ||
                                  data.measurement.title === "Standing Vertical"
                                ? data.inch +
                                  "”" +
                                  (data.decimal !== 0
                                    ? "." + data.decimal * 100 + "”"
                                    : "")
                                : data.ft +
                                  "’" +
                                  data.inch +
                                  // "”" +
                                  (data.decimal !== 0
                                    ? "." + data.decimal * 100 + "”"
                                    : "”")}
                            </div>
                            <div className="label">
                              {data.measurement.title}
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <Button
                      className="red-base-btn"
                      onClick={()=>{setEditModal(true)
                        setMeasurementDate(item)
                        // setMeasurementDate(allProfileMeasurements[item])
                      }
                      }
                      icon={<img src={Edit} />}
                    >
                      Edit Measurements
                    </Button>
                  </div>
                </Panel>
              );
            }
            // }
            // )
          )}
        </Collapse>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ marginBlock: 70 }}
        />
      )}
      {isModalOpen && (
        <CombineMeasurementModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
       )} 
      {editModal && (
        <EditCombineMeasurementModal
          editModal={editModal}
          setEditModal={setEditModal}
        />
     )}
    </div>
  );
};

const CombineMeasurements = () => {
  return (
    <CombineMeasurementContextProvider>
      <CombineMeasurementsPage />
    </CombineMeasurementContextProvider>
  );
};

export default CombineMeasurements;
