import { useContext, useRef, useState } from "react";
import "./existing-workout.scss";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import { Button, Input, Select, Spin ,Radio,Checkbox} from "antd";
import WorkoutModal from "./workout-modal";
import { getDate } from "../../../util/config";
import DownArrow from "../../../assets/images/down-arrow.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  ExistingWorkoutContext,
  ExistingWorkoutContextProvider,
} from "../../../context/existing-workout-context";
import Search from "../../../assets/images/search.svg";
import SelectedWorkoutDetail from "./selected-workout-detail";
import { useNavigate } from "react-router-dom";
import CategoryModal from "./category-modal";
const ExistingWorkoutPage = ({ setWorkoutDetail, setExistingWorkout ,generalDetail }) => {
  const navigate = useNavigate();
  const [showAllWorkouts, setShowAllWorkouts] = useState(true);
  const {
    existingWorkouts,
    workoutTasks,
    setWorkoutTasks,
    params,
    setSelectedCategory,
    allTags,
    setUpdateTemplate,    
    setSkillLevel,
    setTaskType,
    setSort,
    search,
    setSearch,
    setExistingWorkouts,
    loading,taskType,selectedCategory
  } = useContext(ExistingWorkoutContext);
  const [workoutUpdateModal, setWorkoutUpdateModal] = useState(false);
  const [categoryModal , setCategoryModal] = useState(false)
  const inputRef = useRef(null);
  const showWorkouts = () => {
    setWorkoutDetail(false);
    setExistingWorkout(false);
    navigate({
      search: `id=${params.id}&tab=workouts`,
    });
  };
  const onChangeCategory = (e) => {
    inputRef.current.focus({
              cursor: 'all',
            });
    setCategoryModal(true)
  };
  const onChangeSkill = (e) => {
    setSkillLevel(e);
  };
  const onChangeTaskType = (e) => {
    setTaskType(e.target.value);
  };
  const tagOptions = [{
    value :"all" , 
    label: "All"
  }];
  for (let i = 0; i < allTags.length; i++) {
    tagOptions.push({
      value: allTags[i].tag,
      label: allTags[i].tag,
    });
  }
  const searchWorkout= (e) => {
    if (e.target.value.length >= 3) {
      setExistingWorkouts([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setExistingWorkouts((workoutsData) => []);
      setExistingWorkouts([]);
      setSearch("");
    }
  };
  const options = [
    {
      value: "beginner",
      label: "Beginner",
    },
    {
      value: "intermediate",
      label: "Intermediate",
    },
    {
      value: "advanced",
      label: "Advanced",
    },
  ];
  const taskOptions = [
    {
      value: "ballogy",
      label: "Ballogy workouts",
    },
    {
      value: "group",
      label: "Only this group's workouts",
    },
    {
      value: "external",
      label: "External workouts",
    }
  ]
  return (
    <div className="existing-workout-main">
      <div className="heading-row">
      <FaArrowLeftLong className= "left-arrow" onClick={() => showWorkouts()} />
        <h1>Assign an existing workout</h1>
      </div>
      {showAllWorkouts ? (
        <>
          <label htmlFor="category">Workout category</label>
          <br />
          <Input
            placeholder="Select Category"
            optionFilterProp="children"
            onClick={onChangeCategory}
            suffixIcon={<img src={DownArrow} />}
            ref={inputRef}
            className="category-select search-select-input"
            style={{ height: 38 , width:413, color:"#7e8299" }}
            value={selectedCategory === "" ? "All Workouts" : selectedCategory}
          />
          <div
            className="tasks-filter"
          >
            <h3>Workouts</h3>
            <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchWorkout}
            style={{ marginBottom: "26px",width:413 }}
          />
            <br />
            <Checkbox.Group options={options} defaultValue={["beginner","intermediate","advanced"]} onChange={onChangeSkill} className="custom-checkbox"/>
            <div style={{ display: "flex", marginBottom: "26px" , marginTop :15,width:700 }}>
              {/* <Select
                placeholder="Skill Level"
                optionFilterProp="children"
                suffixIcon={<img src={DownArrow} />}
                className="select-input"
                onChange={onChangeSkill}
                options={[
                  {
                    value: "beginner",
                    label: "Beginner",
                  },
                  {
                    value: "intermediate",
                    label: "Intermediate",
                  },
                  {
                    value: "advanced",
                    label: "Advanced",
                  },
                ]}
              />
              <Select
                placeholder="Select workout type"
                optionFilterProp="children"
                className="select-input"
                suffixIcon={<img src={DownArrow} />}
                onChange={onChangeTaskType}
                options={[
                  {
                    value: "ballogy",
                    label: "Ballogy workouts",
                  },
                  {
                    value: "group",
                    label: "Only this group's workouts",
                  },
                  {
                    value: "external",
                    label: "External workouts",
                  }
                ]}
              /> */}
              <Radio.Group options={taskOptions} onChange={onChangeTaskType} value={taskType} className="custom-radio" style={{marginTop:8}}/>
              <Select
                placeholder="Newest"
                optionFilterProp="children"
                suffixIcon={<img src={DownArrow} />}
                className="select-input"
                style={{marginLeft:"auto",marginRight:0}}
                onChange={(e) => setSort(e)}
                options={[
                  {
                    value: true,
                    label: "Newest",
                  },
                  {
                    value: false,
                    label: "Oldest",
                  },
                ]}
              />
            </div>
          </div>
          {loading && loading == true ? (
          <Spin
            className="loader"
            size="large"
            style={{
              margin: "5% 50%",
            }}
          />
        ) : (
          <div className="tasks-list">
            {existingWorkouts.map((item, index) => {
              let tags = item.tags.split(",");
              return (
                <div
                  className="list"
                  key={index}
                  onClick={() => {
                    setWorkoutTasks(item);
                    setShowAllWorkouts(false);
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <h3>{item.title}</h3>
                    <Button
                      className="green-tag"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item.skill_level}
                    </Button>
                  </div>
                  <div className="task-added">
                    Added on:
                    <span className="time"> {getDate(item.created_at)} </span>by
                    <span className="name"> {item.group_name}</span>
                  </div>
                  <div className="tags">
                    Tags:
                    {tags.length > 0 && !tags.includes("")
                      ? tags.map((item, index) => (
                          <Button
                            className="purple-tag"
                            key={index}
                            style={{ textTransform: "capitalize" }}
                          >
                            {item}
                          </Button>
                        ))
                      : ""}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        </>
      ) : (
        <SelectedWorkoutDetail
          setShowAllWorkouts={setShowAllWorkouts}
          workoutTasks={workoutTasks}
          workoutUpdateModal={workoutUpdateModal}
          setWorkoutUpdateModal={setWorkoutUpdateModal}
          setNewWorkout={setExistingWorkout}
          generalDetail={generalDetail}
        />
      )}
      <WorkoutModal
        workoutUpdateModal={workoutUpdateModal}
        setWorkoutUpdateModal={setWorkoutUpdateModal}
        setUpdateTemplate={setUpdateTemplate}
        generalDetail={generalDetail}
      />
      <CategoryModal categoryModal={categoryModal} setCategoryModal={setCategoryModal} allTags={allTags}  selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} categoryType={"workout"}/>
    </div>
  );
};
const ExistingWorkout = ({ setWorkoutDetail, setExistingWorkout , generalDetail }) => {
  return (
    <ExistingWorkoutContextProvider>
      <ExistingWorkoutPage
        setWorkoutDetail={setWorkoutDetail}
        setExistingWorkout={setExistingWorkout}
        generalDetail={generalDetail}
      />
    </ExistingWorkoutContextProvider>
  );
};
export default ExistingWorkout;
