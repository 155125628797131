import React, { useContext, useEffect, useState } from "react";
import "./workout-detail.scss";
import SortableList, { SortableItem } from "react-easy-sort";
import DragIndicator from "../../../assets/images/drag_indicator.svg";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  Button,
  Input,
  Checkbox,
  Switch,
  Modal,
  Col,
  Row,
  Card,
  Image,
} from "antd";
import ReactPlayer from "react-player";
import { convertSeconds } from "../../../util/config";
import {
  WorkoutContext,
  WorkoutContextProvider,
} from "../../../context/challenge-workout-context/workout-context";
import { useNavigate } from "react-router-dom";
let totalReps = 0;
const WorkoutDetailPage = () => {
  const navigate = useNavigate();
  const {
    selectedWorkout,
    getSelectedworkout,
    title,
    setNewTaskArray,
    current,
    newTaskArray,
    error,
    setTitle,
    setPrivacy,
    setCurrent,
  } = useContext(WorkoutContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const [selectedTask, setSelectedTask] = useState({});
  const [url, setUrl] = useState("");
  useEffect(() => {
    getSelectedworkout();
  }, []);
  useEffect(() => {
    setTitle(selectedWorkout.title);
    setNewTaskArray(selectedWorkout.tasks);
  }, [selectedWorkout]);
  const onSortEnd = (oldIndex, newIndex) => {
    setNewTaskArray((prevState) => {
      const newItems = [...prevState];
      if (oldIndex > newIndex) {
        for (let i = oldIndex; i >= newIndex; i--) {
          newItems[i].order++;
          newItems[oldIndex].order = newIndex + 1;
        }
      } else if (oldIndex < newIndex) {
        for (let i = oldIndex; i <= newIndex; i++) {
          newItems[i].order--;
          newItems[oldIndex].order = newIndex;
        }
      }
      return newItems.sort((a, b) => a.order - b.order);
    });
  };
  let webUrl = window.location.pathname;
  const showWorkout = () => {
    navigate({
      pathname: `/home/workouts`,
    });
  };
  const updateState = (index) => (e) => {
    const newArray = newTaskArray.map((item, i) => {
      if (index === i) {
        if (e.target) {
          return {
            ...item,
            [e.target.name]:
              typeof e.target.value === "string"
                ? parseInt(e.target.value)
                : e.target.checked,
          };
        } else {
          if (e === true) {
            setCurrent(index);
          } else {
            setCurrent();
          }
          return { ...item, lock: e };
        }
      } else {
        return item;
      }
    });
    setNewTaskArray(newArray);
  };
  const handleOk = () => {
    let newArray = newTaskArray.filter((e) => e.id !== deletedItem.id);
    setNewTaskArray(newArray);
    setDeletedItem({});
    setDeleteModal(false);
  };
  useEffect(() => {
    setPrivacy(false);
  }, [newTaskArray?.length]);
  totalReps = newTaskArray?.reduce((accumulator, object) => {
    return accumulator + object.num_completion;
  }, 0);
  return (
    <div className="new-workout-main">
      <div className="heading-row">
      <FaArrowLeftLong className= "left-arrow" alt="" onClick={() => showWorkout()} />
        <h1>{title}</h1>
      </div>
      <Row>
        <div style={{ width: "55%" }}>
          {error && (
            <span className="error-message" style={{ marginTop: 0 }}>
              Please enter workout title.
            </span>
          )}
          {newTaskArray && newTaskArray.length > 0 && (
            <div className="workout-overview">
              {!webUrl.includes("challenge") && <h3>Workout Overview</h3>}
              <div className="total">
                Total Tasks
                <span>
                  {newTaskArray &&
                    newTaskArray.filter(
                      (ele, ind) =>
                        ind ===
                        newTaskArray.findIndex((elem) => elem.id === ele.id)
                    ).length}
                </span>
                {!webUrl.includes("challenge") && (
                  <>
                    Total Reps
                    <span>{isNaN(totalReps) ? 0 : totalReps}</span>
                  </>
                )}
              </div>
              <SortableList
                onSortEnd={onSortEnd}
                className="list"
                draggedItemClassName="dragged"
              >
                {newTaskArray &&
                  newTaskArray
                    .filter(
                      (ele, ind) =>
                        ind ===
                        newTaskArray.findIndex((elem) => elem.id === ele.id)
                    )
                    .map((item, index) => {
                      return (
                        <SortableItem key={index}>
                          <div
                            className="overview-card"
                            style={{ listStyleType: "none" }}
                          >
                            <div style={{ display: "flex" }}>
                              <h3
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedTask(item);
                                  setUrl(item.media_url);
                                }}
                              >
                                {item.title}
                              </h3>
                            </div>
                            <div className="form-data">
                              <div className="reps">
                                <label htmlFor="complete-task">
                                  Complete task in
                                </label>
                                <br />
                                <Input
                                  addonBefore={"Reps"}
                                  defaultValue={item.num_completion}
                                  className="reps-input"
                                  name={"num_completion"}
                                  onChange={updateState(index)}
                                  min={1}
                                  value={
                                    item.num_completion > 0
                                      ? item.num_completion
                                      : ""
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="extra">
                              <Checkbox
                                className="custom-checkbox"
                                defaultChecked={item.allow_more_reps}
                                checked={item.allow_more_reps}
                                onChange={updateState(index)}
                                name="allow_more_reps"
                                disabled
                              />
                              <span className="allowed">
                                Allowed extra reps.
                              </span>
                              {index !== 0 && (
                                <div className="lock-div">
                                  Lock this task
                                  <Switch
                                    defaultChecked={item.lock}
                                    checked={item.lock}
                                    // disabled={index > current}
                                    name="lock"
                                    onChange={updateState(index)}
                                    disabled
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </SortableItem>
                      );
                    })}
              </SortableList>
            </div>
          )}
        </div>
        {Object.keys(selectedTask).length > 0 && (
          <div
            className="task-detail"
            style={{
              paddingLeft: 100,
              width: "45%",
            }}
          >
            <Row style={{ gap: 24 }}>
              <span className="span">Task Details</span>
              <h1>{selectedTask.title}</h1>
              <Row>
                <Col span={10} className="label-col">
                  <h3>Task type</h3>
                </Col>
                <Col span={14} className="data-col">
                  <h3>{selectedTask.type}</h3>
                </Col>
              </Row>
              {selectedTask.media_required === true && (
                <Row>
                  <Col span={10} className="label-col">
                    <h3>Time limit</h3>
                  </Col>
                  <Col span={14} className="data-col">
                    <h3>{convertSeconds(selectedTask.time_limit_seconds)}</h3>
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={10} className="label-col">
                  <h3>Task Library status</h3>
                </Col>
                <Col span={14} className="data-col">
                  <h3>
                    {selectedTask.is_public === true ? "Public" : "Private"}
                  </h3>
                </Col>
              </Row>
              {selectedTask.media_type && (
                <>
                  <Col span={24} className="data-col">
                    <h3
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        marginBottom: 8,
                      }}
                    >
                      Media
                    </h3>
                  </Col>
                  <Col span={24} className="label-col">
                    <Card hoverable className="media-card">
                      {selectedTask.media_type === "image" ? (
                        <div
                          style={{
                            overflow: "hidden",
                            height: "150px",
                            borderRadius: 8,
                          }}
                        >
                          <Image
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            className="img"
                            src={selectedTask.image}
                          />
                        </div>
                      ) : (
                        <ReactPlayer url={url} controls key={url} />
                      )}
                    </Card>
                  </Col>
                </>
              )}
              <Col span={24} className="data-col">
                <h3 style={{ fontSize: 18 }}>Instructions </h3>
              </Col>
              <Row>
                <Col span={6} className="label-col">
                  <h3>Step 1</h3>
                </Col>
                <Col span={18} className="data-col">
                  <h3>Watch/read the instructions above</h3>
                </Col>
              </Row>
              {selectedTask.media_required === true && (
                <Row>
                  <Col span={6} className="label-col">
                    <h3>Step 2</h3>
                  </Col>
                  <Col span={18} className="data-col">
                    <h3>
                      Record yourself completing the task and upload your
                      video.*
                    </h3>
                  </Col>
                </Row>
              )}
            </Row>
          </div>
        )}
      </Row>
      <Modal
        className="delete-task-modal"
        open={deleteModal}
        onOk={handleOk}
        onCancel={() => setDeleteModal(false)}
        okText={"Remove"}
        cancelText={"Cancel"}
        width={"608px"}
        //   footer={false}
      >
        <h1>Remove this task</h1>
        <p>Are you sure you want to remove this task? </p>
      </Modal>
    </div>
  );
};
const WorkoutDetail = ({ setWorkoutDetail, setNewWorkout }) => {
  return (
    <WorkoutContextProvider>
      <WorkoutDetailPage
        setWorkoutDetail={setWorkoutDetail}
        setNewWorkout={setNewWorkout}
      />
    </WorkoutContextProvider>
  );
};
export default WorkoutDetail;
