import React, { useContext, useEffect, useState } from "react";
import { deleteItem, getData, jsonPostData } from "../../util/api-calls";
import CryptoJs from "crypto-js";
import { UserContext } from "../user-context";
import { useNavigate } from "react-router-dom";
import * as tus from "tus-js-client"
import axios from "axios";

export const HomeRotatorContext = React.createContext({});
export const HomeRotatorContextProvider = ({ children = {} }) => {
    const [sponsorModal , setSponsorModal] = useState(false)
    const [pageNumber , setPageNumber] = useState(1)
    const [search , setSearch] = useState("")
    const [pageSize , setPageSize] = useState(10)
    const [allRotators , setAllRotators] = useState([])
    const [loading , setLoading] = useState(false)
    const [total , setTotal] = useState(0)
    const [sportsList, setSportsList] = useState([]);
    const [sport, setSport] = useState("");
    const [sort , setSort] = useState(true)
    const [startDate , setStartDate] = useState("")
    const [endDate , setEndDate] = useState("")
    const [status , setStatus] = useState("")
    const getAllRotators = () => {
      setLoading(true)
      getData( `slide?sport_id=${sport}&search=${search}&order_desc=${sort}&status=${status}&start_date=${startDate}&end_date=${endDate}&page=${pageNumber}&page_size=${pageSize}`).then((res)=>{
        setTotal(res.data.count)
        setAllRotators(res.data.results)
      }).catch((err)=>{
        console.log(err)
      }).finally(()=>{
        setLoading(false)
      })
    }
  const getSportList = () => {
    getData(`sport`)
      .then((res) => {
        setSportsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteRotator = (e) => {
    deleteItem(`slide/${e}`).then((res)=>{
      getAllRotators();
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getAllRotators()
  },[sport , sort , status , startDate , endDate , search , pageNumber , pageSize])
  React.useEffect(()=>{
    getSportList();
  } , [
]) 

  const defaultContext = {
    sponsorModal ,
    setSponsorModal,
    pageNumber,
    setPageNumber,
    search,
    setSearch,
    pageSize,
    setPageSize,
    loading,
    setLoading,
    total,allRotators,
    sportsList,
    sport,
    setSport,
    sort,setSort,setStatus,
    setStartDate,setEndDate,
    deleteRotator,
    setAllRotators
  };
  return (
    <HomeRotatorContext.Provider value={defaultContext}>
      {children}
    </HomeRotatorContext.Provider>
  );
};
