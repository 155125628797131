import React, { useContext, useState } from "react";
import { Table, Tabs, Input, Button, Select, DatePicker, Dropdown } from "antd";
import "./challenges-home.scss";
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
import { useNavigate } from "react-router-dom";
import { getDate } from "../../../util/config";
import DownArrow from "../../../assets/images/arrows.svg";
import Calendar from "../../../assets/images/calendar.svg";
import RowVertical from "../../../assets/images/row-vertical.svg";
import Filter from "../../../assets/images/filter.svg";
import {
  GroupContext,
  GroupContextProvider,
} from "../../../context/group-context/group-context";
const Challenges = () => {
  const dateFormat = "ddd. MMMM D, YYYY";

  const { pageNumber, pageSize, groups, totalGroups, handleTableChange } =
    useContext(GroupContext);
  const navigate = useNavigate();
  const onChange = (key) => {
  };
  const tabItems = [
    {
      key: "all",
      label: `All`,
      //   children: `Content of Tab Pane 1`,
      // children: <Profile />
    },
    {
      key: "archived",
      label: `Archived`,
      // children: `Content of Tab Pane 2`,
      //   children: <UserAccountTable />
    },
  ];
  const items = [
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "tom",
      label: "Tom",
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { RangePicker } = DatePicker;
  const onChangeSelect = (value) => {
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Sport",
      dataIndex: "sport_name",
      key: "sport_name",
    },
    {
      title: "tasks",
      dataIndex: "tasks",
      key: "tasks",
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "taken by",
      dataIndex: "taken",
      key: "taken",
    },
    {
      title: "presented by",
      dataIndex: "presented",
      key: "presented",
    },
    {
      title: "ending",
      dataIndex: "ending",
      key: "ending",
    },
    {
      title: "points",
      dataIndex: "points",
      key: "points",
    },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      render: (text, key) => (
        <Dropdown
          placeholder="Actions"
          className="custom-dropdown"
          menu={{
            items,

            // onClick: ({ key }) => {
            //   if (key === "delete") {
            //     deleteSport(record);
            //   } else {
            //     setSelectedSport(record);
            //     setEditSportModal(true);
            //   }
            // },
          }}
          overlayClassName="workout-dropdown-menu"
        >
          <a onClick={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
      ),
    },
  ];
  const data = [];
  for (let i = 0; i < 5; i++) {
    data.push({
      key: i,
      id: "3",
      title: "Beat Desmond Bane...",
      sport_name: "Basketball",
      tasks: 2,
      price: "$15",
      taken: "543 members",
      presented: "ITZ Sports",
      ending: "Sep 7, 2023",
      points: "364",
    });
  }
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const gotoChallengeDetail = (record) => {
    navigate({
      pathname: `./${record.name}`,
      search: `?id=${record.id}`,
    });
  };

  return (
    <div className="challenges-home-main">
      <Tabs defaultActiveKey="1" items={tabItems} onChange={onChange} />
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
          />
          <div className="right-div">
            <Select
              placeholder="Newest"
              optionFilterProp="children"
              onChange={onChange}
              suffixIcon={<img src={DownArrow} />}
              className="select-input"
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
            />
            <button className="red-base-btn">
              <img src={Filter} alt="filter" />
            </button>
          </div>
        </div>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => gotoChallengeDetail(record),
            };
          }}
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: totalGroups,
          }}
          columns={columns}
          dataSource={data}
          className="table-container"
        />
      </div>
    </div>
  );
};
export default Challenges;
