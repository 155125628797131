import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Radio,
  Select,
  Upload,
  DatePicker,
  Divider,
  Spin,
  InputNumber,
} from "antd";
import "./create-new-group.scss";
import Edit from "../../../assets/images/edit.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import Close from "../../../assets/images/close.svg";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import DownArrow from "../../../assets/images/down-arrow.svg";
import Calendar from "../../../assets/images/calendar.svg";
import { useNavigate } from "react-router-dom";
import {
  CreateGroupContextProvider,
  CreateGroupContext,
} from "../../../context/group-context/create-group-context";
import SubscriptionHistory from "../subscription-history/subscription-history";
const { TextArea } = Input;
const dateFormat = "ddd. MMMM D, YYYY";

const formItemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};
const CreateGroupPage = () => {
  const {
    sportsList,
    createGroup,
    type,
    setSport,
    setMemberLimit,
    seatLimit,
    setImageUrl,
    setSeatLimit,
    setType,
    setLevel,
    memberLimit,
    level,
    setCombineLeaderBoard,
    setBsaLeaderBoard,
    setWorkoutLeaderBoard,
    imgSrc,
    bsaValueoneTime,
    bsaValueEachMonth,
    setBsaValue,
    setImgSrc,
    subscriptionStatus,
    setNewSubscription,
    setSubscriptionHistory,
    newSubscription,
    setSubscriptionStatus,
    subscriptionHistory,
    subStart,subExpire,setSubExpire,setSubStart,
    loading,
    access,
    setAccess,
    editSubscription,
    setEditSubscription,
    setCurriculum,
    imageError,
    setImageError
    // subNotes , subExpire , subStart
  } = useContext(CreateGroupContext);
  // const {subStart , subExpire , newSubscription,subscriptionStatus , setNewSubscription,subscriptionHistory,setSubscriptionHistory,setSubscriptionStatus} = useContext(SubscriptionContext)
  const navigate = useNavigate();
  const [bsaCredit, setBsaCredit] = useState();
  const [form] = Form.useForm();
  const onChangeType = (e) => {
    setType(e.target.value);
  };
  const onChangeLimit = (e) => {
    if (e.target.value === true) {
      setSeatLimit(true);
    } else {
      setSeatLimit(false);
    }
  };
  const onChangeSkillLevel = (e) => {
    setLevel(e);
  };
  const onchangeLeaderBoard = (e) => {
    if (e.indexOf("display_workout_leaderboard") > -1) {
      setWorkoutLeaderBoard(true);
    } else {
      setWorkoutLeaderBoard(false);
    }
    if (e.indexOf("display_bsa_leaderboard") > -1) {
      setBsaLeaderBoard(true);
    } else {
      setBsaLeaderBoard(false);
    }
    if (e.indexOf("display_combine_measurement_leaderboard") > -1) {
      setCombineLeaderBoard(true);
    } else {
      setCombineLeaderBoard(false);
    }
  };
  const onChangeBsa = (e) => {
    if (e.target.value === "none") {
      setBsaCredit(1);
    } else if (e.target.value === "one-time") {
      setBsaCredit(2);
    } else {
      setBsaCredit(3);
    }
  };
  const changeSport = (e) => {
    setSport(e.id);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    setImageError(false)
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    console.log(file);
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  };
  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  const oneTimeOptions = [];
  for (let i = 1; i <= 10; i++) {
    oneTimeOptions.push({
      value: i.toString(10),
      label: i.toString(10),
    });
  }
  const monthlyOptions = [];
  for (let i = 1; i <= 10; i++) {
    monthlyOptions.push({
      value: i.toString(10),
      label: i.toString(10),
    });
  }
  const onChangeBsaSelect = (e) => {
    setBsaValue(e);
  };
  const onChangeSubscription = () =>{
    if(access){
      setAccess(false)
      setSubStart("")
      setSubExpire("")
    }else{
      setAccess(true)
    }
  }
  useEffect(() => {
    let sport = form.getFieldValue("sport");
    for (let i = 0; i < sportsList.length; i++) {
      if (sportsList[i].name === sport) {
        setSport(sportsList[i].id);
      }
    }
  }, [sportsList]);
  // console.log(editSubscription)
  const onChangeCurriculum = (e) => {
    setCurriculum(e.target.checked)
  };
  return (
    <>
      {loading && loading == true ? (
        <Spin
          className="loader"
          size="large"
          style={{
            position: "absolute",
            left: "49%",
            top: "39%",
          }}
        />
      ) : (
        <div className="create-group-main">
          <div className="heading-row">
          <FaArrowLeftLong className= "left-arrow" onClick={() => navigate("/home/groups")} />
            <h1>Create New Group</h1>
          </div>
          <Form
            form={form}
            name="dynamic_rule"
            className="group-form"
            onFinish={createGroup}
            initialValues={{
              type: type,
              level: "Level 1: Beginners (age < 10)",
              sport: "Basketball",
              bsa_credit: "none",
              "members-limit": false,
              display_announcements_section: true,
              display_post_section: true,
              allow_members_post: "owners",
              accessible_leaderboards: ["display_workout_leaderboard"],
              access: access
            }}
          >
            <h2>Sports</h2>
            <Form.Item
              {...formItemLayout}
              name="sport"
              label="Sport"
              className="sport-div"
              rules={[
                {
                  required: true,
                  message: "Please select sport",
                },
              ]}
            >
              <Radio.Group className="custom-radio">
                <>
                  {sportsList.map((item) => (
                    <Radio
                      key={item.id}
                      value={item.name}
                      defaultChecked="Basketball"
                      onChange={() => changeSport(item)}
                    >
                      {item.name}
                    </Radio>
                  ))}
                </>
              </Radio.Group>
            </Form.Item>
            <Divider />
            <h2>General</h2>
            <Form.Item {...formItemLayout} name="curriculum_group" label="Make a curriculum group">
            <Checkbox onChange={onChangeCurriculum} className="custom-checkbox" />
            {/* Curriculum Group</Checkbox> */}
            </Form.Item>
                        <Form.Item {...formItemLayout} name="type" label="Type">
              <Radio.Group
                onChange={onChangeType}
                value={type}
                className="custom-radio"
              >
                <Radio value={1}>Team Training Group</Radio>
                <Radio value={2}>Paid Public Training Group</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="avatar"
              label="Display Photo"
              className="img-div"
            >
              <div className="image-previewer">
                {!imgSrc ? (
                  <Upload
                    className="uploader"
                    showUploadList={false}
                    type="file"
                    customRequest={dummyRequest}
                    beforeUpload={handleBeforeUpload}
                  >
                    Upload pic
                  </Upload>
                ) : (
                  <>
                    <img
                      src={imgSrc}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      className="img"
                    />
                    <Upload
                      className="avatar-uploader"
                      showUploadList={false}
                      type="file"
                      customRequest={dummyRequest}
                      beforeUpload={handleBeforeUpload}
                    >
                      <img src={Edit} alt="edit" className="edit"></img>
                    </Upload>
                    <img
                      src={Close}
                      alt="close"
                      className="delete"
                      onClick={removeImage}
                    />
                  </>
                )}
              </div>
              <span className="img-type">Allowed file types: PNG, JPG, JPEG.</span>
              {imageError &&
               <span style={{color:"red"}}> Please upload group image
                </span>}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="name"
              label="Full Name"
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter group name.",
                },
              ]}
            >
              <Input
                placeholder="Please input your name"
                className="custom-input"
              />
            </Form.Item>
            {type === 2 && (
              // <Form.Item
              //   {...formItemLayout}
              //   name="level"
              //   label="Skill Level"
              //   className="skill"
              //   value={level}
              // >
              //   <Select
              //     optionFilterProp="children"
              //     onChange={onChangeSkillLevel}
              //     filterOption={(input, option) =>
              //       (option?.label ?? "")
              //         .toLowerCase()
              //         .includes(input.toLowerCase())
              //     }
              //     size={"large"}
              //     style={{ height: 48 }}
              //     className="select-input"
              //     options={[
              //       {
              //         value: "beginner",
              //         label: "Level 1: Beginners (age < 10)",
              //       },
              //       {
              //         value: "intermediate",
              //         label: "Level 2: Intermediate (age 11-15)",
              //       },
              //       {
              //         value: "advanced",
              //         label: "Level 3: Advance (age 16+)",
              //       },
              //     ]}
              //   />
              // </Form.Item>
              <>

            <Form.Item
            {...formItemLayout}
                      name="expiry"
                      label="Member Access Code Expiry"
                      rules={[
                        // {
                        //   type: "number",
                        //   min: 1,
                        //   max: 10000,
                        //   message: "Month(s) must be between ${min} to ${max}.",
                        // },
                        {
                          required:true,
                          message:"Please enter valid expiry"
                        }
                      ]}
                    >
                      <InputNumber
                        placeholder="Enter expiry in months"
                        // type="number"
                        min={1}
                        max={10000}
                        style={{width:"100%"}}
                        className="number-input"
                        onKeyDown={(e) => e.key === "." && e.preventDefault()}
                      />
                    </Form.Item>     
                    <Form.Item {...formItemLayout} name="description" label="Short Description">
              <TextArea
                placeholder="Short description for group"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
              />
            </Form.Item>    
                        </>
            )}
            {/* <Form.Item
          {...formItemLayout}
          name="start_date"
          label="Starting on"
          rules={[
            {
              required: false,
              message: "Please input your name",
            },
          ]}
        >
          <DatePicker
            className="date-picker"
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{height:"38px"}}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="expiry_Date"
          label="Ending on"
          rules={[
            {
              required: false,
              message: "Please input your name",
            },
          ]}
        >
          <DatePicker
            className="date-picker"
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{height:"38px"}}
          />
        </Form.Item> */}


            <Divider />

            <h2>Subscription</h2>
            <Form.Item
              {...formItemLayout}
              name="access"
              label="Access"
              className="access-div"
            >
              <Radio.Group className="custom-radio" onChange={onChangeSubscription}>
                <Radio value={false}>No Subscription Required</Radio>
                <Radio value={true}>Subscription Required</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Subscription Status"
              className="subscription-div"
            >
              {/* <span className="green-tag">Active</span>
          <span className="manage-subscription">Manage Subscription</span> */}
              {/* <Button className="yellow-tag">None</Button> */}
              {/* <br/> */}
              <div>
                {!access ? 
                                <>
                {/* {subscriptionStatus === "none" ? ( */}
                  <Button className="yellow-tag">None</Button>
                {/* ) : subscriptionStatus === "active" ? (
                  <Button className="green-tag">Active</Button>
                ) : (
                  <Button className="pink-tag">Inactive</Button>
                )} */}
                <br />
                </>
                :
                <div style={{ marginTop:  !access ? "16px" : 0 , display: "block", gap: "8px" }}>
                  <Button
                    className="orange-tag"
                    onClick={() => setNewSubscription(true)}
                    // disabled={subscriptionStatus === "none" ? false : true}
                    disabled={subStart && subExpire && subStart !== "Invalid Date" && subExpire !== "Invalid Date" ? true : false}
                    style={{marginRight:8 , marginBottom:8}}
                  >
                    Create New Subscription
                  </Button>
                  <Button
                    className="orange-tag"
                    onClick={() => setEditSubscription(true)}
                    disabled={
                      subStart && subExpire && subStart !== "Invalid Date" && subExpire !== "Invalid Date" ? false : true
                      // subscriptionStatus === "none" ||
                      // subscriptionStatus === "inactive"
                      //   ? true
                      //   : false
                    }
                    style={{marginRight:8, marginBottom:8}}

                  >
                    Edit Current Subscription
                  </Button>
                  <Button
                    className="orange-tag"
                    // disabled={subscriptionStatus === "none" ? true : false}
                    onClick={() => setSubscriptionHistory(true)}
                    disabled={subStart && subExpire && subStart !== "Invalid Date" && subExpire !== "Invalid Date" ? false : true}
                    style={{marginRight:8 , marginBottom:8}}
                  >
                    Subscription History
                  </Button>
                </div>
      }
              </div>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="bsa_credit"
              label="BSA Credit"
              className="bsa-credit-div"
            >
              <Radio.Group
                onChange={onChangeBsa}
                className="custom-radio"
                style={{ display: "block" }}
              >
                <Radio value={"none"}>No BSA Credit</Radio>
                <div style={{ display: "flex" }}>
                  <Radio
                    value={"one-time"}
                    style={{ padding: "11px 0", width: 309 }}
                  >
                    Add one-time credits
                  </Radio>

                  {/* {bsaCredit === 2 ? ( */}
                  <Select
                    placeholder="Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    // defaultValue={generalDetail.bsa_frequency === "one time" ? bsaValue : null}
                    disabled={bsaCredit !== 2}
                    value={bsaValueoneTime}
                    style={{ marginBottom: "16px", width: 212, height: 38 }}
                    onChange={onChangeBsaSelect}
                    suffixIcon={<img src={DownArrow} />}
                    size={"large"}
                    className="select-input"
                    options={oneTimeOptions}
                  />
                </div>

                {/* ) : null} */}
                <div style={{ display: "flex" }}>
                  <Radio
                    value={"monthly"}
                    style={{ padding: "11px 0", width: 309 }}
                  >
                    Add credit each month (on “selected” start day)
                  </Radio>
                  <Select
                    placeholder="Select"
                    optionFilterProp="children"
                    size={"large"}
                    onChange={onChangeBsaSelect}
                    disabled={bsaCredit !== 3}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ marginBottom: "16px", width: 212, height: 38 }}
                    suffixIcon={<img src={DownArrow} />}
                    value={bsaValueEachMonth}
                    className="select-input"
                    options={monthlyOptions}
                  />
                </div>

                {/* {bsaCredit === 3 ? ( */}

                {/* ) : null} */}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="members-limit"
              label="Members Limit"
              className="members-limit-div"
            >
              <Radio.Group
                onChange={onChangeLimit}
                value={seatLimit}
                className="custom-radio"
                style={{ width: "100%" }}
              >
                <Radio value={false}>No Limit</Radio>
                <div style={{ display: "flex" }}>
                  <Radio value={true} style={{ padding: "11px 0", width: 309 }}>
                    Seat Limit
                  </Radio>
                  <Input
                    placeholder="Enter the seat limit..."
                    className="custom-input"
                    style={{ height: 38, width: 212 }}
                    value={memberLimit}
                    onChange={(e) => setMemberLimit(e.target.value)}
                    disabled={seatLimit !== true}
                  />
                </div>
                {/* {seatLimit === true && (
                  <Input
                    placeholder="Please input your name"
                    className="custom-input"
                    value={memberLimit}
                    onChange={(e) => setMemberLimit(e.target.value)}
                  />
                )} */}
              </Radio.Group>
            </Form.Item>
            <Divider />

            <h2>Announcements</h2>
            <Form.Item
              {...formItemLayout}
              name="display_announcements_section"
              label="Display Announcements Section"
              className="type-div"
            >
              <Radio.Group className="custom-radio">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="display_post_section"
              label="Display Posts Section"
              className="type-div"
            >
              <Radio.Group className="custom-radio">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="allow_members_post"
              label="Who can post to the group?"
              className="type-div"
            >
              <Radio.Group className="custom-radio">
                <Radio value={"owners"}>Only Group Owners</Radio>
                <Radio value={"all"}>All (Group Owners and Members)</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="accessible_leaderboards"
              label="Accessible Leaderboards"
              className="sport-div"
            >
              <Checkbox.Group
                className="custom-checkbox"
                onChange={onchangeLeaderBoard}
              >
                <Checkbox value={"display_workout_leaderboard"}>
                  Workouts/Challenges
                </Checkbox>
                <Checkbox value="display_bsa_leaderboard">BSA</Checkbox>
                {/* <Checkbox value="display_combine_measurement_leaderboard">
                  Combine Measurements
                </Checkbox> */}
              </Checkbox.Group>
            </Form.Item>
            {/* <Form.Item {...formItemLayout} name="tags" label="Tags">
              <Select
                mode="tags"
                placeholder="Add tags..."
                className="select-input tag-select"
                suffixIcon={false}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <Form.Item {...formItemLayout} name="comments" label="Comments">
              <TextArea
                placeholder="e.g. something about the group"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
              />
            </Form.Item> */}
            <Divider />

            <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
              <Button
                htmlType="submit"
                type="primary"
                className="discard-btn"
                onClick={() => form.resetFields()}
              >
                Discard
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  margin: "0 8px",
                }}
                className="dark-base-btn"
              >
                Create
              </Button>
            </Form.Item>
          </Form>
          <SubscriptionHistory
            setSubscriptionHistory={setSubscriptionHistory}
            subscriptionHistory={subscriptionHistory}
          />
        </div>
      )}
    </>
  );
};

const CreateGroup = () => {
  return (
    <CreateGroupContextProvider>
      <CreateGroupPage />
    </CreateGroupContextProvider>
  );
};
export default CreateGroup;
