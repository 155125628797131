import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Tabs,
  Input,
  Button,
  Tooltip,
  Select,
  DatePicker,
  Form,
  Dropdown,
} from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import "./admin-users.scss";
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
import Calendar from "../../../assets/images/calendar.svg";
import { useNavigate } from "react-router-dom";
import { getDateWithoutDay } from "../../../util/config";
import {
  AdminUserContext,
  AdminUserContextProvider,
} from "../../../context/setting-context/admin_user_context";
import AddUserModal from "./add-user-modal";
import EditUserModal from "./edit-user";
const { RangePicker } = DatePicker;
const AdminUserPage = () => {
  const [form] = Form.useForm();

  const {
    loading,
    pageSize,
    pageNumber,
    setPageNumber,
    total,
    setStatus,
    setSport,
    sort,
    setSort,
    setStartDate,
    setEndDate,
    addUserModal,
    setAddUserModal,
    usersList,
    setSearch,
    setUsersList,
    roleList,
    setRole,
    editUserModal,
    setEditUserModal,
    setSelectedUser,
    getSingleUser,
    deleteUser,
  } = useContext(AdminUserContext);
  const navigate = useNavigate();
  const dateFormat = "ddd. MMMM D, YYYY";
  const onChange = (key) => {
    if (key === "edit") {
    }
  };
  const items = [
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        return <div>{record.first_name + " " + record.last_name}</div>;
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "role",
      dataIndex: "role",
      key: "role",
    },
    // {
    //   title: "Created by",
    //   dataIndex: "created_by_name",
    //   key: "created_by_name",
    // },
    {
      title: "Created on",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record, index) => {
        return <div>{getDateWithoutDay(record.created_at)}</div>;
      },
    },
    {
      title: "status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record.is_active === true ? (
              <span className="green-tag">Active</span>
            ) : (
              <span className="red-tag">Not Active</span>
            )}
          </div>
        );
      },
    },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      render: (text, record, key) => (
        // <Select
        //   index={key}
        //   placeholder="Actions"
        //   optionFilterProp="children"
        //   onChange={onChange}
        //   suffixIcon={<img src={DownArrow} style={{ marginTop: 6 }} />}
        //   className="custom-action-select"
        //   popupClassName="custom-action-select-dropdown"
        //   options={[
        //   //   {
        //   //     value: "sponsor",
        //   //     label: "Sponsor Report",
        //   //   },
        //     {
        //       value: "edit",
        //       label: "Edit",
        //     },
        //     {
        //       value: "delete",
        //       label: "Delete",
        //     },
        //   ]}
        // />
        <Dropdown
          placeholder="Actions"
          className="custom-dropdown"
          menu={{
            items,

            onClick: ({ key }) => {
              if (key === "edit") {
                setEditUserModal(true);
                getSingleUser(record.id);
              } else {
                deleteUser(record.id);
              }
            },
          }}
          overlayClassName="workout-dropdown-menu"
        >
          <a onClick={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      id: "232123",
      name: "Kenny Thompson",
      email: "john.doe@gmail.com",
      role: "San Antonio FC",
      created_by: "Super Admin",
      created_on: "Jun 23, 2023",
      username: "@kenthompson",
      fullname: "Kenny Thompson",
      dob: "Aug 9, 2000",
      gender: "Male",
      sport: "Basketball",
      rotator: "3",
    });
  }
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const gotoGroupDetail = (record) => {
    navigate({
      pathname: `./${record.name}`,
      search: `?id=${record.id}`,
    });
  };
  const searchUser = (e) => {
    if (e.target.value.length >= 3) {
      // setPageNumber(1);
      setUsersList([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setUsersList((usersList) => []);
      setSearch("");
    }
  };
  const onChangeSort = (value) => {
    if (value === "newest") {
      setSort(true);
    } else {
      setSort(false);
    }
  };
  const onChangeRole = (value) => {
    setRole(value);
  };
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  const onChangeRangePicker = (e) => {
    if (e) {
      setStartDate(e[0].format("YYYY-MM-DD"));
      setEndDate(e[1].format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  let roleOptions = [];
  for (let i = 0; i < roleList.length; i++) {
    roleOptions.push({
      value: roleList[i].id,
      label: roleList[i].role_name,
    });
  }
  return (
    <div className="admin-user-main">
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchUser}
          />
           <div className="right-div">
          {/*  <Select
              placeholder="Newest"
              optionFilterProp="children"
              onChange={onChangeSort}
              className="custom-action-select"
              popupClassName="custom-action-select-dropdown"
              suffixIcon={<img src={DownArrow} />}
              style={{ height: 40, width: 135 }}
              options={[
                {
                  value: "newest",
                  label: "Newest",
                },
                {
                  value: "oldest",
                  label: "Oldest",
                },
              ]}
            />
            <Select
              placeholder="Role"
              optionFilterProp="children"
              onChange={onChangeRole}
              className="custom-action-select"
              popupClassName="custom-action-select-dropdown"
              suffixIcon={<img src={DownArrow} />}
              style={{ height: 40, width: 135 }}
              options={roleOptions}
            />
            */}
            {/* <Select
              placeholder="Status"
              optionFilterProp="children"
              onChange={onChangeStatus}
              className="custom-action-select"
              popupClassName="custom-action-select-dropdown"
              suffixIcon={<img src={DownArrow} />}
              style={{ height: 40, width: 135 }}
              options={[
                {
                  value: "active",
                  label: "Active",
                },
                {
                  value: "inactive",
                  label: "Inactive",
                },
                {
                  value: "scheduled",
                  label: "Scheduled",
                },
              ]}
            />
            <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              onChange={onChangeRangePicker}
            /> */}
            <Button
              icon={<img src={Plus} />}
              className="dark-base-btn"
              style={{ marginLeft: "auto" }}
              onClick={() => setAddUserModal(true)}
            >
              Add New
            </Button>
          </div> 
        </div>
        <Table
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => gotoGroupDetail(record),
          //   };
          // }}
          loading={loading}
          // onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: total,
          }}
          columns={columns}
          dataSource={usersList}
          className="table-container"
        />
      </div>
      <AddUserModal
        addUserModal={addUserModal}
        setAddUserModal={setAddUserModal}
      />
      <EditUserModal
        editUserModal={editUserModal}
        setEditUserModal={setEditUserModal}
      />
    </div>
  );
};

const AdminUser = () => {
  return (
    <AdminUserContextProvider>
      <AdminUserPage />
    </AdminUserContextProvider>
  );
};
export default AdminUser;
