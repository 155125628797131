import React, { useContext, useState } from "react";
import { Table, Input, Button, Dropdown } from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import "./sports.scss";
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import {
  SportsContextProvider,
  SportContext,
} from "../../../context/setting-context/sports_context";
import AddSportModal from "./add-new-sport";
import EditSport from "./edit-sport";
const SportsPage = () => {
  const {
    loading,
    pageSize,
    pageNumber,
    total,
    addSportModal,
    setAddSportModal,
    sportsList,
    deleteSport,
    setEditSportModal,
    editSportModal,
    setSelectedSport,
  } = useContext(SportContext);
  const items = [
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [
    {
      title: "logo",
      dataIndex: "logo",
      key: "logo",
      render: (text, record, index) => {
        return record.icon ? (
          <img src={record.icon} className="logo-avatar" />
        ) : (
          <Avatar
            name={record.name}
            style={{ marginRight: 10 }}
            color="#F15A29"
            className="logo-avatar"
            maxInitials={1}
          />
        );
      },
    },

    {
      title: "sport",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "color",
      dataIndex: "color",
      key: "color",
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record.color ? (
              <span
                className="color-circle"
                style={{ background: record.color }}
              ></span>
            ) : (
              "N/A"
            )}
          </div>
        );
      },
    },
    {
      title: "description",
      dataIndex: "description",
      key: "description",
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record.description && record.description !== "undefined"
              ? record.description
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      render: (text, record, key) => (
        <Dropdown
          placeholder="Actions"
          className="custom-dropdown"
          menu={{
            items,

            onClick: ({ key }) => {
              if (key === "delete") {
                deleteSport(record);
              } else {
                setSelectedSport(record);
                setEditSportModal(true);
              }
            },
          }}
          overlayClassName="workout-dropdown-menu"
        >
          <a onClick={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <div className="sports-main">
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
          />
          <div className="right-div">
            <Button
              icon={<img src={Plus} />}
              className="dark-base-btn"
              style={{ marginLeft: "auto" }}
              onClick={() => setAddSportModal(true)}
            >
              Add New
            </Button>
          </div>
        </div>
        <Table
          loading={loading}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: total,
          }}
          columns={columns}
          dataSource={sportsList}
          className="table-container"
        />
      </div>
      <EditSport
        setEditSportModal={setEditSportModal}
        editSportModal={editSportModal}
      />
      <AddSportModal
        addSportModal={addSportModal}
        setAddSportModal={setAddSportModal}
      />
    </div>
  );
};

const Sports = () => {
  return (
    <SportsContextProvider>
      <SportsPage />
    </SportsContextProvider>
  );
};
export default Sports;
