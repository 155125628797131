import React, { useContext, useEffect, useState } from "react";
import { Input, Row, Col, Modal, Form, Switch, Divider, Button } from "antd";
import { RolesPermissionsContext } from "../../../context/setting-context/roles_permissions_context";
const { TextArea } = Input;

const AddNewRole = ({ addRoleModal, setAddRoleModal }) => {
  const {
    permissionList,
    permissions,
    setPermissions,
    createNewRole,
    form,
    key,
    setKey,
  } = useContext(RolesPermissionsContext);

  const handleCancel = () => {
    setAddRoleModal(false);
    form.resetFields();
    setPermissions((permissions) => []);
    setKey((k) => k + 1);
  };

  const onChange = (e, module, permission) => {
    const updateOldArray = permissions.map((item, index) => {
      if (
        item.permission_id === permission.id &&
        module.id === item.module_id
      ) {
        return {
          ...item,
          value: e,
        };
      } else {
        return item;
      }
    });
    setPermissions(updateOldArray);
  };
  useEffect(() => {
    if (permissionList.length > 0) {
      const permissionListArray = [];
      for (let i = 0; i < permissionList.length; i++) {
        for (let j = 0; j < permissionList[i].permissions.length; j++) {
          permissionListArray.push({
            permission_id: permissionList[i].permissions[j].id,
            value: false,
            module_id: permissionList[i].module.id,
          });
        }
        setPermissions(permissionListArray);
      }
    }
  }, [permissionList]);
  return (
    <Modal
      wrapClassName="add-role-modal"
      className="add-role-modal"
      open={addRoleModal}
      onCancel={handleCancel}
      okText={"Create"}
      cancelText={"Cancel"}
      width={"624px"}
      footer={false}
    >
      <div className="add-role-main">
        <h1>Add New Role</h1>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="add-role-form"
          onFinish={createNewRole}
        >
          <Form.Item
            name="role_name"
            label="Role Name"
            rules={[
              {
                required: true,
                message: "Please enter role",
              },
            ]}
          >
            <Input placeholder="Please enter role name" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea rows={4} placeholder="Please enter description" />
          </Form.Item>

          <div className="permissions">
            <h1>Permissions</h1>
            {permissionList.map((item, index) => {
              return (
                <>
                  <h2>{item.module.name}</h2>
                  {item.permissions.map((permission, index) => (
                    <Row key={index}>
                      <Col span={12} className="label-col">
                        <label htmlFor="">{permission.title}</label>
                      </Col>
                      <Col span={12}>
                        {" "}
                        <Switch
                          onChange={(e) => onChange(e, item.module, permission)}
                          className="custom-switch"
                          id={permission.id}
                          key={key}
                        />
                      </Col>
                      <Divider dashed />
                    </Row>
                  ))}
                </>
              );
            })}
          </div>
          <Form.Item style={{ textAlign: "end" }}>
            <Button
              type="default"
              className="discard-btn"
              onClick={() => {
                setAddRoleModal(false);
                form.resetFields();
              }}
              style={{
                margin: "0 8px",
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className="dark-base-btn">
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddNewRole;
