import React, { useState } from "react";
import {
  formPostDataTraining,
  getDataTraining,
  jsonPostDataTraining,
  patchDataTraining,
} from "../../util/api-calls";
import { Form } from "antd";
export const EditTaskContext = React.createContext({});
export const EditTaskContextProvider = ({ children = {} }) => {
  const [form] = Form.useForm();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  let groupName = window.location.pathname.slice(
    window.location.pathname.lastIndexOf("/") + 1,
    window.location.pathname.length
  );
  const [detailVideoId, setDetailVideoId] = useState("");
  const [mediaType, setMediaType] = useState("upload");
  const [title, setTitle] = useState("");
  const [privacyModal, setPrivacyModal] = useState(false);
  const [updateTask, setUpdateTask] = useState(false);
  const [embeddedUrl, setEmbeddedUrl] = useState("");
  const [data, setData] = useState();
  const [timeLimitSeconds, setTimeLimitSeconds] = useState("");
  // const [existingTaskModal, setExistingTaskModal] = useState(false);
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [detailImgSrc, setDetailImgSrc] = useState();
  const [detailImageUrl, setDetailImageUrl] = useState();
  const [allTags, setAllTags] = useState([]);
  const [existingTasks, setExistingTasks] = useState([]);
  const [skillLevel, setSkillLevel] = useState("");
  const [uploadError, setUploadError] = useState(false);
  const [taskType, setTaskType] = useState(true);
  const [selectedTask, setSelectedTask] = useState({});
  const [tags, setTags] = useState([]);
  const [sort, setSort] = useState(true);
  const [value, setValue] = useState("");
  const [timeLimit, setTimeLimit] = useState(false);
  const [taskStatus, setTaskStatus] = useState(true);
  const [level, setLevel] = useState("beginner");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [videoRequired, setVideoRequired] = useState(true);

  const postUpdateTask = (
    selectedTask,
    setNewTaskArray,
    newTaskArray,
    setEditTaskModal
  ) => {
    let formData = new FormData();
    formData.append("title", title !== "" ? title : selectedTask.title);
    formData.append(
      "order",
      newTaskArray === undefined ? selectedTask.order : newTaskArray.length + 1
    );
    if(mediaType !== "embedded")
    formData.append(
      "media_uri",
      detailVideoId !== "" ? detailVideoId : selectedTask.media_uri
    );
    if (embeddedUrl) {
      formData.append("media_url", embeddedUrl);
    }
    formData.append(
      "media_type",
      detailVideoId
        ? "vimeo"
        : embeddedUrl !== ""
        ? "embedded"
        : detailImageUrl
        ? "image"
        : selectedTask.media_type
    );
    formData.append("media_required", videoRequired);
    formData.append(
      "instruction",
      value !== "" ? value : selectedTask.instruction
    );
    formData.append("is_public", taskStatus);
    if (detailImageUrl) {
      formData.append("file", detailImageUrl);
    }
    formData.append("best_score", selectedTask.best_score);
    formData.append("skill_level", level);
    formData.append("is_time_limit", timeLimit);
    formData.append("time_limit_seconds", selectedTask.time_limit_seconds);
    formData.append("score_based_test", taskType);
    formData.append("num_completion", selectedTask.num_completion);
    formData.append("legacy_point", selectedTask.legacy_point);
    formData.append("allow_more_reps", selectedTask.allow_more_reps);
    formData.append(
      "tags",
      tags.toString() === ""
        ? selectedTask.tags.toLowerCase()
        : tags.toString().toLowerCase()
    );
    formData.append("lock", selectedTask.lock);
    // formData.append("group_id", selectedTask.group_id);
    formData.append("group_name", selectedTask.group_name);
    patchDataTraining(`admin/task/library/${selectedTask.id}`, formData)
      .then((res) => {
        // setNewTaskArray((prevTask) => [...prevTask, res.data]);
        setEditTaskModal(false);
        form.resetFields();
        setEmbeddedUrl("")
        setSelectedTask({});
        setTitle("");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllTags = () => {
    getDataTraining(`sport/tags`)
      .then((res) => {
        setAllTags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const defaultContext = {
    detailVideoId,
    setDetailVideoId,
    setTags,
    mediaType,
    setMediaType,
    timeLimitSeconds,
    setTimeLimitSeconds,
    tags,
    value,
    setValue,
    timeLimit,
    setTimeLimit,
    taskStatus,
    setTaskStatus,
    level,
    setLevel,
    setEmbeddedUrl,
    embeddedUrl,
    newTaskModal,
    setNewTaskModal,
    // existingTaskModal,
    // setExistingTaskModal,
    form,
    detailImgSrc,
    setDetailImgSrc,
    detailImageUrl,
    setDetailImageUrl,
    setTitle,
    data,
    setData,
    uploadError,
    getAllTags,
    allTags,
    existingTasks,
    setSkillLevel,
    setTaskType,
    postUpdateTask,
    setSort,
    setSelectedCategory,
    params,
    setPrivacyModal,
    privacyModal,
    updateTask,
    setUpdateTask,
    selectedTask,
    setSelectedTask,
    videoRequired,
    setVideoRequired,
  };
  return (
    <EditTaskContext.Provider value={defaultContext}>
      {children}
    </EditTaskContext.Provider>
  );
};
