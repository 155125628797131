import React, { useContext, useEffect } from "react";
import { Input, Row, Col, Modal, Form, Switch, Divider, Button } from "antd";
import { RolesPermissionsContext } from "../../../context/setting-context/roles_permissions_context";
const { TextArea } = Input;
const EditRole = () => {
  const {
    permissionList,
    updatedPermissions,
    setUpdatedPermissions,
    form,
    key,
    setKey,
    editRoleModal,
    setEditRoleModal,
    selectedRole,
    updateRole,
  } = useContext(RolesPermissionsContext);
  const handleCancel = () => {
    setEditRoleModal(false);
    form.resetFields();
    setUpdatedPermissions((updatedPermissions) => []);
    setKey((k) => k + 1);
  };

  useEffect(() => {
    form.setFieldsValue({
      role_name: selectedRole.role_name,
      description: selectedRole.description,
    });
    setUpdatedPermissions(
      selectedRole.role_permission.map((item) => {
        return {
          ...updatedPermissions,
          permission_id: item.permission.id,
          value: item.value,
          module_id: item.module.id,
        };
      })
    );
  }, [selectedRole]);
  const onChange = (e, module, permission) => {
    const updateOldArray = updatedPermissions.map((item, index) => {
      if (
        item.permission_id === permission.id &&
        module.id === item.module_id
      ) {
        return {
          ...item,
          value: e,
        };
      } else {
        return item;
      }
    });
    setUpdatedPermissions(updateOldArray);
  };
  return (
    <Modal
      wrapClassName="add-role-modal"
      className="add-role-modal"
      open={editRoleModal}
      onCancel={handleCancel}
      okText={"Create"}
      cancelText={"Cancel"}
      width={"624px"}
      footer={false}
    >
      <div className="add-role-main">
        <h1>Edit Role</h1>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="add-role-form"
          onFinish={updateRole}
        >
          <Form.Item
            name="role_name"
            label="Role Name"
            rules={[
              {
                required: true,
                message: "Please enter role",
              },
            ]}
          >
            <Input placeholder="Please enter role name" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea rows={4} placeholder="Please enter description" />
          </Form.Item>
          <div className="permissions">
            <h1>Permissions</h1>
            {permissionList.map((item, index) => {
              return (
                <>
                  <h2>{item.module.name}</h2>
                  {selectedRole.role_permission.map((items, index) => {
                    return (
                      item.module.id === items.module.id && (
                        <Row key={index}>
                          <Col span={12} className="label-col">
                            <label htmlFor="">{items.permission.title}</label>
                          </Col>
                          <Col span={12}>
                            {" "}
                            <Switch
                              onChange={(e) =>
                                onChange(e, items.module, items.permission)
                              }
                              className="custom-switch"
                              id={items.permission.id}
                              key={key}
                              defaultChecked={items.value}
                            />
                          </Col>
                          <Divider dashed />
                        </Row>
                      )
                    );
                  })}
                </>
              );
            })}
          </div>
          <Form.Item style={{ textAlign: "end" }}>
            <Button
              type="default"
              className="discard-btn"
              onClick={handleCancel}
              style={{
                margin: "0 8px",
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className="dark-base-btn">
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditRole;
