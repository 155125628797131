import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, DatePicker, Form, InputNumber } from "antd";
import dayjs from "dayjs";
import "./combine-measurements-modal.scss";
import Calendar from "../../../../assets/images/calendar.svg";
import { CombineMeasurementContext } from "../../../../context/user-context/combine-measurement-context";
const CombineMeasurementModal = ({ setIsModalOpen, isModalOpen }) => {
  const { submitCombineMeasurements } = useContext(CombineMeasurementContext);
  const dateFormat = "MMMM D, YYYY";
  const [form] = Form.useForm();
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  useEffect(() => {
    if (isModalOpen === false) {
      form.resetFields();
    }
  }, [isModalOpen]);
  const handleCancel = () =>{
    setIsModalOpen(false)
  }
  return (
    <Modal
      title="Basic Modal"
      className="combine-measurement-modal"
      width={624}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={false}
    >
      <div className="header">
        <h1>Combine Measurements</h1>
        <span>Update combine measurements</span>
      </div>
      <div className="form">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={submitCombineMeasurements}
        >
          <Form.Item
            name="date"
            label="Select Date"
            rules={[
              {
                required: true,
                message: "Please Select date.",
              },
            ]}
          >
            <DatePicker
              className="date-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              style={{ height: 38 }}
              disabledDate={disabledDate}
            />
          </Form.Item>
          <h4>Measurements</h4>

          <Form.Item
            label="Height (w/o) Shoes"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="height_feet"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 8,
                  pattern: new RegExp("^[0-9]*$"),
                  message: "Feet must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"ft"}
                placeholder="Feet"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="height_inch"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 11,
                  message: "Inches must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"in"}
                placeholder="Inch"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="height_dec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 75,
                  message: "Decimal must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Weight"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="weight_lbs"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 999,
                  message: "Weight must be between ${min} to ${max}.",
                },
              ]}
              style={{ width: "100%" }}
            >
              <InputNumber
                addonBefore={"lbs"}
                placeholder="Lbs"
                className="weight-input"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Wingspan"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="wingspan_feet"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 8,
                  message: "Feet must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"ft"}
                placeholder="Feet"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="wingspan_inch"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 11,
                  message: "Inches must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"in"}
                placeholder="Inch"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="wingspan_dec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 75,
                  message: "Decimal must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Max. Vertical"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="maxVertical_inch"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 60,
                  message: "Inches must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"in"}
                placeholder="Inch"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="maxVertical_dec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 75,
                  message: "Decimal must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Standing Vertical"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="standingVertical_inch"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 60,
                  message: "Inches must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"in"}
                placeholder="Inch"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="standingVertical_dec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 75,
                  message: "Decimal must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="3/4 Court Sprint"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="court_sec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 99,
                  message: "Seconds must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"sec"}
                placeholder="Sec"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="court_dec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 99,
                  message: "Decimal must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Lane Agility"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="lane_sec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 99,
                  message: "Seconds must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"sec"}
                placeholder="Sec"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="lane_dec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 99,
                  message: "Decimal must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            style={{ display: "flex", justifyContent: "end", marginTop: 30 }}
          >
            <Button type="default" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default CombineMeasurementModal;
