import "./workouts.scss";
import { Col, Row, Progress, Dropdown, Select, Spin } from "antd";
import DownArrow1 from "../../../assets/images/down-bg.svg";
import DownArrow from "../../../assets/images/arrows.svg";
import { useContext, useEffect } from "react";
// import AllGroupWorkouts from "./all-group-workouts/all-group-workouts";
// import Hello from "../all-group-workout/all-group-workout";
import AllGroupWorkout from "../all-group-workout/all-group-workout";
// import AllGroupWorkouts from "./all-group-workouts/all-group-workouts";
import { UserDetailContext } from "../../../context/user-module-context/user-detail-context";
import { getDate } from "../../../util/config";
// import AllWorkoutsModal from "../../Groups/workouts/view-all-workouts-modal/view-all-workouts-modal";
let clickedWorkouts = "";

const Workouts = ({
  setWorkoutDetail,
  setExistingWorkout,
  setNewWorkout,
  setSelectedWorkout,
  setGroupOwner,
  groupOwner,
  workoutDetail
}) => {
  const {
    profileGroups,
    selectedGroup,
    setSelectedGroup,
    groupWorkouts,
    setShowAllWorkouts,
    setWorkoutType,
    showAllWorkouts,
    loading,
    getProfileWorkouts
  } = useContext(UserDetailContext);
  const style = {
    padding: "5px 0",
  };
  useEffect(()=>{
    if(selectedGroup !== ""){
      getProfileWorkouts(groupOwner)
    }
  },[selectedGroup])
  let url = window.location.href;
  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setExistingWorkout(true)}
        >
          Add Existing Workout
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setNewWorkout(true)}
        >
          Create New Workout{" "}
        </a>
      ),
    },
  ];
  let groupOptions = [];
  for (let i = 0; i < profileGroups.length; i++) {
    groupOptions.push({
      value: profileGroups[i].id,
      label: profileGroups[i].name,
    });
  }
  useEffect(() => {
    if (groupOptions?.length > 0 && selectedGroup === "") {
      setGroupOwner(profileGroups[0].is_owner)
      setSelectedGroup(groupOptions[0].value);
    }else{
      let id = selectedGroup
      setSelectedGroup(id)
    }
  }, []);
  const onChange = (e) => {
  let specificGroup  = profileGroups.find(x => x.id === e)
    setGroupOwner(specificGroup.is_owner)
    setSelectedGroup(e);
  };
  let activeWorkouts = [],
    archiveWorkouts = [],
    upcomingWorkouts = [];
  groupWorkouts?.length > 0 &&
    groupWorkouts?.map(function (item) {
      if (item.category === "active") {
        activeWorkouts.push(item);
      } else if (item.category === "archive") {
        archiveWorkouts.push(item);
      } else {
        upcomingWorkouts.push(item);
      }
    });
    let index = groupOptions.findIndex(x => x.value ===selectedGroup);
  return (
    <div className="workouts-main">
      <div style={{ display: "flex" }}>
        <h1>Workouts</h1>
        {!url.includes("users") && (
          <Dropdown
            className="dark-base-btn"
            menu={{ items }}
            overlayClassName="workout-dropdown-menu"
          >
            <a onClick={(e) => e.preventDefault()}>
              Add Workout
              <img src={DownArrow1} />
            </a>
          </Dropdown>
        )}
        <div style={{marginLeft:"auto"}}>
          <label style={{fontWeight:500 , marginRight:20,fontSize:16}}>Groups:</label>
        <Select
          placeholder="Select Group"
          optionFilterProp="children"
          onChange={onChange}
          suffixIcon={<img src={DownArrow} />}
          className="select-input"
          options={groupOptions}
          // defaultActiveFirstOption
          defaultValue={
            selectedGroup === "" && groupOptions?.length > 0 ? groupOptions[0].label : selectedGroup !== "" && groupOptions?.length > 0  ? 
            groupOptions[index].label
             :  "Select Group"
          }
        />
        </div>
      </div>
      <hr />
      {loading === true ? (
        <Spin
          className="loader"
          size="large"
          style={{
            position: "absolute",
            left: "49%",
            marginTop: "5%",
          }}
        />
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <h2>Active Workouts</h2>
            {activeWorkouts && activeWorkouts.length >= 3 && (
              <h5
                onClick={() => {
                  clickedWorkouts = "active";
                  setShowAllWorkouts(true);
                  setWorkoutType("active");
                }}
              >
                View All Workouts
              </h5>
            )}
          </div>
          <Row gutter={16} className="challenges-row">
            {activeWorkouts && activeWorkouts.length > 0 ? (
              activeWorkouts.slice(0, 3).map(function (item, index) {
                return (
                  <Col
                    className="gutter-row"
                    span={8}
                    style={{ padding: "0 8px 16px" }}
                    key={index}
                  >
                    <div
                      style={{ padding: "5px 0", cursor: "pointer" }}
                      className="div-1"
                      onClick={() => {
                        setWorkoutDetail(true);
                        setSelectedWorkout(item);
                      }}
                    >
                      <h2>{item.title}</h2>
                      <span>
                        Ends: {getDate(item.end_date)}
                      </span>
                      <div style={{ display: "flex", marginTop: "40px" }}>
                        <div className="percentage">
                          {item.completion_percentage}%
                        </div>
                        {/* <div className="tasks">{item.total_tasks} tasks</div> */}
                      </div>
                      <Progress
                        percent={item.completion_percentage}
                        showInfo={false}
                        className="purple-progress-bar"
                      />
                    </div>
                  </Col>
                );
              })
            ) : (
              <span className="no-result">There are no active workouts</span>
            )}
          </Row>
          <div style={{ display: "flex" }}>
            <h2>Upcoming Workouts</h2>
            {upcomingWorkouts && upcomingWorkouts.length >= 3 && (
              <h5
                onClick={() => {
                  clickedWorkouts = "upcoming";
                  setShowAllWorkouts(true);
                  setWorkoutType("upcoming");
                }}
              >
                View All Workouts
              </h5>
            )}
          </div>
          <Row className="challenges-row">
            {upcomingWorkouts && upcomingWorkouts.length > 0 ? (
              upcomingWorkouts.slice(0, 3).map(function (item, index) {
                return (
                  <Col
                    className="gutter-row"
                    span={8}
                    style={{ padding: "0 8px 16px" }}
                    key={index}
                  >
                    <div
                      style={{ padding: "5px 0", cursor: "default" }}
                      className="div-1"
                    >
                      <h2>{item.title}</h2>
                      <span>
                        Ends: {getDate(item.end_date)}
                      </span>
                      <div style={{ display: "flex", marginTop: "40px" }}>
                        <div className="percentage">
                          {item.completion_percentage}%
                        </div>
                        {/* <div className="tasks">{item.total_tasks} tasks</div> */}
                      </div>
                      <Progress
                        percent={item.completion_percentage}
                        showInfo={false}
                        className="purple-progress-bar"
                      />
                    </div>
                  </Col>
                );
              })
            ) : (
              <span className="no-result">There are no upcoming workouts</span>
            )}
          </Row>
          <div style={{ display: "flex" }}>
            <h2>Archived Workouts</h2>
            {archiveWorkouts && archiveWorkouts.length >= 3 && (
              <h5
                onClick={() => {
                  clickedWorkouts = "archive";
                  setShowAllWorkouts(true);
                  setWorkoutType("archive");
                }}
              >
                View All Workouts
              </h5>
            )}
          </div>
          <Row className="challenges-row">
            {archiveWorkouts && archiveWorkouts.length > 0 ? (
              archiveWorkouts.slice(0, 3).map(function (item, index) {
                return (
                  <Col
                    className="gutter-row"
                    span={8}
                    style={{ padding: "0 8px 16px" }}
                    key={index}
                  >
                    <div
                      style={{ padding: "5px 0", cursor: "pointer" }}
                      className="div-1"
                      onClick={() => {
                        setWorkoutDetail(true);
                        setSelectedWorkout(item);
                      }}
                    >
                      <h2>{item.title}</h2>
                      <span>
                        Ends: {getDate(item.end_date)}
                      </span>
                      <div style={{ display: "flex", marginTop: "40px" }}>
                        <div className="percentage">
                          {item.completion_percentage}%
                        </div>
                        {/* <div className="tasks">{item.total_tasks} tasks</div> */}
                      </div>
                      <Progress
                        percent={item.completion_percentage}
                        showInfo={false}
                        className="purple-progress-bar"
                      />
                    </div>
                  </Col>
                );
              })
            ) : (
              <span className="no-result">There are no archived workouts</span>
            )}
          </Row>
        </>
      )}
      <AllGroupWorkout
        showAllWorkouts={showAllWorkouts}
        setShowAllWorkouts={setShowAllWorkouts}
        setWorkoutDetail={setWorkoutDetail}
        setSelectedWorkout={setSelectedWorkout}
        // workoutData={workoutData}
        allWorkouts={
          clickedWorkouts === "active"
            ? activeWorkouts
            : clickedWorkouts === "archive"
            ? archiveWorkouts
            : upcomingWorkouts
        }
      />
    </div>
  );
};
export default Workouts;
