import React, { useState } from "react";
import { Modal, Input, Radio, Form, Upload, message } from "antd";
import "./sponsor-modal.scss"
import Loader from "../../../assets/images/loading.svg";
import File from "../../../assets/images/files.svg"
import Support  from "../../../assets/images/support.svg"
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const SponsorModal = ({ sponsorModal, setSponsorModal }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);

  const handleOk = () => {
    setSponsorModal(false);
  };
  const handleCancel = () => {
    setSponsorModal(false);
  };
  const onSearch = (value) => console.log(value);
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
  };
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <Modal
      className="sponsor-modal"
      open={sponsorModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Download"}
      cancelText={"Cancel"}
      width={"699px"}
    //   footer={false}
    >
      <h1>Export Sponsor Report</h1>
      <p>Sponsor’s Analytics ready to be downloaded</p>
      <div className="loading-div">
      <img src={Loader} className="loader"/>
      <p className="status">Fetching...</p>
      </div>
      {/* <div className="file-div">
      <img src={File} className="loader"/>
      <p className="status" style={{marginTop:16}}>Sponsor’s Details.pdf</p>
      </div>
      <div className="error-div">
      <img src={Support} className="loader"/>
      <p className="status" style={{marginTop:40}}>Failed to fetch. <span>Retry</span></p>
      </div> */}
    </Modal>
  );
};

export default SponsorModal;
