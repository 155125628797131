// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submissions-main .ant-tabs-nav {
  padding: 0 100px;
  background: #fff;
  height: 53px;
}
.submissions-main .ant-tabs-tab-btn {
  color: #3F4254;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.submissions-main .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3F4254 !important;
}
.submissions-main .ant-tabs-ink-bar {
  background: #F15A29;
}`, "",{"version":3,"sources":["webpack://./src/admin-module/Submissions/index.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,gBAAA;EACA,YAAA;AAAR;AAEI;EACI,cAAA;EACJ,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGQ;EACA,yBAAA;AADR;AAKI;EACI,mBAAA;AAHR","sourcesContent":[".submissions-main{\n    .ant-tabs-nav{\n        padding: 0 100px;\n        background: #fff;\n        height: 53px;\n    }\n    .ant-tabs-tab-btn{\n        color:  #3F4254;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 16px;\n    }\n    .ant-tabs-tab-active{\n        .ant-tabs-tab-btn{\n        color:  #3F4254 !important;\n        }\n\n    }\n    .ant-tabs-ink-bar{\n        background: #F15A29;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
