import "./workouts-home.scss";
import { Col, Row, Progress, Dropdown, Spin } from "antd";
import DownArrow from "../../../assets/images/down-bg.svg";
import {
  WorkoutsContext,
  WorkoutsContextProvider,
} from "../../../context/workouts-context/workouts-context";
import { useContext, useEffect, useState } from "react";
import AllWorkoutsModal from "../workouts/view-all-workouts-modal/view-all-workouts-modal";
import { getDateWithTime, getDate } from "../../../util/config";
let clickedWorkouts = "";
const WorkoutsHome = ({
  setWorkoutDetail,
  setExistingWorkout,
  setNewWorkout,
  workoutData,
  setSelectedWorkout,
}) => {
  const {
    allWorkouts,
    showAllWorkouts,
    setShowAllWorkouts,
    setWorkoutType,
    loading,
  } = useContext(WorkoutsContext);
  let activeWorkouts = [],
    archiveWorkouts = [],
    upcomingWorkouts = [];
  allWorkouts.results?.length > 0 &&
    allWorkouts.results?.map(function (item) {
      if (item.category === "active") {
        activeWorkouts.push(item);
      } else if (item.category === "archive") {
        archiveWorkouts.push(item);
      } else {
        upcomingWorkouts.push(item);
      }
    });
  const style = {
    padding: "5px 0",
  };
  let url = window.location.href;
  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setExistingWorkout(true)}
        >
          Add Existing Workout
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setNewWorkout(true)}
        >
          Create New Workout{" "}
        </a>
      ),
    },
  ];
  return (
    <div className="workouts-main">
      <div style={{ display: "flex" }}>
        <h1>Workouts</h1>
        {!url.includes("users") && (
          <Dropdown
            className="dark-base-btn"
            menu={{ items }}
            overlayClassName="workout-dropdown-menu"
          >
            <a onClick={(e) => e.preventDefault()}>
              Add Workout
              <img src={DownArrow} />
            </a>
          </Dropdown>
        )}
      </div>
      <hr />
      <div style={{ position: "relative" }}>
        {loading === true ? (
          <Spin
            className="loader"
            size="large"
            style={{
              position: "absolute",
              left: "49%",
              marginTop: "5%",
            }}
          />
        ) : (
          <>
            <div style={{ display: "flex" }}>
              <h2>Active Workouts</h2>
              {activeWorkouts && activeWorkouts.length >= 3 && (
                <h5
                  onClick={() => {
                    clickedWorkouts = "active";
                    setShowAllWorkouts(true);
                    setWorkoutType("active");
                  }}
                >
                  View All Workouts
                </h5>
              )}
            </div>
            <Row gutter={16} className="challenges-row">
              {activeWorkouts && activeWorkouts.length > 0 ? (
                activeWorkouts.slice(0, 3).map(function (item, index) {
                  return (
                    <Col
                      className="gutter-row"
                      span={8}
                      key={index}
                      onClick={() => {
                        setWorkoutDetail(true);
                        setSelectedWorkout(item);
                      }}
                    >
                      <div
                        style={{ padding: "0 5px", cursor: "pointer" }}
                        className="div-1"
                      >
                        <h2>{item.title}</h2>
                        <span>Ends: {getDate(item.end_date)}</span>
                        <div style={{ display: "flex", marginTop: "40px" }}>
                          <div className="percentage">
                            {item.completion_percentage}%
                          </div>
                          {/* <div className="tasks">4 of 5 tasks</div> */}
                        </div>
                        <Progress
                          percent={item.completion_percentage}
                          showInfo={false}
                          className="purple-progress-bar"
                        />
                        {item.recent_upload === true ?
                          <span style={{ color: "#F15A29" }}>{item.recent_upload_count} New Submission(s)</span>
                          : <span style={{color:"#5e6278"}}>No New Submission(s)</span>
                        }
                      </div>
                    </Col>
                  );
                })
              ) : (
                <span className="no-result">There are no active workouts</span>
              )}
            </Row>
            <div style={{ display: "flex" }}>
              <h2>Upcoming Workouts</h2>
              {upcomingWorkouts && upcomingWorkouts.length >= 3 && (
                <h5
                  onClick={() => {
                    clickedWorkouts = "upcoming";
                    setShowAllWorkouts(true);
                    setWorkoutType("upcoming");
                  }}
                >
                  View All Workouts
                </h5>
              )}
            </div>
            <Row className="challenges-row" gutter={16}>
              {upcomingWorkouts && upcomingWorkouts.length > 0 ? (
                upcomingWorkouts.slice(0, 3).map(function (item, index) {
                  return (
                    <Col
                      className="gutter-row"
                      span={8}
                      key={index}
                    // onClick={()=>setWorkoutDetail(true)}
                    >
                      <div
                        style={{ padding: "0 5px", cursor: "default" }}
                        className="div-1"
                      >
                        <h2>{item.title}</h2>
                        <span>Ends: {getDate(item.end_date)}</span>
                        <div style={{ display: "flex", marginTop: "40px" }}>
                          <div className="percentage">
                            {item.completion_percentage}%
                          </div>
                          {/* <div className="tasks">4 of 5 tasks</div> */}
                        </div>
                        <Progress
                          percent={item.completion_percentage}
                          showInfo={false}
                          className="purple-progress-bar"
                        />
                        {item.recent_upload === true ?
                          <span style={{ color: "#F15A29" }}>{item.recent_upload_count} New Submission(s)</span>
                          : <span style={{color:"#5e6278"}}>No New Submission(s)</span>

                        }
                      </div>
                    </Col>
                  );
                })
              ) : (
                <span className="no-result">
                  There are no upcoming workouts
                </span>
              )}
            </Row>
            <div style={{ display: "flex" }}>
              <h2>Archived Workouts</h2>
              {archiveWorkouts && archiveWorkouts.length >= 3 && (
                <h5
                  onClick={() => {
                    clickedWorkouts = "archive";
                    setShowAllWorkouts(true);
                    setWorkoutType("archive");
                  }}
                >
                  View All Workouts
                </h5>
              )}
            </div>
            <Row className="challenges-row" gutter={16}>
              {archiveWorkouts && archiveWorkouts.length > 0 ? (
                archiveWorkouts.slice(0, 3).map(function (item, index) {
                  return (
                    <Col
                      className="gutter-row"
                      span={8}
                      key={index}
                      onClick={() => {
                        setWorkoutDetail(true);
                        setSelectedWorkout(item);
                      }}
                    >
                      <div
                        style={{ padding: "0 5px", cursor: "pointer" }}
                        className="div-1"
                      >
                        <h2>{item.title}</h2>
                        <span>Ends: {getDate(item.end_date)}</span>
                        <div style={{ display: "flex", marginTop: "40px" }}>
                          <div className="percentage">
                            {item.completion_percentage}%
                          </div>
                          {/* <div className="tasks">4 of 5 tasks</div> */}
                        </div>
                        <Progress
                          percent={item.completion_percentage}
                          showInfo={false}
                          className="purple-progress-bar"
                        />
                        {item.recent_upload === true ?
                          <span style={{ color: "#F15A29" }}>{item.recent_upload_count} New Submission(s)</span>
                          : <span style={{color:"#5e6278"}}>No New Submission(s)</span>
                        }
                      </div>
                    </Col>
                  );
                })
              ) : (
                <span className="no-result">
                  There are no archived workouts
                </span>
              )}
            </Row>
          </>
        )}
      </div>
      <AllWorkoutsModal
        showAllWorkouts={showAllWorkouts}
        setShowAllWorkouts={setShowAllWorkouts}
        workoutData={workoutData}
        allWorkouts={
          clickedWorkouts === "active"
            ? activeWorkouts
            : clickedWorkouts === "archive"
              ? archiveWorkouts
              : upcomingWorkouts
        }
        setWorkoutDetail={setWorkoutDetail}
        setSelectedWorkout={setSelectedWorkout}
      />
    </div>
  );
};
const Workouts = ({
  setWorkoutDetail,
  setExistingWorkout,
  setNewWorkout,
  workoutData,
  setSelectedWorkout,
}) => {
  return (
    <WorkoutsContextProvider workoutData={workoutData}>
      <WorkoutsHome
        setWorkoutDetail={setWorkoutDetail}
        setExistingWorkout={setExistingWorkout}
        setNewWorkout={setNewWorkout}
        workoutData={workoutData}
        setSelectedWorkout={setSelectedWorkout}
      />
    </WorkoutsContextProvider>
  );
};
export default Workouts;
