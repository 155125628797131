import "./workouts.scss";
import { Col, Row, Progress, Spin } from "antd";
import { useContext, useEffect } from "react";
import AllGroupWorkout from "../all-group-workout/all-group-workout";
import { UserDetailContext } from "../../../../coach-context/user-module-context/user-detail-context";
import { getDate } from "../../../../util/config";
let clickedWorkouts = "";

const Workouts = ({
  setWorkoutDetail,
  setSelectedWorkout,
  groupOwner,
}) => {
  const {
    groupWorkouts,
    setShowAllWorkouts,
    setWorkoutType,
    showAllWorkouts,
    loading,
    getProfileWorkouts,
  } = useContext(UserDetailContext);
  const style = {
    padding: "5px 0",
  };
  useEffect(() => {
    // if(selectedGroup !== ""){
    getProfileWorkouts(groupOwner)
    // }
  }, [])

  let activeWorkouts = [],
    archiveWorkouts = [],
    upcomingWorkouts = [];
  groupWorkouts?.length > 0 &&
    groupWorkouts?.map(function (item) {
      if (item.category === "active") {
        activeWorkouts.push(item);
      } else if (item.category === "archive") {
        archiveWorkouts.push(item);
      } else {
        upcomingWorkouts.push(item);
      }
    });
  return (
    <div className="workouts-main">
      <div style={{ display: "flex" }}>
        <h1>Workouts</h1>
      </div>
      <hr />
      {loading === true ? (
        <Spin
          className="loader"
          size="large"
          style={{
            position: "absolute",
            left: "49%",
            marginTop: "5%",
          }}
        />
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <h2>Active Workouts</h2>
            {activeWorkouts && activeWorkouts.length >= 3 && (
              <h5
                onClick={() => {
                  clickedWorkouts = "active";
                  setShowAllWorkouts(true);
                  setWorkoutType("active");
                }}
              >
                View All Workouts
              </h5>
            )}
          </div>
          <Row gutter={16} className="challenges-row">
            {activeWorkouts && activeWorkouts.length > 0 ? (
              activeWorkouts.slice(0, 3).map(function (item, index) {
                return (
                  <Col
                    className="gutter-row"
                    span={8}
                    style={{ padding: "0 8px 16px" }}
                    key={index}
                  >
                    <div
                      style={{ padding: "5px 0", cursor: "pointer" }}
                      className="div-1"
                      onClick={() => {
                        setWorkoutDetail(true);
                        setSelectedWorkout(item);
                      }}
                    >
                      <h2>{item.title}</h2>
                      <span>
                        Ends: {getDate(item.end_date)}
                      </span>
                      <div style={{ display: "flex", marginTop: "40px" }}>
                        <div className="percentage">
                          {item.completion_percentage}%
                        </div>
                      </div>
                      <Progress
                        percent={item.completion_percentage}
                        showInfo={false}
                        className="purple-progress-bar"
                      />
                    </div>
                  </Col>
                );
              })
            ) : (
              <span className="no-result">There are no active workouts</span>
            )}
          </Row>
          <div style={{ display: "flex" }}>
            <h2>Upcoming Workouts</h2>
            {upcomingWorkouts && upcomingWorkouts.length >= 3 && (
              <h5
                onClick={() => {
                  clickedWorkouts = "upcoming";
                  setShowAllWorkouts(true);
                  setWorkoutType("upcoming");
                }}
              >
                View All Workouts
              </h5>
            )}
          </div>
          <Row className="challenges-row">
            {upcomingWorkouts && upcomingWorkouts.length > 0 ? (
              upcomingWorkouts.slice(0, 3).map(function (item, index) {
                return (
                  <Col
                    className="gutter-row"
                    span={8}
                    style={{ padding: "0 8px 16px" }}
                    key={index}
                  >
                    <div
                      style={{ padding: "5px 0", cursor: "default" }}
                      className="div-1"
                    >
                      <h2>{item.title}</h2>
                      <span>
                        Ends: {getDate(item.end_date)}
                      </span>
                      <div style={{ display: "flex", marginTop: "40px" }}>
                        <div className="percentage">
                          {item.completion_percentage}%
                        </div>
                      </div>
                      <Progress
                        percent={item.completion_percentage}
                        showInfo={false}
                        className="purple-progress-bar"
                      />
                    </div>
                  </Col>
                );
              })
            ) : (
              <span className="no-result">There are no upcoming workouts</span>
            )}
          </Row>
          <div style={{ display: "flex" }}>
            <h2>Archived Workouts</h2>
            {archiveWorkouts && archiveWorkouts.length >= 3 && (
              <h5
                onClick={() => {
                  clickedWorkouts = "archive";
                  setShowAllWorkouts(true);
                  setWorkoutType("archive");
                }}
              >
                View All Workouts
              </h5>
            )}
          </div>
          <Row className="challenges-row">
            {archiveWorkouts && archiveWorkouts.length > 0 ? (
              archiveWorkouts.slice(0, 3).map(function (item, index) {
                return (
                  <Col
                    className="gutter-row"
                    span={8}
                    style={{ padding: "0 8px 16px" }}
                    key={index}
                  >
                    <div
                      style={{ padding: "5px 0", cursor: "pointer" }}
                      className="div-1"
                      onClick={() => {
                        setWorkoutDetail(true);
                        setSelectedWorkout(item);
                      }}
                    >
                      <h2>{item.title}</h2>
                      <span>
                        Ends: {getDate(item.end_date)}
                      </span>
                      <div style={{ display: "flex", marginTop: "40px" }}>
                        <div className="percentage">
                          {item.completion_percentage}%
                        </div>
                      </div>
                      <Progress
                        percent={item.completion_percentage}
                        showInfo={false}
                        className="purple-progress-bar"
                      />
                    </div>
                  </Col>
                );
              })
            ) : (
              <span className="no-result">There are no archived workouts</span>
            )}
          </Row>
        </>
      )}
      <AllGroupWorkout
        showAllWorkouts={showAllWorkouts}
        setShowAllWorkouts={setShowAllWorkouts}
        setWorkoutDetail={setWorkoutDetail}
        setSelectedWorkout={setSelectedWorkout}
        // workoutData={workoutData}
        allWorkouts={
          clickedWorkouts === "active"
            ? activeWorkouts
            : clickedWorkouts === "archive"
              ? archiveWorkouts
              : upcomingWorkouts
        }
      />
    </div>
  );
};
export default Workouts;
