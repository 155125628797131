import React, { useContext, useState } from "react";
import { Table, Tabs, Input, Button, Select , DatePicker } from "antd";
// import "../challenges-home/challenges-home.scss";
import "./leaderboard.scss"
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
import Profile from "../../../assets/images/avatar.svg";
import { useNavigate } from "react-router-dom";
import { getDate } from "../../../util/config";
import DownArrow from "../../../assets/images/arrows.svg";
import Calendar from "../../../assets/images/calendar.svg";
import RowVertical from "../../../assets/images/row-vertical.svg";
import Filter from "../../../assets/images/filter.svg";
import {
  GroupContext,
  GroupContextProvider,
} from "../../../context/group-context/group-context";
const LeaderBoard = () => {
  const dateFormat = "ddd. MMMM D, YYYY";

  const { pageNumber, pageSize, groups, totalGroups, handleTableChange } =
    useContext(GroupContext);
  const navigate = useNavigate();
  const onChange = (key) => {
  };
  const items = [
    {
      key: "all",
      label: `All`,
      //   children: `Content of Tab Pane 1`,
      // children: <Profile />
    },
    {
      key: "archived",
      label: `Archived`,
      // children: `Content of Tab Pane 2`,
      //   children: <UserAccountTable />
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { RangePicker } = DatePicker;
  const onChangeSelect = (value) => {
  };
  const columns = [
    {
      title: "member",
      dataIndex: "member",
      key: "member",
      width:"50%",
      render: (text , key) => 
    <div span={20} style={{ display: "flex" }}>
          <img src={Profile}></img>
          <div className="name-div">
            <h3 className="user-name">Jacob Jones</h3>
            <h4 className="user-handle">@jacobjones</h4>
          </div>
        </div>
    },
    {
      title: "reps",
      dataIndex: "reps",
      key: "reps",
      width:"30%",
    },
    {
      title: "completion",
      dataIndex: "completion",
      key: "completion",
      width:"20%",
    },
  ];
  const data = [];
  for (let i = 0; i < 5; i++) {
    data.push({
      reps:"21/22",
    completion:"96%"
    });
  }
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const gotoChallengeDetail = (record) => {
    navigate({
      pathname: `./${record.name}`,
      search: `?id=${record.id}`,
    });
  };

  return (
    <div className="leaderboard-main">
      <h1>Leaderboard</h1>
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input-md"
            style={{width:292}}
          />
          <div className="right-div">
          <Select
      placeholder="Most Reps. Completed"
      optionFilterProp="children"
      onChange={onChange}
      suffixIcon={<img src={DownArrow} />}
      className="select-input"
      options={[
        {
          value: 'jack',
          label: 'Jack',
        },
        {
          value: 'lucy',
          label: 'Lucy',
        },
        {
          value: 'tom',
          label: 'Tom',
        },
      ]}
    />
        </div>
        </div>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => gotoChallengeDetail(record),
            };
          }}
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
        //   pagination={{
        //     pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
        //     showSizeChanger: true,
        //     locale: { items_per_page: "" },
        //     defaultPageSize: 10,
        //     current: pageNumber,
        //     pageSize: pageSize,
        //     total: totalGroups,
        //   }}
          columns={columns}
          dataSource={data}
          className="table-container"
        />
      </div>
    </div>
  );
};
export default LeaderBoard;
