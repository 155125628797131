// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.owners-main {
  display: flex;
  padding: 30px 30px 27px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
}
.owners-main h1 {
  color: var(--gray-gray-800, #3F4254);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.18px;
  margin: 10px 0;
}
.owners-main .ant-col-4 {
  display: flex;
  justify-content: end;
}
.owners-main .custom-dropdown img {
  margin-left: 6px;
}`, "",{"version":3,"sources":["webpack://./src/admin-module/Challenges/members/members.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,4BAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,+CAAA;AACJ;AACI;EACE,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AACN;AACI;EACI,aAAA;EACA,oBAAA;AACR;AACI;EACI,gBAAA;AACR","sourcesContent":[".owners-main {\n    display: flex;\n    padding: 30px 30px 27px 30px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 40px;\n    align-self: stretch;\n    border-radius: 12px;\n    background: #FFF;\n    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);\n\n    h1 {\n      color: var(--gray-gray-800, #3F4254);\n      font-size: 22px;\n      font-style: normal;\n      font-weight: 600;\n      line-height: 18px;\n      letter-spacing: -0.18px;\n      margin: 10px 0;\n    }\n    .ant-col-4{\n        display: flex;\n        justify-content: end;\n    }\n    .custom-dropdown img{\n        margin-left: 6px;\n    }\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
