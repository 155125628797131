import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formPostDataTraining, getData, getDataTraining, jsonPostDataTraining } from "../../util/api-calls";

export const WorkoutDetailContext = React.createContext({});
export const WorkoutDetailContextProvider = ({ children = {} , selectedWorkout }) => {
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const entries = urlParams.entries();
    const params = paramsToObject(entries);
    function paramsToObject(entries) {
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result;
    }
    const [taskDetail , setTaskDetail] = useState({})
    const [workoutDetail , setWorkoutDetail] = useState({})
    const [sort , setSort] = useState("desc")
    const [search , setSearch] = useState("")
    const [selectedTask , setSelectedTask] = useState("")
    const [workoutProgress ,setWorkoutProgress ] = useState([])
    const [graphModal , setGraphModal] = useState(false)
    const [graphData , setGraphData] = useState([])
    const [submissionData , setSubmissionData] = useState({})
    const [ submissionDetail , setSubmissionDetail] = useState({})
    const [score , setScore] = useState()
    const [gradeInput, setGradeInput] = useState(false)
    const [selectedSubmission , setSelectedSubmission] = useState("")
    const [commentsData , setCommentsData] = useState()
    const [commentValue, setCommentValue] = useState("");
    const [profileId, setProfileId] = useState("")
    const [submissionRecord , setSubmissionRecord] = useState({})
    const [activeTab , setActiveTab] = useState("")
    const [submissionDetailModal, setSubmissionDetailModal] = useState(false)
    const getWorkoutDetail = () => {
      getDataTraining(`admin/workout/${selectedWorkout.id}`).then((res)=>{
        setWorkoutDetail(res.data)
        
      }).catch((err)=>{
        console.log(err)
      })
    }
    const getWorkoutProgress = () => {
      let url = ""
      if(selectedTask === "" && search === ""){
        url =  `admin/workout/${selectedWorkout.id}/progress?sort=${sort}`
      }
      else if(search !== "" && selectedTask === ""){
        url =  `admin/workout/${selectedWorkout.id}/progress?sort=${sort}&search=${search}` 
      }
      else if(search === "" && selectedTask !== ""){
        url =  `admin/workout/${selectedWorkout.id}/progress?sort=${sort}&task_id=${selectedTask}` 
      }
      else{
        url = `admin/workout/${selectedWorkout.id}/progress?sort=${sort}&task_id=${selectedTask}&search=${search}`
      }
      getDataTraining(url).then((res)=>{
        setWorkoutProgress(res.data)
      }).catch((err)=>{
        console.log(err)
      })
    }
    useEffect(()=>{
      getWorkoutProgress()
    },[sort , selectedTask , search])
    useEffect(()=>{
      getWorkoutDetail()
    },[])
    const getSubmissions = (e , taskId) => {
      getDataTraining(`admin/task/${taskDetail.id ? taskDetail.id : taskId}/profile/${e.id}/submissions`).then((res)=>{
        setSubmissionData(res.data)
      }).catch((err)=>{
        console.log(err)
      })
    }

  const getSubmissionDetail = (e) => {
    getDataTraining(`admin/task/submission/media/${selectedSubmission === "" ? e : selectedSubmission}`).then((res)=>{
      setSubmissionDetail(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const invalidateSubmission = (valid , profileId) => {
    let data= {
      "is_validate": valid ,
      "score": valid === false ? 0 : score,
      "task_submission_id": submissionDetail.task_submission_id,
      "task_submission_media_id": submissionDetail.id
  }
    jsonPostDataTraining(`review/task`,data).then((res)=>{
      getSubmissionDetail()
      setGradeInput(false)
      setSubmissionDetailModal(false);
      getSubmissions(profileId,taskDetail.id)
      setScore()
    }).catch((err)=>{
      console.log(err)
    }) 
  }
  const videoPlayed = (valid) => {
    let data= {
      "is_validate": valid ,
      "score":  submissionDetail?.score ? submissionDetail?.score : 0,
      "task_submission_id": submissionDetail.task_submission_id,
      "task_submission_media_id": submissionDetail.id
  }
    jsonPostDataTraining(`review/task`,data).then((res)=>{
      getSubmissionDetail()
    }).catch((err)=>{
      console.log(err)
    }) 
  }
  const addComment = (e, id) => {
    let data = {
      "text": commentValue
    }
    jsonPostDataTraining(`task/${submissionDetail.id}/feedback`, data).then((res) => {
      setCommentValue("")
      getChat();
    }).catch((err) => {
      console.log(err)
    })
  }
  const getChat = () => {
    getDataTraining(`task/${submissionDetail.id}/feedback`).then((res) => {
      setCommentsData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  const defaultContext = {
    workoutDetail,
    sort,
    setSort,
    setSelectedTask,
    search,
    setSearch,
    workoutProgress,
    setWorkoutProgress,
    setGraphModal,
    graphModal,
    graphData , 
    setGraphData,
    getSubmissions,
    setTaskDetail,
    submissionData,
    getSubmissionDetail,
    submissionDetail , 
    setSubmissionDetail,
    invalidateSubmission,
    score,
    setScore,
    gradeInput,
    setGradeInput,
    setSelectedSubmission,
    getChat,
    commentsData,
    addComment,
    commentValue,
    setCommentValue,
    submissionRecord,
    setSubmissionRecord,
    profileId,
    setProfileId,
    activeTab, setActiveTab,
    submissionDetailModal,
    setSubmissionDetailModal,
    params,
    videoPlayed
    // viewSubmissions,
    // setViewSubmissions
  };
  return (
    <WorkoutDetailContext.Provider value={defaultContext}>
      {children}
    </WorkoutDetailContext.Provider>
  );
};
