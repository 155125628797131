// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-settings-main h1 {
  margin: 0;
  color: var(--gray-gray-900, #181c32);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.22px;
  padding: 30px;
}
.sport-settings-main hr {
  border: 1px solid #E1E3EA;
  margin: 0;
}
.sport-settings-main .ant-col-7 {
  text-align: left;
}
.sport-settings-main .ant-col-7 label {
  color: var(--gray-gray-700, #5E6278);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.sport-settings-main .ant-select-selector {
  border-color: #E1E3EA !important;
}
.sport-settings-main .ant-select-selection-placeholder {
  color: var(--gray-gray-800, #3F4254);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 107.692% */
}`, "",{"version":3,"sources":["webpack://./src/admin-module/Users/sport-settings/sport-settings.scss"],"names":[],"mappings":"AACI;EACI,SAAA;EACA,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,aAAA;AAAR;AAGI;EACI,yBAAA;EACA,SAAA;AADR;AAGA;EACI,gBAAA;AADJ;AAGI;EACI,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADR;AAIA;EACI,gCAAA;AAFJ;AAIA;EACI,oCAAA;EACJ,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AAFA","sourcesContent":[".sport-settings-main {\n    h1 {\n        margin: 0;\n        color: var(--gray-gray-900, #181c32);\n        font-size: 22px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: 22px;\n        letter-spacing: -0.22px;\n        padding: 30px;\n    }\n\n    hr {\n        border: 1px solid #E1E3EA;\n        margin: 0;\n    }\n.ant-col-7 {\n    text-align: left;\n\n    label {\n        color: var(--gray-gray-700, #5E6278);\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 16px;\n    }\n}\n.ant-select-selector{\n    border-color: #E1E3EA !important;\n}\n.ant-select-selection-placeholder{\n    color: var(--gray-gray-800, #3F4254);\nfont-size: 13px;\nfont-style: normal;\nfont-weight: 600;\nline-height: 14px; /* 107.692% */\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
