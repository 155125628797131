import React, { useEffect, useState } from "react";
import "./individual-profile-detail.scss";
import { Col, Row, Menu, Tabs, Button, Skeleton } from "antd";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import User from "../../../assets/images/avatar.svg";
import Add from "../../../assets/images/add.svg";
import Overview from "../overview/overview";
import Challenges from "../challenges/challenges";
import WorkoutDetails from "../../workouts-detail/workouts-detail";
import Submissions from "../submissions/submissions";
import CombineMeasurements from "../combine-measurements";
import GeneralDetails from "../../general-details/general-details";
import EditGeneralDetails from "../../edit-general-details/edit-general-details";
import EditProfileModal from "../edit-profile-modal/edit-profile-modal";
import SportModal from "../sport-modal/sport-modal";
import Groups from "../groups/groups";
import Workouts from "../../shared/workouts/workouts";
import SportSettings from "../sport-settings/sport-settings";
import Purchases from "../purchases/purchases";
import { getData } from "../../../util/api-calls";
import { getDateWithoutDay } from "../../../util/config";
import { UserDetailContextProvider } from "../../../context/user-module-context/user-detail-context";
import WorkoutsDetail from "../../workouts-detail/workouts-detail";
const IndividualProfileDetail = () => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [selectedMenuItem, setSelectedMenuItem] = useState("overview");
  const [challengeDetail, setChallengeDetail] = useState(false);
  const [editProfile, showEditProfile] = useState(false);
  const [sportModal, setSportModal] = useState(false);
  const [profileDetail, setProfileDetail] = useState({});
  const [imageLoaded, setImageLoaded] = useState("");
  const [workoutDetail, setWorkoutDetail] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState({});
  const [groupOwner , setGroupOwner] = useState(false)

  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }
  const items = [
    getItem("Overview", "overview", <Overview profileDetail={profileDetail} />),
    getItem("Groups", "groups", <Groups />),
    // getItem(
    //   "Challenges",
    //   "challenges",
    //   challengeDetail ? (
    //     <WorkoutDetails Details setChallengeDetail={setChallengeDetail} />
    //   ) : (
    //     <Challenges setChallengeDetail={setChallengeDetail} />
    //   )
    // ),
    // getItem("Workouts", "workouts", <Workouts />),
    getItem(
      "Workouts",
      "workouts",
      workoutDetail ? (
        <WorkoutsDetail
          setWorkoutDetail={setWorkoutDetail}
          workoutDetail={workoutDetail}
          selectedWorkout={selectedWorkout}
          setGroupOwner={setGroupOwner}
          setSelectedWorkout={setSelectedWorkout}
          groupOwner={groupOwner}
        />
      ) : (
        <Workouts
          setWorkoutDetail={setWorkoutDetail}
          setSelectedWorkout={setSelectedWorkout}
          setGroupOwner={setGroupOwner}
          groupOwner={groupOwner}
          selectedWorkout={selectedWorkout}
          workoutDetail={workoutDetail}
        />
      )
    ),
    getItem("Submissions", "submissions", <Submissions />),
    // getItem("Combine Measurements", "measurements", <CombineMeasurements />),
    // getItem(
    //   "General Details",
    //   "details",
    //   editProfile ? (
    //     <EditGeneralDetails showEditProfile={showEditProfile} />
    //   ) : (
    //     <GeneralDetails showEditProfile={showEditProfile} />
    //   )
    // ),
    // getItem("Sports Settings", "settings", <SportSettings />),
    getItem(
      "Purchases",
      "purchases",
      <Purchases profileDetail={profileDetail} />
    ),
  ];
  const onChange = (key) => {
  };
  const gameItems = [
    {
      key: "basketball",
      label: "Basketball",
      //   children: 'Content of Tab Pane 1',
    },
    // {
    //   key: "baseball",
    //   label: "Baseball",
    //   //   children: 'Content of Tab Pane 2',
    // },
    // {
    //   key: "football",
    //   label: "Football",
    //   //   children: 'Content of Tab Pane 3',
    // },
    // {
    //   key: "rugby",
    //   label: "Rugby",
    // },
  ];
  const getUserProfile = () => {
    getData(`user/profile/${params.id}`)
      .then((res) => {
        setProfileDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getUserProfile();
  }, []);
  return (
    <div className="user-account-main">
      <div className="user-detail">
        <div style={{ width: 90 }}>
          {/* <img src={profileDetail.avatar}></img> */}
          {profileDetail.avatar !== null &&
            profileDetail.avatar !== "" &&
            imageLoaded === "" && (
              <Skeleton.Image
                active={true}
                style={{ width: "90px", height: "90px" }}
              />
            )}

          {profileDetail.avatar === null || profileDetail.avatar === "" ? (
            <Avatar
              name={profileDetail.first_name + " " + profileDetail.last_name}
              color="#F15A29"
              maxInitials={2}
              className="group-avatar"
            />
          ) : (
            <img
              src={profileDetail.avatar}
              className="group-logo"
              style={
                imageLoaded === "" ? { display: "none" } : { display: "block" }
              }
              onLoad={() => setImageLoaded("loaded")}
            />
          )}
        </div>
        <Row style={{ width: "100%" }}>
          <Col span={24} style={{ paddingLeft: 24 }}>
            <div className="name-handle">
              <h2 className="user-name">@{profileDetail.username}</h2>
              <div className="vl"></div>
              <span>ID : #{profileDetail.id}</span>
            </div>
            <div className="added-on">
              <span>Added on:</span>
              <p className="date">
                {getDateWithoutDay(profileDetail.created_at)}
              </p>
            </div>
            <Row gutter={[16, 16]} className="points-row">
              <Col
              span={3}
              >
                <p className="profile-label">Full Name</p>
                <p className="profile-value"    
                title={Object.keys(profileDetail).length > 0 ? profileDetail?.first_name + " " + profileDetail?.last_name : "N/A"}           
                style={{                    
                whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>
                  {Object.keys(profileDetail).length > 0 ? profileDetail?.first_name + " " + profileDetail?.last_name : "N/A"}
                </p>
              </Col>
              <Col span={3}>
                <p className="profile-label">Date of Birth</p>
                <p className="profile-value">
                  {profileDetail.date_of_birth === "" || profileDetail.date_of_birth === undefined ? "N/A" :  getDateWithoutDay(profileDetail.date_of_birth)}
                </p>
              </Col>
              <Col 
              span={3}
              >
                <p className="profile-label">Gender</p>
                <p className="profile-value">{profileDetail.gender}</p>
              </Col>
              <Col 
              span={2}
              >
                <p className="profile-label">Height</p>
                <p className="profile-value">
                  {profileDetail.height ? profileDetail.height : "N/A"}
                </p>
              </Col>
              <Col 
              span={2}
              >
                <p className="profile-label">Weight</p>
                <p className="profile-value">
                  {profileDetail.weight ? profileDetail.weight : "N/A"}
                </p>
              </Col>
              <Col 
              span={4}
               className="location-col">
                <p className="profile-label">Location</p>
                <p
                  className="profile-value"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 200,
                  }}
                  title={
                    profileDetail.location &&
                    profileDetail.location !== "undefined"
                      ? profileDetail.location
                      : "N/A"
                  }
                >
                  {profileDetail.location &&
                  profileDetail.location !== "undefined"
                    ? profileDetail.location
                    : "N/A"}
                </p>
              </Col>
            </Row>
          </Col>
          {/* <Col span={2} style={{ textAlign: "end" }}> */}
            {/* <Button
              className="dark-base-btn"
              // onClick={() => showEditProfile(true)}
            >
              Edit Profile
            </Button> */}
          {/* </Col> */}
        </Row>
      </div>
      <div style={{ position: "relative" }}>
        <Tabs
          defaultActiveKey="1"
          items={gameItems}
          onChange={onChange}
          className="game-tabs"
          destroyInactiveTabPane={true}
        />
        {/* <Button
          icon={<img src={Add} />}
          className="add-sport"
          onClick={() => setSportModal(true)}
        >
          Add Another Sport
        </Button> */}
      </div>
      <div className="user-data">
        <Tabs
          tabPosition="left"
          onChange={onChange}
          type="card"
          className="user-tabs"
          items={items}
          destroyInactiveTabPane={true}
        />
      </div>
      {/* <EditProfileModal
        editProfile={editProfile}
        showEditProfile={showEditProfile}
      /> */}
      <SportModal sportModal={sportModal} setSportModal={setSportModal} />
    </div>
  );
};
const UserInvidualProfile = () => {
  return (
    <UserDetailContextProvider>
      <IndividualProfileDetail />
    </UserDetailContextProvider>
  );
};
export default UserInvidualProfile;
