import { Button, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { PurchasesContext } from "../../../context/user-context/purchses-context";
import { getDateWithTimeWithoutDay } from "../../../util/config";


const TeamSubscriptionPurchase = ({setCreditModal , profileDetail}) => {
  const {teamSubPurchaseList , loading , getTeamSubList} = useContext(PurchasesContext)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const columns = [
        {
          title: "ID",
          dataIndex: "id",
        },
        {
          title: "Member Limit",
          dataIndex: "member_limit",
          width:"50px"

        },
        {
            title: "start",
            dataIndex: "subscription_start",
            render: (text, record,key) => (
              <span key={key}>{getDateWithTimeWithoutDay(record.subscription_start)}</span>
            )
  
          },
        {
          title: "End",
          dataIndex: "subscription_end",
          render: (text, record,key) => (
            <span key={key}>{getDateWithTimeWithoutDay(record.subscription_end)}</span>
          )

        },
        {
          title: "mode of purchase",
          dataIndex: "subscription_from",
          width:"50px"
        },
        {
          title: "Type",
          dataIndex: "subscription_type",
        },
      ];
            const onSelectChange = (newSelectedRowKeys) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
      };
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };
      useEffect(()=>{
        getTeamSubList(profileDetail.user)
      },[])
    return(
        <div className="bsa-main">
                  <div className="table-header">
        <h3 style={{margin:"11px 0"}}>Team Subscription</h3>
        <div className="right-div" style={{display:"flex"}}>
          {/* <Button
            className="red-base-btn"
            style={{ height: 38 }}
            onClick={() => setCreditModal(true)}
          >
            Add Credits
          </Button> */}
        </div>
      </div>
      <div className="table-div">
        <Table
            // rowSelection={rowSelection}
            rowKey={(obj) => obj.id}
          //   onRow={(record, rowIndex) => {
          //     return {
          //       onClick: () => gotoProfileDetail(record),
          //     };
          //   }}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: false,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            // current: 1,
            pageSize: 10,
            // total: 85,
            showQuickJumper: false,
          }}
          loading={loading}
          columns={columns}
          dataSource={teamSubPurchaseList}
          className="table-container"
        />
      </div>
        </div>
    )
}

export default TeamSubscriptionPurchase