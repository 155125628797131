import React, { useContext } from "react";
import "./index.scss";
import { Tabs } from "antd";
import AccountSettings from "./account-settings/account-settings";
import AdminUser from "./admin-users/admin-users";
import RolesPermissions from "./roles-permissions/roles-permissions";
import Notifications from "./notifications/notifications";
import Sports from "./sports/sports";
import Sponsors from "./sponsors/sponsors";
import Subscriptions from "./subscriptions/subscriptions";
import { SettingsContext , SettingsContextProvider } from "../../context/setting-context/setting_context";
import { UserContext } from "../../context/user-context";
const SettingsPage = () => {
  const {accountDetail} = useContext(UserContext)
  const {sportsList} = useContext(SettingsContext)
  const onChange = (key) => {
  };
  const items = [
    {
      key: "1",
      label: `Account Settings`,
      //   children: `Content of Tab Pane 1`,
      children: <AccountSettings />,
    },
    {
      key: "2",
      label: `Admin Users`,
      children : <AdminUser />,
      disabled : !accountDetail.is_superuser
      // children: `Content of Tab Pane 2`,
      //   children: <UserAccountTable />
    },
    {
      key: "3",
      label: "Roles & Permissions",
      children:<RolesPermissions />,
      disabled : !accountDetail.is_superuser
    },
    {
      key: "4",
      label: "Notifications",
      children: <Notifications />,
      disabled : !accountDetail.is_superuser
    },
    { key: "5", label: "Sports",children:<Sports/>,disabled : !accountDetail.is_superuser},
    { key: "6", label: "Sponsors" , children : <Sponsors />,disabled : !accountDetail.is_superuser },
    { key: "7", label: "Subscriptions" , children : <Subscriptions sportsList={sportsList}/>,disabled : !accountDetail.is_superuser },
  ];
  return (
    <div className="settings-main">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} className="custom-tabs" destroyInactiveTabPane={true}/>
    </div>
  );
};

const Settings = () => {
  return (
    <SettingsContextProvider>
      <SettingsPage />
    </SettingsContextProvider>
  );
};


export default Settings;
