import React, { useContext } from "react";
import {Tabs} from "antd"
import Profile from "./profiles-table/profiles-table";
import "./index.scss"
import history from "../../util/history";
// import UserAccountTable from "./user-account-table/user-account-table";
import UserAccountTable from "./user-account-table/user-account-table";
import { useNavigate } from "react-router-dom";
import { UserModuleContextProvider  , UserModuleContext} from "../../context/user-module-context/user-module-context";
const UsersPage = () =>{
  const {allProfiles} = useContext(UserModuleContext)
  const navigate = useNavigate()
    const onChange = (key) => {
        // if(key === "2"){
        //   history.push(`users/user-accounts`)   
        // }
      };
      const items = [
        {
          key: '1',
          label: `User Accounts`,
          // children: `Content of Tab Pane 2`,
          children: <UserAccountTable />
        },
        {
          key: '2',
          label: `Profiles`,
        //   children: `Content of Tab Pane 1`,
        children: <Profile />
        },

      ];
    return (
        <div className="user-main">
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
    )
}
const Users = () => {
  return(
    <UserModuleContextProvider>
      <UsersPage />
    </UserModuleContextProvider>
  )
}
export default Users;