import React, { useState, useRef } from "react";
import { Row, Col, Progress } from "antd";
import "./challenges.scss";
const Challenges = ({setChallengeDetail}) => {
  const style = {
    padding: "5px 0",
  };
  return (
    <div className="challenges-main">
      <h1>Challenges</h1>
      <hr />
      <h2>Challenges (Ballogy Training)</h2>
      <Row gutter={16} className="challenges-row">
        <Col className="gutter-row" span={8}>
          <div style={style} className="div-1" onClick={()=>setChallengeDetail(true)}>
            <h2>BCAM Buckets Challenge</h2>
            <span>Ends: Sun. 27 Apr 2023</span>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <div className="percentage">80%</div>
              <div className="tasks">4 of 5 tasks</div>
            </div>

            <Progress
              percent={80}
              showInfo={false}
              className="orange-progress-bar"
            />
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div style={style} className="div-1">
            <h2>BCAM Buckets Challenge</h2>
            <span>Ends: Sun. 27 Apr 2023</span>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <div className="percentage">80%</div>
              <div className="tasks">4 of 5 tasks</div>
            </div>

            <Progress
              percent={80}
              showInfo={false}
              className="orange-progress-bar"
            />
          </div>
        </Col>{" "}
        <Col className="gutter-row" span={8}>
          <div style={style} className="div-1">
            <h2>BCAM Buckets Challenge</h2>
            <span>Ends: Sun. 27 Apr 2023</span>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <div className="percentage">80%</div>
              <div className="tasks">4 of 5 tasks</div>
            </div>

            <Progress
              percent={80}
              showInfo={false}
              className="orange-progress-bar"
            />
          </div>
        </Col>
      </Row>
      <h2>Upcoming Challenges</h2>
      <Row className="challenges-row">
        <span className="no-result">There are no upcoming challenges</span>
      </Row>
      <h2>Archived Challenges</h2>
      <Row className="challenges-row">
        <span className="no-result">There are no archived challenges</span>
      </Row>
      {/* <h2>Workouts (Team Training)</h2>
      <Row gutter={16} className="challenges-row">
        <Col className="gutter-row" span={8}>
          <div style={style} className="div-1">
            <h2>BCAM Buckets Challenge</h2>
            <span>Ends: Sun. 27 Apr 2023</span>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <div className="percentage">80%</div>
              <div className="tasks">4 of 5 tasks</div>
            </div>

            <Progress
              percent={80}
              showInfo={false}
              className="purple-progress-bar"
            />
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div style={style} className="div-1">
            <h2>BCAM Buckets Challenge</h2>
            <span>Ends: Sun. 27 Apr 2023</span>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <div className="percentage">80%</div>
              <div className="tasks">4 of 5 tasks</div>
            </div>

            <Progress
              percent={80}
              showInfo={false}
              className="purple-progress-bar"
            />
          </div>
        </Col>{" "}
        <Col className="gutter-row" span={8}>
          <div style={style} className="div-1">
            <h2>BCAM Buckets Challenge</h2>
            <span>Ends: Sun. 27 Apr 2023</span>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <div className="percentage">80%</div>
              <div className="tasks">4 of 5 tasks</div>
            </div>

            <Progress
              percent={80}
              showInfo={false}
              className="purple-progress-bar"
            />
          </div>
        </Col>
      </Row> */}
    </div>
  );
};
export default Challenges;
