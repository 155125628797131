import React, { useEffect, useState } from "react";
import {Tabs } from "antd";
import "./index.scss";
import Challenges from "./challenges-home/challenges-home";
import Workouts from "./workout-home/workout-home"
import history from "../../util/history";
import { useNavigate } from "react-router-dom";
const ChallengesMainPage = () => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const navigate = useNavigate();
  const [activeTab , setActiveTab] = useState("challenges")
  const onChange = (key) => {
    setActiveTab(key)
  };
  const items = [
    // {
    //   key: "challenges",
    //   label: `Challenges`,
    //   //   children: `Content of Tab Pane 1`,
    //   // children: <Profile />
    //   children:<Challenges/>
    // },
    {
      key: "workouts",
      label: `Workouts`,
      children : <Workouts />
      // children: `Content of Tab Pane 2`,
      //   children: <UserAccountTable />
    },
  ];
  // useEffect(()=>{
  //   if(activeTab === "workouts" || params.tab === "workouts"){
  //     navigate({
  //       search: `tab=workouts`,
  //     })
  //   }else{
  //     navigate({
  //       search:`tab=challenges`
  //     })
  //   }
  // },[activeTab])
  return (
    <div className="challenges-main">
    {/* <Tabs defaultActiveKey={params.tab && params.tab} items={items} onChange={onChange} /> */}
    <Workouts />
</div>
  );
};

const ChallengesMain = () => {
  return (
    // <GroupContextProvider>
      <ChallengesMainPage />
    // </GroupContextProvider>
  );
};
export default ChallengesMain;
