import React, { useContext } from "react";
import { Row, Col, Divider, Button, message } from "antd";
import "./group-general-detail.scss";
import { GroupDetailContext } from "../../../context/group-context/group-detail-context";
import { getDate } from "../../../util/config";
import SubscriptionModal from "../subscription-modal/subscription-modal";
import SubscriptionHistory from "../subscription-history/subscription-history";
import EditSubscriptionModal from "../edit-subscription-modal/edit-subscription-modal";
import { UserContext } from "../../../context/user-context";
// import { SubscriptionContext } from "../../../context/subscription-context";
const GroupGeneralDetails = ({ setGeneralDetail }) => {
  const {
    generalDetail,
    setNewSubscription,
    setEditSubscription,
    editSubscription,
    setSubscriptionHistory,
    subscriptionHistory,
    newSubscription,
    setSubscriptionStatus,
    subscriptionStatus,
    deleteGroup,
  } = useContext(GroupDetailContext);
  const { accountDetail } = useContext(UserContext);
  // const {newSubscription , setNewSubscription,subscriptionHistory,setSubscriptionHistory,subscriptionStatus,setSubscriptionStatus} = useContext(SubscriptionContext)
  const info = () => {
    message.info("You don't have permissions. Contact to Admin");
  };
  const checkGroupPermission = () => {
    if(accountDetail.role !== null){
    for (let item of accountDetail.role?.role_permission) {
      if(item.module.name === "Groups" && item.permission.code_name === "EDIT"){
        if(item.value === false){
          info();
        }
        else{
          setGeneralDetail(false)       
        }
      }
    }
  }
  else{
    setGeneralDetail(false)       
  }
  }
  const checkGroupDeletePermission = () => {
    if(accountDetail.role !== null){
    for (let item of accountDetail.role?.role_permission) {
      if(item.module.name === "Groups" && item.permission.code_name === "DELETE"){
        if(item.value === false){
          info();
        }
        else{
          deleteGroup()
        }
      }
    }
  }
  else{
    deleteGroup()
  }
  }
  return (
    <div className="group-general-details-main">
      <div style={{ display: "flex" }}>
        <h1>General Details</h1>
        <button
          className="dark-base-btn"
          onClick={checkGroupPermission}
        >
          Edit
        </button>
      </div>
      <Divider orientationMargin={0} />
      <Row className="data-row">
        <Col span={24} style={{ margin: "6px 0 14px 0" }}>
          <h2>Sports</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Sport</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>{generalDetail.sport && generalDetail.sport.name}</h3>
        </Col>
        <Col span={24} style={{ margin: "14px 0" }}>
          <h2>General</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Curriculum Group</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.is_curriculum_group === true
              ? "Yes"
              : "No"}
          </h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Type</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.is_purchased_group === true
              ? "Paid Public Training Group"
              : "Team Training Group"}
          </h3>
        </Col>{" "}
        {/* {generalDetail.is_default === true && (
          <>
            <Col span={10} className="label-col">
              <h3>Skill Level</h3>
            </Col>
            <Col span={14} className="data-col">
              <h3>{generalDetail.level}</h3>
            </Col>
          </>
        )} */}
        <Col span={10} className="label-col">
          <h3>Group Name</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>{generalDetail.name}</h3>
        </Col>
        {generalDetail.is_purchased_group === true &&
        <>
        <Col span={10} className="label-col">
          <h3>Members Code Expiry</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.member_code_expiry} months
          </h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Short Description</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.description !== null ? generalDetail.description : "N/A"}
          </h3>
        </Col>
        </>
}
        {/* <Col span={10} className="label-col">
          <h3>Starting on</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>{generalDetail.start_date !== null ? getDate(generalDetail.start_date) : ""}</h3>
        </Col>{" "}
        <Col span={10} className="label-col">
          <h3>Ending on</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>{generalDetail.expiry_date !== null ? getDate(generalDetail.expiry_date) : ""}</h3>
        </Col>{" "} */}
        <Col span={24} style={{ margin: "40px 0 14px 0" }}>
          <h2>Subscription</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Subscription Status</h3>
        </Col>
        <Col span={14} className="data-col">
          {/* <div>
            <span className="green-tag">Active</span>
            <span className="manage-subscription">Manage Subscription</span>
          </div> */}
          {generalDetail.subscription_required === true &&
          generalDetail.subscription === null ? (
            <Button className="pink-tag">Inactive</Button>
          ) : generalDetail.subscription_required === true &&
            generalDetail.subscription !== null ? (
            <Button className="green-tag">Active</Button>
          ) : generalDetail.subscription_required === false &&
            generalDetail.subscription === null ? (
            <Button className="green-tag">Active</Button>
          ) : (
            <Button className="yellow-tag">none</Button>
          )}
          <br />
          {generalDetail.subscription_required === true && (
              <div style={{ marginTop: "16px", display: "block", gap: "8px" }}>
                <Button
                  className="orange-tag"
                  onClick={() => setNewSubscription(true)}
                  // disabled={subscriptionStatus === "none" ? false : true}
                  disabled={
                    generalDetail.subscription_required === true &&
                    generalDetail.subscription !== null
                  }
                  style={{ marginRight: 8, marginBottom: 8 }}
                >
                  Create New Subscription
                </Button>
                <Button
                  className="orange-tag"
                  // disabled={
                  //   subscriptionStatus === "none" ||
                  //   subscriptionStatus === "inactive"
                  //     ? true
                  //     : false
                  // }
                  style={{ marginRight: 8, marginBottom: 8 }}
                  onClick={() => setEditSubscription(true)}
                  disabled={
                    generalDetail.subscription_required === true &&
                    generalDetail.subscription === null
                  }
                >
                  Edit Current Subscription
                </Button>
                <Button
                  className="orange-tag"
                  // disabled={subscriptionStatus === "none" ? true : false}
                  onClick={() => setSubscriptionHistory(true)}
                  style={{ marginRight: 8, marginBottom: 8 }}
                >
                  Subscription History
                </Button>
              </div>
            )}
        </Col>
        <Col span={10} className="label-col">
          <h3>BSA Credit</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.free_bsa === 0
              ? "No BSA Credit"
              : generalDetail.free_bsa}
          </h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Members Limit</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.member_limit === 100000
              ? "No limit"
              : generalDetail.member_limit}
          </h3>
        </Col>
        <Col span={24} style={{ margin: "40px 0 14px 0" }}>
          <h2>Announcements</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Display Announcements Section</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.display_announcements_section === true
              ? "Yes"
              : "No"}
          </h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Display Posts Section</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>{generalDetail.display_post_section === true ? "Yes" : "No"}</h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Who can post to the group?</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.allow_members_post === true
              ? "All (Group Owners and Members)"
              : "Only Group Owners"}
          </h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Accessible Leaderboards</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.display_bsa_leaderboard === true ? "BSA," : ""}
            {/* {generalDetail.display_combine_measurement_leaderboard === true
              ? "Combine Measurements,"
              : ""} */}
            {generalDetail.display_workout_leaderboard === true
              ? "Workouts/Challenges"
              : ""}
          </h3>
        </Col>
        {/* <Col span={10} className="label-col">
          <h3>Tags</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>{generalDetail.tags}</h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Comments</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>{generalDetail.comments}</h3>
        </Col> */}
        <Col span={24} style={{ margin: "40px 0 14px 0" }}>
          <h2>Delete Group</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Delete Group</h3>
        </Col>
        <Col span={14} className="data-col">
          <button
            className="red-tag-btn"
            onClick={checkGroupDeletePermission}
            style={{ cursor: "pointer" }}
          >
            Delete Group
          </button>
        </Col>
      </Row>
      <SubscriptionModal
        setNewSubscription={setNewSubscription}
        setSubscriptionStatus={setSubscriptionStatus}
        newSubscription={newSubscription}
      />
      <SubscriptionHistory
        setSubscriptionHistory={setSubscriptionHistory}
        subscriptionHistory={subscriptionHistory}
      />
      <EditSubscriptionModal
        editSubscription={editSubscription}
        setEditSubscription={setEditSubscription}
      />
    </div>
  );
};
export default GroupGeneralDetails;
