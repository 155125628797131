import React, { useState, useRef } from "react";
import { Row, Col, Progress, Checkbox, Radio, Select, Form } from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import "./sport-settings.scss";
const formItemLayout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 17,
    },
  };
const SportSettings = ({setChallengeDetail}) => {
        const [form] = Form.useForm();
  const style = {
    padding: "5px 0",
  };
  return (
    <div className="sport-settings-main">
      <h1>Sports Details</h1>
      <hr />








      <Form
        form={form}
        name="dynamic_rule"
        style={{
          padding: "22px 30px ",
        }}
        className="sport-form"
      >
        <Form.Item
          {...formItemLayout}
          className="skills-div"
          label="Skill Level"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
            <Select
              showSearch
              placeholder="Level 2: Intermediate (age 11-15)"
              optionFilterProp="children"
              style={{ height: "48px" }}
              suffixIcon={<img src={DownArrow} />}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              className="skill-select"
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="shooting-hand"
          label="Shooting Hand"
          className="shooting-div"
        >
          <Radio.Group
            // value={value}
            // defaultValue={value}
            className="custom-radio"
          >
            <Radio value={1}>Right</Radio>
            <Radio value={2}>Left</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="positions"
          label="Positions"
          className="sport-div position-div"
        >
          <Checkbox.Group className="custom-checkbox">
            <Checkbox value="pg">PG</Checkbox>
            <Checkbox value="sg">SG</Checkbox>
            <Checkbox value="sf">SF</Checkbox>
            <Checkbox value="pf">PF</Checkbox>
            <Checkbox value="c">C</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Form>







      
      {/* <h2>Challenges (Ballogy Training)</h2>
      <Row gutter={16} className="challenges-row">
        <Col className="gutter-row" span={8}>
          <div style={style} className="div-1" onClick={()=>setChallengeDetail(true)}>
            <h2>BCAM Buckets Challenge</h2>
            <span>Ends: Sun. 27 Apr 2023</span>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <div className="percentage">80%</div>
              <div className="tasks">4 of 5 tasks</div>
            </div>

            <Progress
              percent={80}
              showInfo={false}
              className="orange-progress-bar"
            />
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div style={style} className="div-1">
            <h2>BCAM Buckets Challenge</h2>
            <span>Ends: Sun. 27 Apr 2023</span>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <div className="percentage">80%</div>
              <div className="tasks">4 of 5 tasks</div>
            </div>

            <Progress
              percent={80}
              showInfo={false}
              className="orange-progress-bar"
            />
          </div>
        </Col>{" "}
        <Col className="gutter-row" span={8}>
          <div style={style} className="div-1">
            <h2>BCAM Buckets Challenge</h2>
            <span>Ends: Sun. 27 Apr 2023</span>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <div className="percentage">80%</div>
              <div className="tasks">4 of 5 tasks</div>
            </div>

            <Progress
              percent={80}
              showInfo={false}
              className="orange-progress-bar"
            />
          </div>
        </Col>
      </Row>
      <h2>Upcoming Challenges</h2>
      <Row className="challenges-row">
        <span className="no-result">There are no upcoming challenges</span>
      </Row>
      <h2>Archived Challenges</h2>
      <Row className="challenges-row">
        <span className="no-result">There are no archived challenges</span>
      </Row> */}
          </div>
  );
};
export default SportSettings;
