import React, { useContext } from "react";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { Input, Checkbox, Switch, Button } from "antd";
import "./workout-overview.scss";
import DragIndicator from "../../../assets/images/drag_indicator.svg";
import { arrayMoveImmutable } from "array-move";
import Edit from "../../../assets/images/pencil.svg";
import Delete from "../../../assets/images/delete.svg";
import { ExistingWorkoutContext } from "../../../context/existing-workout-context";
const WorkoutOverview = ({
  workoutTasks,
  deleteTask,
  setShowAllWorkouts,
  setSelectedTask,
}) => {
  const [items, setItems] = React.useState(["a", "b", "c", "d", "e", "f"]);
  const CustomKnob = React.forwardRef((props, ref) => {
    return (
      <Button
        ref={ref}
        icon={<img src={DragIndicator} className="drag-icon" />}
      ></Button>
    );
  });
  const onSortEnd = ({ oldIndex, newIndex }) => {
    arrayMoveImmutable(items, oldIndex, newIndex);
  };
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  let url = window.location.pathname;
  console.log(workoutTasks);
  return (
    <div className="workout-overview">
      {!url.includes("challenge") && <h3>Workout Overview</h3>}
      <div className="total">
        Total Tasks
        {/* <span>{workoutTasks.length > 0 && workoutTasks.length}</span> */}
        {!url.includes("challenge") && (
          <>
            Total Reps
            <span>6</span>
          </>
        )}
      </div>
      <SortableList
        onSortEnd={onSortEnd}
        draggedItemClassName="dragged"
      >
        {workoutTasks &&
          workoutTasks.map((item, index) => (
            <SortableItem key={index}>
              <div
                className="overview-card"
                style={{ listStyleType: "none" }}
                onClick={() => setSelectedTask(item)}
              >
                <div style={{ display: "flex" }}>
                  <h3>{item.title}</h3>
                  <div className="right-div">
                    <Button icon={<img src={Edit} />}></Button>
                    <Button
                      icon={<img src={Delete} />}
                      onClick={() => deleteTask(item.id)}
                    ></Button>
                    <SortableKnob>
                      <CustomKnob />
                    </SortableKnob>
                  </div>
                </div>
                <div className="form-data">
                  <div className="reps">
                    <label htmlFor="complete-task">Complete task in</label>
                    <br />
                    <Input
                      addonBefore={"Reps"}
                      value={item.num_completion}
                      className="reps-input"
                    />
                  </div>
                  {/* <div className="legacy">
                      <label htmlFor="legacy-point">Legacy Points</label>
                      <Input value={"20"} className="legacy-input" />
                    </div> */}
                </div>
                <div className="extra">
                  <Checkbox
                    className="custom-checkbox"
                    checked={item.allow_more_reps}
                  />
                  <span className="allowed">Allowed extra reps.</span>
                  {!url.includes("challenge") && (
                    <div className="lock-div">
                      Lock this task
                      <Switch checked={item.lock} onChange={onChange} />
                    </div>
                  )}
                </div>
              </div>
            </SortableItem>
          ))}
      </SortableList>
      <div style={{ marginTop: "24px" }}>
        <button className="cancel-btn" onClick={() => setShowAllWorkouts(true)}>
          Cancel
        </button>
        <button
          className="save-btn"
        >
          Save
        </button>
      </div>
    </div>
  );
};
export default WorkoutOverview;
