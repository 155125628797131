import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { Col, Row, Select, Tabs } from "antd";
import "./challenge-detail.scss";
import User from "../../../assets/images/dual-user.svg";
import Avatar from "../../../assets/images/group-avatar.svg";
import Tasks from "../tasks/tasks";
// import Members from "../../Groups/owners-members/member";
import Members from "../members/members";
import LeaderBoard from "../leaderboard/leaderboard";
import DownArrow from "../../../assets/images/arrows.svg";

// import EditGroupGeneralDetails from "../edit-group-general-details/edit-group-general-detail";
// import OwnersMembers from "../owners-members/owners-members";
// import Workouts from "../workouts/workouts";
// import WorkoutDetails from "../workout-detail/workout-detail";
// import Announcements from "../announcements/announcements";
// import Posts from "../posts/posts";
// import ExistingWorkout from "../existing-workout/existing-workout";
// import NewWorkout from "../new-workout/new-workout";
// import {
//   GroupDetailContextProvider,
//   GroupDetailContext,
// } from "../../../context/group-context/group-detail-context";
// import GroupGeneralDetails from "../group-general-detail/group-general-detail";
const ChallengeDetailPage = () => {
  const [workoutDetail, setWorkoutDetail] = useState(false);
  const [existingWorkout, setExistingWorkout] = useState(false);
  const [generalDetails, setGeneralDetail] = useState(true);
  // const [generalDetails, setGeneralDetail] = useState(false);

  // const { generalDetail } = useContext(GroupDetailContext);
  const [newWorkout, setNewWorkout] = useState(false);
  const onSearch = (value) => {};
  const onChange = (value) => {
  };
  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }
  const items = [
    getItem(
      "Tasks",
      "tasks",
      <Tasks />
      // generalDetails ? (
      //   <GroupGeneralDetails generalDetail={generalDetail} setGeneralDetail={setGeneralDetail}/>
      // ) : (
      //   <EditGroupGeneralDetails generalDetail={generalDetail} setGeneralDetail={setGeneralDetail}/>
      // )
    ),
    getItem(
      "Leaderboard",
      "leaderboard",
      <LeaderBoard />
      // <OwnersMembers />
    ),
    getItem(
      "All Members",
      "all_members",
      // <Members/>
      <Members />
      // workoutDetail ? (
      //   <WorkoutDetails setWorkoutDetail={setWorkoutDetail} />
      // ) : (
      //   <Workouts
      //     setWorkoutDetail={setWorkoutDetail}
      //     setNewWorkout={setNewWorkout}
      //     setExistingWorkout={setExistingWorkout}
      //   />
      // )
    ),
  ];
  return (
    <div className="challenge-detail-main">
      <div className="challenge-detail">
        <div style={{ marginRight: 24 }}>
          <img src={Avatar}></img>
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", height: "30px" }}>
            {" "}
            <h2 className="challenge-name">Desmond Bane Shooting Basics</h2>
            <span className="purple-tag">$12</span>
            <span className="green-tag">Beginners</span>
            <Select
              placeholder="Actions"
              optionFilterProp="children"
              className="action-select"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{height:38}}
              suffixIcon={<img src={DownArrow} />}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div style={{ display: "flex", marginTop: "9px" }}>
            <div className="creator">
              <div style={{ marginBottom: 8 }}>
                Presented by :{" "}
                <span style={{ textDecoration: "underline" }}>
                  ITZ Sports Performance
                </span>
              </div>
              <div>
                Created by: <span>Admin_1</span>
              </div>
            </div>
            <div className="creator" style={{ marginLeft: "auto" }}>
              <div style={{ marginBottom: 8 }}>
                Created on : <span>Aug 11, 2023</span>
              </div>
              <div>
                Ends on: <span>Aug 11, 2023</span>
              </div>
            </div>
          </div>
          <Row gutter={[16, 16]} className="points-row">
            <Col span={4}>
              <span className="points">5</span>
              <br />
              <span className="category">Tasks</span>
            </Col>
            <Col span={4}>
              <span className="points">2</span>
              <br />
              <span className="category">Total Reps</span>
            </Col>
          </Row>
        </div>
      </div>
      <div className="challenge-data">
        <Tabs
          tabPosition="left"
          onChange={onChange}
          type="card"
          className="challenge-tabs"
          items={items}
        />
      </div>
      {/* </>
      )} */}
    </div>
  );
};

const ChallengeDetail = () => {
  return (
    // <GroupDetailContextProvider>
    <ChallengeDetailPage />
    // </GroupDetailContextProvider>
  );
};
export default ChallengeDetail;
