import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Radio,
  Select,
  Upload,
  DatePicker,
  Divider,
} from "antd";
import "./create-new-challenge.scss";
import { FaArrowLeftLong } from "react-icons/fa6";
import Profile from "../../../assets/images/group-avatar.svg";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import Calendar from "../../../assets/images/calendar.svg";
import { useNavigate } from "react-router-dom";
import File from "../../../assets/images/files-folders.svg";
import Plus from "../../../assets/images/plus-red.svg";
import {
  CreateGroupContextProvider,
  CreateGroupContext,
} from "../../../context/group-context/create-group-context";
import DownArrow from "../../../assets/images/arrows.svg";
import { faL } from "@fortawesome/free-solid-svg-icons";
import WorkoutOverview from "../../Groups/workout-overview/workout-overview";
// import ExistingTask from "../../Groups/new-workout/existing-task-modal";
import ExistingTask from "../existing-task/existing-task";
// import NewTask from "../../tasks/new-workout/new-task-modal";
import NewTask from "../../Tasks/new-task-modal/new-task-modal"
import ReactQuill from "react-quill";
// import 'react-quill/dist/quill.snow.css';

import Bold from "../../../assets/images/bold.svg"
import ReplaceTask from "../replace-task-modal/replace-task-modal";
// import {
//   $getRoot,
//   $getSelection,
//   $isRangeSelection,
//   FORMAT_TEXT_COMMAND,
// } from 'lexical';
// import {LexicalComposer} from '@lexical/react/LexicalComposer';
// import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin';
// import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
// import {ContentEditable} from '@lexical/react/LexicalContentEditable';
// import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
// import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
// import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

// const theme = {
  // Theme styling goes here
  // ...
// }
// import SubscriptionModal from "../subscription-modal/subscription-modal";
// import SubscriptionHistory from "../subscription-history/subscription-history";
const { TextArea } = Input;
const dateFormat = "ddd. MMMM D, YYYY";

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
// function onChange(editorState) {
//   editorState.read(() => {
//     // Read the contents of the EditorState here.
//     const root = $getRoot();
//     const selection = $getSelection();

//   });
// }
const CreateChallengePage = () => {
  const {
    sportsList,
    createGroup,
    type,
    setLevel,
    level,
  } = useContext(CreateGroupContext);
  const [existingTaskModal , setExistingTaskModal] = useState(false)
  const [newTaskModal , setNewTaskModal] = useState(false)
  const [value, setValue] = useState('');
  const [editorHtml , setEditorHtml] = useState("")
  const [imgSrc, setImgSrc] = useState();
  const [imageUrl, setImageUrl] = useState();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const onChangeSkillLevel = (e) => {
    setLevel(e);
  };
 
  const showExistingTaskModal = () => {
    setExistingTaskModal(true);
  };
  const showNewTaskModal = () =>{
    setNewTaskModal(true)
  }
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  };
  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  const [code, setCode] = useState("hellllo");
  const handleProcedureContentChange = (content, delta, source, editor) => {
    setCode(content);
    //let has_attribues = delta.ops[1].attributes || "";
    //const cursorPosition = e.quill.getSelection().index;
    // this.quill.insertText(cursorPosition, "★");
    //this.quill.setSelection(cursorPosition + 1);
  };
 function handleChange(html) {
    // this.setState({ editorHtml: html });
    setEditorHtml(html)
  }

  // function MyCustomAutoFocusPlugin() {
    // const [editor] = useLexicalComposerContext();
  
    
  
  // useEffect(() => {
  //     // Focus the editor when the effect fires!
  //     editor.focus();
  //   }, [editor]);
  
  //   return null;
  // }
  
  function onError(error) {
    throw error;
  }
  


  const initialConfig = {
    namespace: 'MyEditor',
    // theme,
    onError,
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      ["link", "image"],
    ],
  };
  const formats = ["header", "bold", "italic", "underline", "link", "image"];

  return (
    <div className="create-new-challenge">
      <div className="heading-row">
      <FaArrowLeftLong className= "left-arrow"  onClick={() => navigate("/home/challenges")} />
        <h1>Create a new challenge</h1>
      </div>
      <Form
        form={form}
        name="dynamic_rule"
        className="challenge-form"
        // onFinish={createGroup}
      >
           <Form.Item
          {...formItemLayout}
          name="avatar"
          label="Display Photo"
          className="img-div"
        >
          <div className="image-previewer">
            <img
              src={!imgSrc ? Profile : imgSrc}
              alt="avatar"
              style={{
                width: "100%",
                height: "100%",
              }}
              className="img"
            />
            <Upload
              className="avatar-uploader"
              showUploadList={false}
              type="file"
              customRequest={dummyRequest}
              beforeUpload={handleBeforeUpload}
            >
              <img src={Edit} alt="edit" className="edit"></img>
            </Upload>
            <img src={Close} alt="close" className="delete" onClick={removeImage}/>
          </div>
          <span className="img-type">Allowed file types: PNG, JPG, JPEG.</span>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="title"
          label="Workout Title"
          className="name-row"
          rules={[
            {
              required: false,
              message: "Please enter group name.",
            },
          ]}
        >
          <Input
            placeholder="Please enter workout title"
            className="custom-input"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="level"
          label="Skill Level"
          // className="skill"
          value={level}
        >
          <Select
          placeholder="Select skill level"
            optionFilterProp="children"
            onChange={onChangeSkillLevel}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size={"large"}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            options={[
              {
                value: "beginner",
                label: "Level 1: Beginners (Age 1-5)",
              },
              {
                value: "intermediate",
                label: "Lucy",
              },
              {
                value: "advance",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="sport"
          label="Sport"
          // className="skill"
          value={level}
        >
          <Select
          placeholder="Select Sport"
            optionFilterProp="children"
            onChange={onChangeSkillLevel}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size={"large"}
            className="select-input"
            suffixIcon={<img src={DownArrow} />}
            options={[
              {
                value: "beginner",
                label: "Level 1: Beginners (Age 1-5)",
              },
              {
                value: "intermediate",
                label: "Lucy",
              },
              {
                value: "advance",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="price"
          label="Set Price"
          className="name-row"
          rules={[
            {
              required: false,
              message: "Please enter group name.",
            },
          ]}
        >
          <Input
            placeholder="Please enter price"
            className="add-on-input"
            addonBefore={"$"}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="completion_bonus"
          label="Completion Bonus"
          className="name-row"
          rules={[
            {
              required: false,
              message: "Please enter group name.",
            },
          ]}
        >
          <Input
            placeholder="Please enter bonus"
            className="add-on-input"
            addonBefore={"$"}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="start_date"
          label="Starting on"
          rules={[
            {
              required: false,
              message: "Please input your name",
            },
          ]}
        >
          <DatePicker
            className="date-picker"
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{ height: "38px" }}
            placeholder="Select start date"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="expiry_Date"
          label="Ending on"
          rules={[
            {
              required: false,
              message: "Please input your name",
            },
          ]}
        >
          <DatePicker
            className="date-picker"
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{ height: "38px" }}
            placeholder="Select end date"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="reward_before"
          label="Reward before completing the challenge "
          rules={[
            {
              required: false,
              message: "Please input your name",
            },
          ]}
        >
                   <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  // value={detailContent}
                  className="custom-toolbar"
                  onChange={handleProcedureContentChange}
                  placeholder="Rewards before challenge completion..."
                />

        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="reward_after"
          label="Reward after completing the challenge "
          rules={[
            {
              required: false,
              message: "Please input your name",
            },
          ]}
        >
                   <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  // value={detailContent}
                  className="custom-toolbar"
                  onChange={handleProcedureContentChange}
                  placeholder="Rewards after challenge completion..."
                />

        </Form.Item>
        {/* <Form.Item
          {...formItemLayout}
          name="rewards"
          label="Rewards"
          rules={[
            {
              required: false,
              message: "Please input your name",
            },
          ]}
        > */}
      {/* <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={code}
        onChange={handleProcedureContentChange}
      /> */}

{/* <div className="bg-white relative rounded-sm shadow-sm border border-gray-200" style={{borderColor:"gray"}}>
      <LexicalComposer
        initialConfig={{
          theme: {
            // ltr: 'ltr',
            // rtl: 'rtl',
            paragraph: 'mb-1',
            text: {
              bold: 'font-bold',
              italic: 'italic',
              underline: 'underline',
              strikethrough: 'line-through',
            },
          },
          onError(error) {
            throw error;
          },
        }}
      >
        <Toolbar />
        <RichTextPlugin
          contentEditable={
            <ContentEditable 
            className="min-h-[450px] outline-none py-[15px] px-2.5 resize-none overflow-hidden text-ellipsis" />
          }
          // placeholder={
          //   <div className="absolute top-[15px] left-[10px] pointer-events-none select-none">
          //     Enter some text...
          //   </div>
          // }
        />
        <OnChangePlugin onChange={onChange} />
      </LexicalComposer>
    </div> */}
        {/* </Form.Item> */}
        <h2>Sponsor Details</h2>
        <Form.Item
          {...formItemLayout}
          name="sponsor"
          label="Select Sponsor"
          // className="skill"
          value={level}
        >
          <Select
          placeholder="Select sponsor"
            optionFilterProp="children"
            onChange={onChangeSkillLevel}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            style={{width:"635px"}}
            size={"large"}
            className="select-input"
            suffixIcon={<img src={DownArrow} />}
            options={[
              {
                value: "beginner",
                label: "Level 1: Beginners (Age 1-5)",
              },
              {
                value: "intermediate",
                label: "Lucy",
              },
              {
                value: "advance",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <div className="btn-div">
          <Button
            icon={<img src={File} />}
            className="existing-task-btn"
              onClick={showExistingTaskModal}
          >
            Add from existing tasks
          </Button>
          <Button
            icon={<img src={Plus} />}
            className="new-task-btn"
              onClick={showNewTaskModal}
          >
            Create a new task
          </Button>
        </div>
        <WorkoutOverview />
        {/* <Form.Item style={{ textAlign: "start", marginBottom: "0" }}>
          <Button
            htmlType="submit"
            type="primary"
            className="discard-btn"
            onClick={() => form.resetFields()}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              margin: "0 8px",
            }}
            className="dark-base-btn"
          >
            Save
          </Button>
        </Form.Item> */}
      </Form>
      {/* <ExistingTask existingTaskModal={existingTaskModal} setExistingTaskModal={setExistingTaskModal}/> */}
      <ExistingTask existingTaskModal={existingTaskModal} setExistingTaskModal={setExistingTaskModal}/>
          <NewTask newTaskModal={newTaskModal} setNewTaskModal={setNewTaskModal}/>
    </div>
  );
};
// const Toolbar = () => {
//   const [editor] = useLexicalComposerContext();
//   const [isBold, setIsBold] = React.useState(false);
//   const [isItalic, setIsItalic] = React.useState(false);
//   const [isUnderline, setIsUnderline] = React.useState(false);

//   const updateToolbar = React.useCallback(() => {
//     const selection = $getSelection();

//     if ($isRangeSelection(selection)) {
//       setIsBold(selection.hasFormat('bold'));
//       setIsItalic(selection.hasFormat('italic'));
//       setIsUnderline(selection.hasFormat('underline'));
//     }
//   }, [editor]);

//   React.useEffect(() => {
//     // return mergeRegister(
//       editor.registerUpdateListener(({ editorState }) => {
//         editorState.read(() => {
//           updateToolbar();
//         });
//       })
//     // );
//   }, [updateToolbar, editor]);

//   return (
//     <div className="fixed z-20 shadow bottom-8 left-1/2 transform -translate-x-1/2 min-w-52 h-10 px-2 py-2 bg-[#1b2733] mb-4 space-x-2 flex items-center">
//       <button
//         // className={clsx(
//         //   'px-1 hover:bg-gray-700 transition-colors duration-100 ease-in',
//         //   isBold ? 'bg-gray-700' : 'bg-transparent'
//         // )}
//         onClick={() => {
//           editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
//         }}
//       >
//         {/* <FontAwesomeIcon
//           icon="fa-solid fa-bold"
//           className="text-white w-3.5 h-3.5"
//         /> */}
//                 <img src={Bold} />

//       </button>
//       <button
//         // className={clsx(
//         //   'px-1 hover:bg-gray-700 transition-colors duration-100 ease-in',
//         //   isItalic ? 'bg-gray-700' : 'bg-transparent'
//         // )}
//         onClick={() => {
//           editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
//         }}
//       >
//         {/* <FontAwesomeIcon
//           icon="fa-solid fa-italic"
//           className="text-white w-3.5 h-3.5"
//         /> */}
//       </button>
//       <button
//         // className={clsx(
//         //   'px-1 hover:bg-gray-700 transition-colors duration-100 ease-in',
//         //   isUnderline ? 'bg-gray-700' : 'bg-transparent'
//         // )}
//         onClick={() => {
//           editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
//         }}
//       >
//         {/* <FontAwesomeIcon
//           icon="fa-solid fa-underline"
//           className="text-white w-3.5 h-3.5"
//         /> */}
//       </button>

//       <span className="w-[1px] bg-gray-600 block h-full"></span>
//     </div>
//   );
// };
const CreateChallenge = () => {
  return (
    <CreateGroupContextProvider>
      <CreateChallengePage />
    </CreateGroupContextProvider>
  );
};
export default CreateChallenge;
