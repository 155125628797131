import React, { useState, useRef, useEffect } from "react";
import "./overview.scss";
import { Col, Row, DatePicker, Radio, Table, Card, Button, Spin } from "antd";
import dayjs from "dayjs";
import Calendar from "../../../assets/images/calendar.svg";
import Video from "../../../assets/Snail.mp4";
import Play from "../../../assets/images/play.svg";
import Player from "../../Player/Player";
import Expand from "../../../assets/images/expand.svg";
import CollapseIcon from "../../../assets/images/collapse.svg";
import { getDateWithoutDay } from "../../../util/config";
import ReactPlayer from "react-player";
const { Meta } = Card;
let bg1 = "#109500",
  bg2 = "#5DC800",
  bg3 = "#BDE407",
  bg4 = "#F8D62B",
  bg5 = "#FC8D01",
  bg6 = "#F54F0F",
  bg7 = "#D0021B";
const Overview = ({ profileDetail }) => {
  const wrapperRef = React.useRef(null);
  const [filter, setFilter] = useState("month");
  const [playButton, setPlayButton] = useState(true);
  const [zoneTotal, setZoneTotal] = useState(0);
  const [testTotal, setTestTotal] = useState(0);
  const [selectedZoneTotal, setSelectedZoneTotal] = useState(0);
  const [selectedTestTotal, setSelectedTestTotal] = useState(0);
  const [selectedZone, setSelectedZone] = useState(["A" , "B" , "C" , "D" ,"E" , "F" , "G" , "H" , "I" , "J" , "M" , "P"]);
  const [selectedAll , setSelectedAll] = useState(true)
  const [zoneAbg, setZoneAbg] = useState();
  const [zoneBbg, setZoneBbg] = useState();
  const [zoneCbg, setZoneCbg] = useState();
  const [zoneDbg, setZoneDbg] = useState();
  const [zoneEbg, setZoneEbg] = useState();
  const [zoneFbg, setZoneFbg] = useState();
  const [zoneGbg, setZoneGbg] = useState();
  const [zoneHbg, setZoneHbg] = useState();
  const [zoneIbg, setZoneIbg] = useState();
  const [zoneJbg, setZoneJbg] = useState();
  const [zoneMbg, setZoneMbg] = useState();
  const [zonePbg, setZonePbg] = useState();
  const [loading, setLoading] = useState(true);

  const monthFormat = "YYYY/MM";
  const style = {
    padding: "5px 0",
  };
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  const columns = [
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      width: "30%",
    },
    {
      title: "SUBMITTED ON",
      dataIndex: "test_time",
      key: "test_time",
      render: (text, record, index) => {
        return <div>{getDateWithoutDay(record.test_time)}</div>;
      },
    },
    // {
    //   title: "PLAYER RANK",
    //   dataIndex: "rank",
    //   key: "rank",
    // },
    Table.EXPAND_COLUMN,
  ];
  useEffect(() => {
    if (profileDetail.bsa_detail && Object.keys(profileDetail).length > 0) {
      setZoneTotal(
        Object.values(profileDetail.bsa_detail?.total_zone).reduce(
          (a, b) => a + b,
          0
        )
      );
      setTestTotal(
        Object.values(profileDetail.bsa_detail?.test_zone).reduce(
          (a, b) => a + b,
          0
        )
      );
      setSelectedZoneTotal(
        Object.values(profileDetail.bsa_detail?.total_zone).reduce(
        (a, b) => a + b,
        0
      )
      )
      setSelectedTestTotal(
        Object.values(profileDetail.bsa_detail?.test_zone).reduce(
          (a, b) => a + b,
          0
        )
      );
      setLoading(false);
    } else if (profileDetail.bsa_detail === null) {
      setLoading(false);
    }
    setZoneAbg(
      (profileDetail.bsa_detail?.test_zone["A"] /
        profileDetail.bsa_detail?.total_zone["A"]) *
        100
    );
    setZoneBbg(
      (profileDetail.bsa_detail?.test_zone["B"] /
        profileDetail.bsa_detail?.total_zone["B"]) *
        100
    );
    setZoneCbg(
      (profileDetail.bsa_detail?.test_zone["C"] /
        profileDetail.bsa_detail?.total_zone["C"]) *
        100
    );
    setZoneDbg(
      (profileDetail.bsa_detail?.test_zone["D"] /
        profileDetail.bsa_detail?.total_zone["D"]) *
        100
    );
    setZoneEbg(
      (profileDetail.bsa_detail?.test_zone["E"] /
        profileDetail.bsa_detail?.total_zone["E"]) *
        100
    );
    setZoneFbg(
      (profileDetail.bsa_detail?.test_zone["F"] /
        profileDetail.bsa_detail?.total_zone["F"]) *
        100
    );
    setZoneGbg(
      (profileDetail.bsa_detail?.test_zone["G"] /
        profileDetail.bsa_detail?.total_zone["G"]) *
        100
    );
    setZoneHbg(
      (profileDetail.bsa_detail?.test_zone["H"] /
        profileDetail.bsa_detail?.total_zone["H"]) *
        100
    );
    setZoneIbg(
      (profileDetail.bsa_detail?.test_zone["I"] /
        profileDetail.bsa_detail?.total_zone["I"]) *
        100
    );
    setZoneJbg(
      (profileDetail.bsa_detail?.test_zone["J"] /
        profileDetail.bsa_detail?.total_zone["J"]) *
        100
    );
    setZoneMbg(
      (profileDetail.bsa_detail?.test_zone["M"] /
        profileDetail.bsa_detail?.total_zone["M"]) *
        100
    );
    setZonePbg(
      (profileDetail.bsa_detail?.test_zone["P"] /
        profileDetail.bsa_detail?.total_zone["P"]) *
        100
    );
  }, [profileDetail]);
  const selectZone = (e) => {
    if(e.target.id !== "" && profileDetail.bsa_detail?.test_zone[e.target.id] !== 0 || profileDetail.bsa_detail?.total_zone[e.target.id] !== 0){
    setSelectedAll(false)
    let blockArea = ["K", "L", "N", "O"];
    if (!blockArea.includes(e.target.id)) {
      if (!selectedZone.includes(e.target.id) && selectedAll === false) {
        setSelectedZoneTotal(
          selectedZoneTotal + profileDetail.bsa_detail?.total_zone[e.target.id]
        );
        setSelectedTestTotal(
          selectedTestTotal + profileDetail.bsa_detail?.test_zone[e.target.id]
        );
        setSelectedZone((selectedZone) => [...selectedZone, e.target.id]);
      } else {
        // if(!selectedAll){
        setSelectedZoneTotal(
          selectedZoneTotal - profileDetail.bsa_detail?.total_zone[e.target.id]
        );
        setSelectedTestTotal(
          selectedTestTotal - profileDetail.bsa_detail?.test_zone[e.target.id]
        );
        // }
        setSelectedAll(false)
        const updatedArray = selectedZone.filter(
          (item) => item !== e.target.id
        );
        setSelectedZone(updatedArray);
      }
    }
  }
  };
  const selectAllZone = () => {
    setSelectedZone(["A" , "B" , "C" , "D" ,"E" , "F" , "G" , "H" , "I" , "J" , "M" , "P"])
    // setSelectedAll(true)
    setSelectedZoneTotal(
      Object.values(profileDetail.bsa_detail?.total_zone).reduce(
      (a, b) => a + b,
      0
    )
    )
    setSelectedTestTotal(
      Object.values(profileDetail.bsa_detail?.test_zone).reduce(
        (a, b) => a + b,
        0
      )
    );
    setZoneTotal(
      Object.values(profileDetail.bsa_detail?.total_zone).reduce(
        (a, b) => a + b,
        0
      )
    );
    setTestTotal(
      Object.values(profileDetail.bsa_detail?.test_zone).reduce(
        (a, b) => a + b,
        0
      )
    );
  };
  const clearZone = () => {
    setSelectedAll(false)
    setSelectedZone([]);
    setTestTotal(0);
    setZoneTotal(0);
    setSelectedTestTotal(0)
    setSelectedZoneTotal(0)
  };
  const changePicker = (e, f) => {
    let selectedMonth = new Date(e).getMonth() + 1,
      selectedYear = new Date(e).getFullYear(),
      events = profileDetail.bsa_detail.bsa_tests.filter((item) => {
        var dateStr = selectedYear + "/" + selectedMonth;
        return dayjs(item.test_time).format("YYYY/M").indexOf(dateStr) !== -1;
      });
    // console.log(events);
  };
  return (
    <div className="overview-main">
      <h1>Overview</h1>
      <hr />
      <h2>Legacy Points</h2>
      <div style={{ display: "flex" }}>
        <h3>Overall Legacy Points</h3>
        <h3
          style={{
            marginLeft: "auto",
            color: "#181c32",
            fontWeight: "600",
            paddingBottom: "24px",
          }}
        >
          {profileDetail.bsa_legacy_points +
            profileDetail.challenges_legacy_points +
            profileDetail.profile_legacy_points}
          <span>Points</span>
        </h3>
      </div>
      <h2>Breakdown</h2>
      <div style={{ display: "flex" }}>
        <h3>BSA</h3>
        <h3 style={{ marginLeft: "auto", color: "#181c32", fontWeight: "600" }}>
          {profileDetail.bsa_legacy_points
            ? profileDetail.bsa_legacy_points
            : 0}
          <span>Points</span>
        </h3>
      </div>
      <hr style={{ margin: "16px 30px" }} />
      <div style={{ display: "flex" }}>
        <h3>Workouts</h3>
        <h3 style={{ marginLeft: "auto", color: "#181c32", fontWeight: "600" }}>
          {profileDetail.challenges_legacy_points}
          <span>Points</span>
        </h3>
      </div>
      <hr style={{ margin: "16px 30px" }} />
      <div style={{ display: "flex", paddingBottom: "30px" }}>
        <h3>Other Activities</h3>
        <h3 style={{ marginLeft: "auto", color: "#181c32", fontWeight: "600" }}>
          {profileDetail.profile_legacy_points}
          <span>Points</span>
        </h3>
      </div>
      <h2>BSA Results</h2>
      {!profileDetail.bsa_detail && loading === false ? (
        <div className="no-bsa">User have not taken the BSA yet</div>
      ) : loading == true ? (
        <div style={{ height: 400, position: "relative" }}>
          <Spin
            className="loader"
            size="large"
            style={{
              position: "absolute",
              left: "50%",
              top: "42%",
            }}
          />{" "}
        </div>
      ) : (
        <>
          <h3 style={{ color: "#181c32" }}>Select a zone to view score</h3>
          <svg
            style={{ padding: 20 }}
            width="901"
            height="561"
            className="chart"
            viewBox="0 0 901 561"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={selectZone}
          >
            <path
              className="test"
              id="K"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M321.295 435.807L214.115 410.406C207.341 432.781 203.698 456.499 203.698 481.065V560.896H314.932V476.9C314.932 462.575 317.165 448.766 321.295 435.807Z"
              fill="#3F4254"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M396.273 502.586H504.727V498.421H396.273V502.586ZM433.815 478.984C433.815 469.785 441.287 462.324 450.5 462.324C459.716 462.324 467.185 469.785 467.185 478.984C467.185 488.184 459.716 495.645 450.5 495.645C441.287 495.645 433.815 488.184 433.815 478.984ZM575.63 436.756L575.497 436.789L575.127 435.231C563.592 400.825 538.172 372.774 505.505 357.719L502.344 356.339L502.352 356.317C486.445 349.486 468.918 345.701 450.5 345.701C392.197 345.701 342.761 383.62 325.581 436.109L325.42 436.789L325.364 436.775C321.301 449.423 319.104 462.907 319.104 476.902V560.898H581.896V476.902C581.896 462.899 579.696 449.409 575.63 436.756Z"
              // fill="#109500"
              fill={
                selectedZone.includes("P") || selectedAll ?
                zonePbg >= 70
                  ? bg1
                  : zonePbg >= 50 && zonePbg <= 69
                  ? bg2
                  : zonePbg >= 40 && zonePbg <= 49
                  ? bg3
                  : zonePbg >= 30 && zonePbg <= 39
                  ? bg4
                  : zonePbg >= 20 && zonePbg <= 29
                  ? bg5
                  : zonePbg >= 10 && zonePbg <= 19
                  ? bg6
                  : zonePbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="P"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M450.5 491.487C457.411 491.487 463.014 485.892 463.014 478.991C463.014 472.091 457.411 466.496 450.5 466.496C443.59 466.496 437.986 472.091 437.986 478.991C437.986 485.892 443.59 491.487 450.5 491.487Z"
              // fill="#109500"
              fill={
                selectedZone.includes("P") || selectedAll ?
                zonePbg >= 70
                  ? bg1
                  : zonePbg >= 50 && zonePbg <= 69
                  ? bg2
                  : zonePbg >= 40 && zonePbg <= 49
                  ? bg3
                  : zonePbg >= 30 && zonePbg <= 39
                  ? bg4
                  : zonePbg >= 20 && zonePbg <= 29
                  ? bg5
                  : zonePbg >= 10 && zonePbg <= 19
                  ? bg6
                  : zonePbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="P"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M686.882 410.389L579.702 435.785C583.835 448.749 586.068 462.561 586.068 476.894V560.89H697.302V481.059C697.302 456.491 693.659 432.766 686.882 410.389Z"
              fill="#3F4254"
              id={"O"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M811.208 380.938C824.793 419.921 832.173 461.786 832.173 505.367V560.901H889.978C896.065 560.901 901 555.973 901 549.894V359.663L811.208 380.938Z"
              // fill="#F8D62B"
              fill={
                selectedZone.includes("E") || selectedAll ?
                zoneEbg >= 70
                  ? bg1
                  : zoneEbg >= 50 && zoneEbg <= 69
                  ? bg2
                  : zoneEbg >= 40 && zoneEbg <= 49
                  ? bg3
                  : zoneEbg >= 30 && zoneEbg <= 39
                  ? bg4
                  : zoneEbg >= 20 && zoneEbg <= 29
                  ? bg5
                  : zoneEbg >= 10 && zoneEbg <= 19
                  ? bg6
                  : zoneEbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id={"E"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.00146484 359.662V549.893C0.00146484 555.972 4.93592 560.9 11.0229 560.9H68.8277V505.366C68.8277 461.785 76.2081 419.923 89.7898 380.943L0.00146484 359.662Z"
              // fill="#F8D62B"
              fill={
                selectedZone.includes("A") || selectedAll ?
                zoneAbg >= 70
                  ? bg1
                  : zoneAbg >= 50 && zoneAbg <= 69
                  ? bg2
                  : zoneAbg >= 40 && zoneAbg <= 49
                  ? bg3
                  : zoneAbg >= 30 && zoneAbg <= 39
                  ? bg4
                  : zoneAbg >= 20 && zoneAbg <= 29
                  ? bg5
                  : zoneAbg >= 10 && zoneAbg <= 19
                  ? bg6
                  : zoneAbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id={"A"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M595.45 153.989C694.913 194.643 773.309 275.879 809.81 376.988L901 355.38V11.0068C901 4.9279 896.066 0 889.979 0H662.839L595.45 153.989Z"
              // fill="#D0021B"
              fill={
                selectedZone.includes("D") || selectedAll ?
                zoneDbg >= 70
                  ? bg1
                  : zoneDbg >= 50 && zoneDbg <= 69
                  ? bg2
                  : zoneDbg >= 40 && zoneDbg <= 49
                  ? bg3
                  : zoneDbg >= 30 && zoneDbg <= 39
                  ? bg4
                  : zoneDbg >= 20 && zoneDbg <= 29
                  ? bg5
                  : zoneDbg >= 10 && zoneDbg <= 19
                  ? bg6
                  : zoneDbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M552.071 253.113C617.271 281.802 667.869 337.259 689.709 405.447L805.721 377.96C769.54 278.137 692.051 197.948 593.778 157.81L552.071 253.113Z"
              // fill="#F8D62B"
              fill={
                selectedZone.includes("I") || selectedAll ?
                zoneIbg >= 70
                  ? bg1
                  : zoneIbg >= 50 && zoneIbg <= 69
                  ? bg2
                  : zoneIbg >= 40 && zoneIbg <= 49
                  ? bg3
                  : zoneIbg >= 30 && zoneIbg <= 39
                  ? bg4
                  : zoneIbg >= 20 && zoneIbg <= 29
                  ? bg5
                  : zoneIbg >= 10 && zoneIbg <= 19
                  ? bg6
                  : zoneIbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="I"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M352.712 251.493C382.765 238.856 415.81 231.867 450.501 231.867C485.173 231.867 518.201 238.848 548.24 251.471L589.906 156.262C546.777 139.202 499.739 129.822 450.501 129.822C401.249 129.822 354.2 139.208 311.063 156.273L352.712 251.493Z"
              // fill="#F8D62B"
              fill={
                selectedZone.includes("H") || selectedAll ?
                zoneHbg >= 70
                  ? bg1
                  : zoneHbg >= 50 && zoneHbg <= 69
                  ? bg2
                  : zoneHbg >= 40 && zoneHbg <= 49
                  ? bg3
                  : zoneHbg >= 30 && zoneHbg <= 39
                  ? bg4
                  : zoneHbg >= 20 && zoneHbg <= 29
                  ? bg5
                  : zoneHbg >= 10 && zoneHbg <= 19
                  ? bg6
                  : zoneHbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="H"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M507.831 354.209C540.595 369.497 566.226 397.487 578.37 431.832L685.637 406.417C664.107 339.511 614.416 285.101 550.4 256.934L507.831 354.209Z"
              fill="#3F4254"
              id="N"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M348.881 253.139L307.193 157.828C208.931 197.971 131.454 278.154 95.2773 377.97L211.286 405.465C233.125 337.288 283.7 281.834 348.881 253.139Z"
              // fill="#BDE407"
              fill={
                selectedZone.includes("G") || selectedAll ?
                zoneGbg >= 70
                  ? bg1
                  : zoneGbg >= 50 && zoneGbg <= 69
                  ? bg2
                  : zoneGbg >= 40 && zoneGbg <= 49
                  ? bg3
                  : zoneGbg >= 30 && zoneGbg <= 39
                  ? bg4
                  : zoneGbg >= 20 && zoneGbg <= 29
                  ? bg5
                  : zoneGbg >= 10 && zoneGbg <= 19
                  ? bg6
                  : zoneGbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="G"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M546.57 255.277C517.043 242.876 484.579 236.017 450.5 236.017C416.403 236.017 383.923 242.884 354.382 255.299L396.91 352.526C413.347 345.454 431.465 341.533 450.5 341.533C469.509 341.533 487.602 345.443 504.026 352.498L546.57 255.277Z"
              // fill="#FC8D01"
              fill={
                selectedZone.includes("M") || selectedAll ?
                zoneMbg >= 70
                  ? bg1
                  : zoneMbg >= 50 && zoneMbg <= 69
                  ? bg2
                  : zoneMbg >= 40 && zoneMbg <= 49
                  ? bg3
                  : zoneMbg >= 30 && zoneMbg <= 39
                  ? bg4
                  : zoneMbg >= 20 && zoneMbg <= 29
                  ? bg5
                  : zoneMbg >= 10 && zoneMbg <= 19
                  ? bg6
                  : zoneMbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="M"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M350.55 256.958C286.557 285.134 236.883 339.538 215.359 406.433L322.625 431.854C334.758 397.522 360.364 369.541 393.103 354.244L350.55 256.958Z"
              fill="#3F4254"
              id="L"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M238.161 0H11.0214C4.93445 0 0 4.9279 0 11.0068V355.383L91.1899 376.994C127.689 275.893 206.07 194.663 305.522 154.003L238.161 0Z"
              // fill="#F54F0F"
              fill={
                selectedZone.includes("B") || selectedAll ?
                zoneBbg >= 70
                  ? bg1
                  : zoneBbg >= 50 && zoneBbg <= 69
                  ? bg2
                  : zoneBbg >= 40 && zoneBbg <= 49
                  ? bg3
                  : zoneBbg >= 30 && zoneBbg <= 39
                  ? bg4
                  : zoneBbg >= 20 && zoneBbg <= 29
                  ? bg5
                  : zoneBbg >= 10 && zoneBbg <= 19
                  ? bg6
                  : zoneBbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="B"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M309.392 152.444C353.043 135.154 400.654 125.649 450.501 125.649C500.334 125.649 547.937 135.151 591.58 152.433L658.287 0.00195312H242.712L309.392 152.444Z"
              // fill="#FC8D01"
              fill={
                selectedZone.includes("C") || selectedAll ?
                zoneCbg >= 70
                  ? bg1
                  : zoneCbg >= 50 && zoneCbg <= 69
                  ? bg2
                  : zoneCbg >= 40 && zoneCbg <= 49
                  ? bg3
                  : zoneCbg >= 30 && zoneCbg <= 39
                  ? bg4
                  : zoneCbg >= 20 && zoneCbg <= 29
                  ? bg5
                  : zoneCbg >= 10 && zoneCbg <= 19
                  ? bg6
                  : zoneCbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M210.048 409.458L93.8722 381.921C80.3488 420.587 72.999 462.13 72.999 505.377V560.912H199.528V481.081C199.528 456.185 203.207 432.141 210.048 409.458Z"
              // fill="#BDE407"
              fill={
                selectedZone.includes("F") || selectedAll ?
                zoneFbg >= 70
                  ? bg1
                  : zoneFbg >= 50 && zoneFbg <= 69
                  ? bg2
                  : zoneFbg >= 40 && zoneFbg <= 49
                  ? bg3
                  : zoneFbg >= 30 && zoneFbg <= 39
                  ? bg4
                  : zoneFbg >= 20 && zoneFbg <= 29
                  ? bg5
                  : zoneFbg >= 10 && zoneFbg <= 19
                  ? bg6
                  : zoneFbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="F"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M690.949 409.447C697.793 432.133 701.472 456.182 701.472 481.081V560.912H828.001V505.377C828.001 462.127 820.651 420.584 807.128 381.918L690.949 409.447Z"
              // fill="#BDE407"
              fill={
                selectedZone.includes("J") || selectedAll ?
                zoneJbg >= 70
                  ? bg1
                  : zoneJbg >= 50 && zoneJbg <= 69
                  ? bg2
                  : zoneJbg >= 40 && zoneJbg <= 49
                  ? bg3
                  : zoneJbg >= 30 && zoneJbg <= 39
                  ? bg4
                  : zoneJbg >= 20 && zoneJbg <= 29
                  ? bg5
                  : zoneJbg >= 10 && zoneJbg <= 19
                  ? bg6
                  : zoneJbg < 10
                  ? bg7
                  : "#7e8299" : "#7e8299"
              }
              id="J"
            />
          </svg>

          <div className="color-row">
            <div className="color">
              <span>COLOR</span>
              (%)
            </div>
            <div>
              <span className="color-1"></span>
              &gt; 70
            </div>
            <div>
              <span className="color-2"></span>
              50-69
            </div>
            <div>
              <span className="color-3"></span>
              40-49
            </div>
            <div>
              <span className="color-4"></span>
              30-39
            </div>
            <div>
              <span className="color-5"></span>
              20-29
            </div>
            <div>
              <span className="color-6"></span>
              10-19
            </div>
            <div>
              <span className="color-7"></span>
              &lt; 10
            </div>

            <div span={3}></div>
          </div>
          <div className="zone-score">
            <Button className="red-base-btn" onClick={selectAllZone}
            disabled={selectedZone.length === 12 || selectedAll}
            >
              Select All Zones
            </Button>
            <div className="selection-div">
              {selectedZone.length > 0 && (
                <span className="selection">
                  {selectedZone.length === 0 ? "All zone" : selectedZone.length}{" "}
                  selected
                </span>
              )}
              <span className="clear" onClick={clearZone}>
                Clear All Zones
              </span>
            </div>
            <div className="scores">
              <span>Selected Zone Total</span>
              <span className="total">
                {!selectedAll ? selectedTestTotal : testTotal}/
                {!selectedAll ? selectedZoneTotal : zoneTotal}
                {!selectedAll > 0 ? (
                  <>
                    (
                    {isNaN((selectedTestTotal / selectedZoneTotal) * 100)
                      ? 0
                      : ((selectedTestTotal / selectedZoneTotal) * 100).toFixed(
                          1
                        )}
                    %){" "}
                  </>
                ) : (
                  <>
                    (
                    {isNaN((testTotal / zoneTotal) * 100)
                      ? 0
                      : ((testTotal / zoneTotal) * 100).toFixed(1)}
                    %)
                    {/* ({(testTotal/zoneTotal* 100).toFixed(1)})%  */}
                  </>
                )}
              </span>
            </div>
          </div>

          <h2>BSA Score</h2>
          <Row gutter={16} className="score-row">
            <Col className="gutter-row" span={8}>
              <div style={style}>
                {" "}
                <p>
                  {profileDetail.bsa_detail
                    ? profileDetail.bsa_detail?.high_score_bsa_tests[0].score
                    : 0}
                </p>
                <span>BEST SCORE</span>
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div style={style}>
                {" "}
                <p>
                  {profileDetail.bsa_detail?.result_count > 1 && profileDetail.bsa_detail
                    ? profileDetail.bsa_detail?.avg_total_score
                    : "N/A"}
                </p>
                <span>AVG. SCORE</span>
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div style={style}>
                {" "}
                <p>
                  {profileDetail.bsa_detail
                    ? profileDetail.bsa_detail?.result_count
                    : 0}
                </p>
                <span>TOTAL SCORE</span>
              </div>
            </Col>
          </Row>
          <div className="submission-score-div">
            <div style={{ display: "flex", height: "65px" }}>
              <h2>Submission Score</h2>
              <div
                style={{
                  marginLeft: "auto",
                  padding: "20px 30px",
                  visibility: "hidden",
                }}
              >
                <Radio.Group
                  value={filter}
                  onChange={handleFilterChange}
                  className="
              calendar-radio"
                >
                  <Radio.Button value="day">Day</Radio.Button>
                  <Radio.Button value="week">Week</Radio.Button>
                  <Radio.Button value="month">Month</Radio.Button>
                </Radio.Group>
                <span className="vl"></span>
                <DatePicker
                  format={monthFormat}
                  picker="month"
                  className="month-picker"
                  suffixIcon={<img src={Calendar} />}
                  onChange={changePicker}
                />
              </div>
            </div>
            <Table
              columns={columns}
              rowSelection={false}
              pagination={false}
              rowKey={(obj) => obj.profile_test_id}
              expandable={{
                expandedRowRender: (record, index) => (
                  <div style={{ display: "flex", gap: 30 }} key={index}>
                    {/* <Col span={8}> */}
                    <Card
                      hoverable
                      className="video-card"
                      style={{ width: 236 }}
                      cover={
                        <>
                          <ReactPlayer
                            url={record.test_media.video_url}
                            controls
                            light={record.test_media.thumbnail}
                            playIcon={<img src={Play} />}
                            playing
                          />
                        </>
                      }
                    >
                      <Meta
                        title={getDateWithoutDay(record.test_time)}
                        description={record.score}
                      />
                    </Card>
                    <div style={{ width: "calc(100% - 236px)" }}>
                      <h3>Score Breakdown</h3>
                      <Row>
                        <Col span={12} style={{ paddingRight: 30 }}>
                          {record.bsa_breadown.shots.slice(0, 3).map((item) => (
                            <>
                              <div style={{ display: "flex" }}>
                                <h4>{item.name}</h4>
                                <h4
                                  style={{
                                    marginLeft: "auto",
                                    color: "#181c32",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item.attempted_shots ?  item.attempted_shots + "/"  + item.total_attempts : "N/A"}
                                </h4>
                              </div>
                              <hr style={{ margin: 0 }} />
                            </>
                          ))}
                        </Col>
                        <Col span={12}>
                          {record.bsa_breadown.shots.slice(3, 5).map((item) => (
                            <>
                              <div style={{ display: "flex" }}>
                                <h4>{item.name}</h4>
                                <h4
                                  style={{
                                    marginLeft: "auto",
                                    color: "#181c32",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item.attempted_shots ?  item.attempted_shots + "/"  + item.total_attempts : "N/A"}
                                </h4>
                              </div>
                              <hr style={{ margin: 0 }} />
                            </>
                          ))}
                        </Col>
                      </Row>
                    </div>
                  </div>
                ),
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <img
                      src={CollapseIcon}
                      onClick={(e) => onExpand(record, e)}
                    />
                  ) : (
                    <img src={Expand} onClick={(e) => onExpand(record, e)} />
                  ),
              }}
              dataSource={profileDetail.bsa_detail?.bsa_tests}
              className="score-table"
            />
          </div>
        </>
      )}
    </div>
  );
};
export default Overview;
