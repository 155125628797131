import React, { useContext, useEffect, useState } from "react";
import {
  Input,
  DatePicker,
  Table,
  Row,
  Col,
  Select,
  Divider,
  Dropdown,
  message,
} from "antd";
import Search from "../../../assets/images/search.svg";
import Calendar from "../../../assets/images/calendar.svg";
import dayjs from "dayjs";
import Filter from "../../../assets/images/filter.svg";
import DownArrow from "../../../assets/images/down-arrow.svg";
import User from "../../../assets/images/users.svg";
import { UserDetailContext } from "../../../context/user-module-context/user-detail-context";
import { UserContext } from "../../../context/user-context";
import { useNavigate } from "react-router-dom";
import EditProfileModal from "../edit-profile-modal/edit-profile-modal";
const { RangePicker } = DatePicker;
const dateFormat = "ddd. MMMM D, YYYY";
const UserProfile = () => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const navigate = useNavigate();
  const {
    allProfiles,
    totalProfile,
    profileEndDate,
    profileStartDate,
    search,
    pageNumber,
    setPageNumber,
    profileLoading,
    editProfile,
    showEditProfile,
    setSearch,
    setAllProfiles,
    getAllProfiles,
    deleteProfile,
    selectedProfile,
    getSingleProfile,
    setProfileStartDate,
    setProfileEndDate,
  } = useContext(UserDetailContext);
  const { accountDetail } = useContext(UserContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onSearch = (value) => console.log(value);
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== pageNumber) {
      setPageNumber(pagination.current);
    }
  };

  const items = [
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },
  ];
  const columns = [
    {
      title: "Customer",
      dataIndex: "customer",
      width: "30%",
      render: (text, record) => {
        return (
          <Row className="customer-div">
            <Col span={4}>
              <img src={User} />
            </Col>
            <Col span={20}>
              <span className="full-name">
                {record.first_name + " " + record.last_name}
              </span>
              <br />
              <span className="user-name">@{record.username}</span>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "SPORT(S)",
      dataIndex: "sports",
      render: (text, record) => {
        return (
          <div>
            {record.sports.length === 0 ? "N/A" : record.sports[0].name}
          </div>
        );
      },
    },
    {
      title: "DOB",
      dataIndex: "date_of_birth",
      render: (text, record) => {
        return <div>{record.date_of_birth ? record.date_of_birth : "N/A"}</div>;
      },
    },
    {
      title: "GENDER",
      dataIndex: "gender",
    },

    {
      title: "ACTIONS",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, key) => (
        <Dropdown
          placeholder="Actions"
          className="custom-dropdown"
          menu={{
            items,

            onClick: ({ key }) => {
              if (key === "delete") {
                // deleteRole(record.id);
                checkUserDeletePermission(record.id);
                // deleteProfile(record.id)
              } else {
                checkUserEditPermission(record);
                // setSelectedRole(record)
                // setEditRoleModal(true)
              }
            },
          }}
          overlayClassName="workout-dropdown-menu"
        >
          <a onClick={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
      ),
    },
  ];
  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      id: "232123",
      username: "@kenthompson",
      fullname: "Kenny Thompson",
      dob: "Aug 9, 2000",
      gender: "Male",
      sport: "3 (Basketball, Hockey, Soccer)",
    });
  }
  useEffect(() => {
    getAllProfiles();
  }, [profileEndDate, profileStartDate, search, pageNumber]);
  const info = () => {
    message.destroy();
    message.info("You don't have permissions. Contact to Admin");
  };
  const checkUserDeletePermission = (id) => {
    if (accountDetail.role !== null) {
      for (let item of accountDetail.role?.role_permission) {
        if (
          item.module.name === "Users" &&
          item.permission.code_name === "DELETE"
        ) {
          if (item.value === false) {
            info();
          } else {
            deleteProfile(id);
          }
        }
      }
    } else {
      deleteProfile(id);
    }
  };
  const checkUserEditPermission = (record) => {
    if (accountDetail.role !== null) {
      for (let item of accountDetail.role?.role_permission) {
        if (
          item.module.name === "Users" &&
          item.permission.code_name === "EDIT"
        ) {
          if (item.value === false) {
            info();
          } else {
            showEditProfile(true);
            getSingleProfile(record.id);
          }
        }
      }
    } else {
      showEditProfile(true);
      getSingleProfile(record.id);
    }
  };

  const changeRangePicker = (e) => {
    if (e) {
      setProfileStartDate(e[0].format("YYYY-MM-DD"));
      setProfileEndDate(e[1].format("YYYY-MM-DD"));
    } else {
      setProfileEndDate("");
      setProfileStartDate("");
    }
  };
  const searchProfile = (e) => {
    if (e.target.value.length >= 3) {
      setPageNumber(1);
      setAllProfiles([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setAllProfiles((allProfiles) => []);
      setSearch("");
    }
  };
  const checkUserPermission = () => {
    if (accountDetail.role !== null) {
      for (let item of accountDetail.role?.role_permission) {
        if (
          item.module.name === "Users" &&
          item.permission.code_name === "ADD"
        ) {
          if (item.value === false) {
            info();
          } else {
            navigate({
              pathname: `../create-profile`,
              search: `?id=${params.id}`,
            });
          }
        }
      }
    } else {
      navigate({
        pathname: `../create-profile`,
        search: `?id=${params.id}`,
      });
    }
  };
  return (
    <div className="user-profile">
      <h1>Profiles</h1>
      <Divider style={{ margin: 0 }} />
      <div className="table-div">
        <div style={{ display: "flex", padding: "26px 30px" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchProfile}
          />
          <div className="right-div">
            <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              onChange={changeRangePicker}
            />

            <button className="red-base-btn">
              <img src={Filter} alt="filter" />
            </button>
            <button className="dark-base-btn" onClick={checkUserPermission}>
              Create Profile
            </button>
          </div>
        </div>
        <Divider style={{ margin: 0 }} />
        <Table
          // rowSelection={rowSelection}
          onChange={handleTableChange}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            // current: 1,
            pageSize: 10,
            total: totalProfile,
            showQuickJumper: false,
          }}
          loading={profileLoading}
          columns={columns}
          dataSource={allProfiles}
          className="table-container"
        />
      </div>
      <EditProfileModal
        editProfile={editProfile}
        showEditProfile={showEditProfile}
      />
    </div>
  );
};
export default UserProfile;
