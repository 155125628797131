import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Checkbox,
  DatePicker,
  Radio,
  Select,
  Space,
  Upload,
  message,
} from "antd";
import "./edit-general-details.scss";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Calendar from "../../assets/images/calendar.svg";
import Profile from "../../assets/images/avatar.svg";
import dayjs from "dayjs";
import Edit from "../../assets/images/edit.svg";
import Close from "../../assets/images/close.svg";
const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};
const formTailLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
    //   offset: 4,
  },
};
const EditGeneralDetails = ({ showEditProfile }) => {
  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const dateFormat = "ddd. MMMM D, YYYY";
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);
  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
    } catch (errorInfo) {
    }
  };
  const onSearch = (value) => {}
  const onChangeSelect = (value) => {
  };
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpeg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/JPEG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  return (
    <div className="edit-general-details-main">
      <h1>General Details</h1>
      <hr />
      <Form
        form={form}
        name="dynamic_rule"
        style={{
          padding: "22px 30px ",
        }}
        className="details-form"
      >
        <Form.Item
          {...formItemLayout}
          name="profile-photo"
          label="Profile Photo"
          className="img-div"
        >
          <div className="image-previewer">
            <img src={Profile} alt="profile" />
            <Upload
              name="avatar"
              className="avatar-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                <img src={Edit} alt="edit"></img>
              )}
            </Upload>
            <img src={Close} alt="close" />
          </div>
          <span className="img-cond">Allowed file types: PNG, JPG, JPEG.</span>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="fullname"
          label="Full Name"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
          <Input
            placeholder="Please input your name"
            defaultValue={"Lucy Kunic"}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="dob"
          label="Date of Birth"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
          <DatePicker
            className="date-picker"
            defaultValue={dayjs("Jan 01, 2022", dateFormat)}
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
          />
          {/* <Input placeholder="Please input your name" defaultValue={"Lucy Kunic"}/> */}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="gender"
          label="Gender"
          className="gender-div"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
          <Radio.Group
            onChange={onChange}
            value={value}
            defaultValue={value}
            className="custom-radio"
          >
            <Radio value={1}>Male</Radio>
            <Radio value={2}>Female</Radio>
            <Radio value={3}>Undisclosed</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label="Measurements"
          style={{
            marginBottom: 0,
          }}
          className="measurements-input"
        >
          <div style={{ display: "flex" }}>
            <label>Height:</label>
            <Form.Item
              name="feet"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input addonBefore={"ft"} defaultValue="6" />
            </Form.Item>
            <Form.Item
              name="inch"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input addonBefore={"in"} defaultValue="3" />
            </Form.Item>
            <Form.Item
              name="dec"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input addonBefore={"dec"} defaultValue="0.325" />
            </Form.Item>
          </div>
          <div className="weight-div">
            {/* <label>Weight:</label> */}

            <Form.Item
              label="Weight:"
              style={{
                marginBottom: 0,
              }}
            >
              <Form.Item
                name="lbs"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input addonBefore={"lbs"} defaultValue="160" />
              </Form.Item>
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          className="location-div"
          label="Location"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your name",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a state"
              optionFilterProp="children"
              onChange={onChangeSelect}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              className="location-select"
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your name",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a city"
              optionFilterProp="children"
              onChange={onChangeSelect}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              className="location-select"
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>

          {/* <Select
            showSearch
            placeholder="Select a state"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            className="location-select"
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          /> */}
        </Form.Item>
        <h2>Sports</h2>
        <Form.Item
          {...formItemLayout}
          name="sport"
          label="Sport"
          className="sport-div"
        >
          <Checkbox.Group>
            <Checkbox value="basketball">Basketball</Checkbox>
            <Checkbox value="hockey">Hockey</Checkbox>
            <Checkbox value="soccer">Soccer</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          className="skills-div"
          label="Skill Level"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your name",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Level 2: Intermediate (age 11-15)"
              optionFilterProp="children"
              onChange={onChangeSelect}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              className="skill-select"
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="shooting-hand"
          label="Shooting Hand"
          className="shooting-div"
        >
          <Radio.Group
            onChange={onChange}
            value={value}
            defaultValue={value}
            className="custom-radio"
          >
            <Radio value={1}>Right</Radio>
            <Radio value={2}>Left</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="positions"
          label="Positions"
          className="sport-div position-div"
        >
          <Checkbox.Group className="position-checkbox">
            <Checkbox value="pg">PG</Checkbox>
            <Checkbox value="sg">SG</Checkbox>
            <Checkbox value="sf">SF</Checkbox>
            <Checkbox value="pf">PF</Checkbox>
            <Checkbox value="c">C</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <h2>Subscription</h2>

        <Form.Item
          {...formItemLayout}
          name="subscription-status"
          label="Subscription Status"
          className="subscription-div"
        >
          <span className="green-tag">Active</span>
          <span className="manage-subscription">Manage Subscription</span>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="bsa-credit"
          label="BSA Credit"
          className="bsa-credit-div"
        >
          <Radio.Group
            onChange={onChange}
            value={value}
            defaultValue={value}
            className="custom-radio"
          >
            <Radio value={1}>No BSA Credit</Radio>

            <Radio value={2}>Add one-time credits </Radio>
            {value === 2 ? (
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                onChange={onChangeSelect}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                className="location-select"
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            ) : null}
            <Radio value={3}>
              Add credit each month (on “selected” start day)
            </Radio>
            {value === 3 ? (
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                onChange={onChangeSelect}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                className="location-select"
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            ) : null}
          </Radio.Group>
        </Form.Item>
        <Row className="delete-row">
          <Col span={10} className="label-col">
            <h3>Delete Profile</h3>
          </Col>
          <Col span={14} className="data-col">
            <span className="orange-tag">Delete Profile</span>
          </Col>
        </Row>
        <hr style={{ margin: "56px -30px 30px" }} />

        <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
          <Button
            htmlType="submit"
            type="primary"
            className="discard-btn"
            onClick={() => showEditProfile(false)}
          >
            Discard
          </Button>
          <Button
            htmlType="button"
            style={{
              margin: "0 8px",
            }}
            className="dark-base-btn"
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default EditGeneralDetails;
