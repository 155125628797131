import React, { useContext, useEffect, useState } from "react";
import { Input, Modal, Form, Upload, Button, ColorPicker } from "antd";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import { SportContext } from "../../../context/setting-context/sports_context";
const { TextArea } = Input;

const AddSportModal = () => {
  const {
    addSport,
    selectedColor,
    setSelectedColor,
    addSportModal,
    setAddSportModal,
    imgSrc,
    setImgSrc,
    setImageUrl,
  } = useContext(SportContext);
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    setImageUrl();
    setImgSrc();
    setSelectedColor("");
    setAddSportModal(false);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  };
  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  useEffect(() => {
    if (!addSportModal) {
      handleCancel();
    }
  }, [addSportModal]);
  return (
    <Modal
      wrapClassName="add-sport-modal"
      className="add-sport-modal"
      open={addSportModal}
      onOk={addSport}
      onCancel={handleCancel}
      okText={"Create"}
      cancelText={"Cancel"}
      width={"624px"}
      footer={false}
    >
      <div className="add-sport-main">
        <h1>Add New Sport</h1>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="add-sport-form"
          onFinish={addSport}
        >
          <Form.Item name="avatar" label="Profile Photo" className="img-div">
            <div className="image-previewer">
              {!imgSrc ? (
                <Upload
                  className="uploader"
                  showUploadList={false}
                  type="file"
                  customRequest={dummyRequest}
                  beforeUpload={handleBeforeUpload}
                >
                  Upload pic
                </Upload>
              ) : (
                <>
                  <img
                    src={imgSrc}
                    alt="avatar"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="img"
                  />
                  <Upload
                    className="avatar-uploader"
                    showUploadList={false}
                    type="file"
                    customRequest={dummyRequest}
                    beforeUpload={handleBeforeUpload}
                  >
                    <img src={Edit} alt="edit" className="edit"></img>
                  </Upload>
                  <img
                    src={Close}
                    alt="close"
                    className="delete"
                    onClick={removeImage}
                  />
                </>
              )}
            </div>
          </Form.Item>
          <span className="img-cond">Allowed file types: PNG, JPG, JPEG.</span>
          <Form.Item
            name="name"
            label="Sport Name"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input placeholder="Please enter sports name"/>
          </Form.Item>
          <Form.Item
            name="color"
            label="Select a color "
            rules={[
              {
                required: false,
              },
            ]}
          >
            <ColorPicker
              // format="formatHex"
              onChangeComplete={(color) => setSelectedColor(color)}
              showText={(color) => (
                <span className="custom-placeholder">
                  {" "}
                  {selectedColor !== ""
                    ? selectedColor.toHexString()
                    : " Pick a color or enter hex value e.g. #000000"}
                </span>
              )}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea rows={4} placeholder="Please enter description"/>
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button type="default" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="dark-base-btn"
              type="primary"
              style={{ marginInlineStart: 8 }}
              htmlType="submit"
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddSportModal;
