import React, { useState, useRef, useContext, useEffect } from "react";
import { Row, Col, Tabs, Select, Card, Empty } from "antd";
import "./submissions.scss";
import Video from "../../../assets/Snail.mp4";
import Play from "../../../assets/images/play.svg";
import ReactPlayer from "react-player";
import DownArrow from "../../../assets/images/arrows.svg";
import { UserDetailContext } from "../../../coach-context/user-module-context/user-detail-context";
import {
  getDateWithTime,
  getDateWithTimeWithoutDay,
} from "../../../util/config";

const Submissions = () => {
  const { getProfileBsa, bsaSubmissions ,  status , sort , 
    setStatus , setSort } = useContext(UserDetailContext);
  const [playButton, setPlayButton] = useState(true);
  const [selectedTab, setSelectedTab] = useState("1");
  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    setPlayButton(false);
  };
  const style = {
    padding: "5px 0",
  };
  const onChange = (key) => {
    setSelectedTab(key);
  };
  const onChangeSelect = (value) => {
    setSort(value)
  };
  const onChangeStatus = (value) => {
    setStatus(value)
  }
  useEffect(() => {
    getProfileBsa();
  }, [sort , status]);
  const items = [
    {
      key: "1",
      label: `BSA`,
      //   children: `Content of Tab Pane 1 Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1Content of Tab Pane 1`,
      // children: <Profile />
    },
    // {
    //   key: "2",
    //   label: `Challenges`,
    //   //   children: `Content of Tab Pane 2`,
    // },
  ];
  return (
    <div className="submission-main">
      <h1>Submissions</h1>

      <div style={{ position: "relative" }}>
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="submission-tabs"
        />
        <div style={{ position: "absolute", bottom: 25, right: 30 }}>
          <span className="status-heading">Status</span>
          <Select
            placeholder="All"
            optionFilterProp="children"
            onChange={onChangeStatus}
            suffixIcon={<img src={DownArrow} />}
            className="select-1"
            options={[
              {
                value : "",
                label : "All"
              },
              {
                value: "pending",
                label: "Pending",
              },
              {
                value: "final",
                label: "Completed",
              },
              {
                value: "rejected",
                label: "Rejected",
              },
            ]}
          />
          {/* {selectedTab === "2" && (
            <>
              <span className="status-heading">By Task</span>
              <Select
                placeholder="Beat Desmont..."
                optionFilterProp="children"
                onChange={onChangeSelect}
                suffixIcon={<img src={DownArrow} />}
                className="select-1"
                style={{ width: "auto" }}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            </>
          )} */}
                    <Select
            placeholder="Newly Added"
            suffixIcon={<img src={DownArrow} />}
            onChange={onChangeSelect}
            className="select-input select-2"
            value={sort}
            options={[
              {
                value: "asc",
                label: "Oldest",
              },
              {
                value: "desc",
                label: "Newest",
              },
            ]}
          />
        </div>
      </div>
      <Row gutter={16} className="groups-row">
        {bsaSubmissions?.length > 0 ? (
          bsaSubmissions.map((item, index) => (
            <Col
              className="gutter-row"
              span={8}
              style={{ paddingBottom: 16 }}
              key={index}
            >
              <div style={style} className="div-1">
                <Card
                  hoverable
                  className="video-card"
                  cover={
                    <>
                      <div
                        className={
                          "status " +
                          (item.status === "pending"
                            ? "yellow-tag"
                            : item.status === "rejected"
                            ? "pink-tag"
                            : "green-tag")
                        }
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.status === "final" ? "Completed" : item.status}
                      </div>

                      <ReactPlayer
                        ref={vidRef}
                        url={item.video_url}
                        controls
                        playing
                        width={"100%"}
                        height={"100%"}
                        light={item.thumbnail}
                        playIcon={<img src={Play} style={{ width: 35 }} />}
                      />
                    </>
                  }
                >
                  <div>
                    <div className="test-id">
                      Test ID: <span>{item.id}</span>
                    </div>
                    <div className="score">
                      Score: <span>{item.status === "pending" ? "N/A" : item.test_score}</span>
                    </div>
                  </div>
                  <div className="date">
                    Submitted:{" "}
                    <span>{getDateWithTimeWithoutDay(item.submitted_on)}</span>
                  </div>
                </Card>
              </div>
            </Col>
          ))
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: "10% auto" }}
          />
        )}
      </Row>
    </div>
  );
};
export default Submissions;
