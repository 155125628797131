import React, { useContext, useEffect, useState } from "react";
import "./individual-profile-detail.scss";
import { Col, Row, Menu, Tabs, Button, Skeleton } from "antd";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import Overview from "../overview/overview";
import Submissions from "../submissions/submissions";
import ProfileWorkoutDetail from "../profile-workout-detail/workout-detail"
import SportModal from "../sport-modal/sport-modal";
import Workouts from "../workouts/workouts/workouts";
import { getDateWithoutDay } from "../../../util/config";
import { UserDetailContextProvider , UserDetailContext } from "../../../coach-context/user-module-context/user-detail-context";
const IndividualProfileDetail = () => {
  const navigate = useNavigate();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [activeKey , setActiveKey] = useState("overview")
  const {viewSubmissions , setViewSubmissions , profileDetail} = useContext(UserDetailContext)
  const [sportModal, setSportModal] = useState(false);
  const [imageLoaded, setImageLoaded] = useState("");
  const [workoutDetail, setWorkoutDetail] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState({});
  const [groupOwner , setGroupOwner] = useState(false)

  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }
  const items = [
    getItem("Overview", "overview", <Overview profileDetail={profileDetail} />),
    params.profile_type !== "owner" &&
    getItem(
      "Workouts",
      "workouts",
        workoutDetail || params.tab === "submission-detail" ? (
          <ProfileWorkoutDetail setWorkoutDetail={setWorkoutDetail} selectedWorkout={selectedWorkout} setViewSubmissions={setViewSubmissions} viewSubmissions={viewSubmissions} 
           />
        ) : (
        <Workouts
          setWorkoutDetail={setWorkoutDetail}
          setSelectedWorkout={setSelectedWorkout}
          setGroupOwner={setGroupOwner}
          groupOwner={groupOwner}
          selectedWorkout={selectedWorkout}
          workoutDetail={workoutDetail}
        />
        )
    ),
    getItem("Submissions", "submissions", <Submissions />),
  ];
  // React.useEffect(() => {
  //   if (params.tab === "workouts") {
  //     navigate({
  //       search: `?id=${profileDetail.id}`,
  //     });
  //   }
  // }, [params]);
  useEffect(()=>{
    if(activeKey === "overview" && profileDetail?.length !== 0 && params.tab === "submission-detail"){
      navigate({
        search: `profile_type=${params.profile_type}&id=${params.id}&profile_id=${params.profile_id}`,
      });
    }
  }, [profileDetail])
  const onChange = (value) => {
    setActiveKey(value)
    setWorkoutDetail(false)
    setViewSubmissions(false)
    if (params.tab === "submission-detail") {
      navigate({
        search: `profile_type=${params.profile_type}&id=${params.id}&profile_id=${params.profile_id}`,
      });
    }
  };
  const gameItems = [
    {
      key: "basketball",
      label: "Basketball",
      //   children: 'Content of Tab Pane 1',
    },
    // {
    //   key: "baseball",
    //   label: "Baseball",
    //   //   children: 'Content of Tab Pane 2',
    // },
    // {
    //   key: "football",
    //   label: "Football",
    //   //   children: 'Content of Tab Pane 3',
    // },
    // {
    //   key: "rugby",
    //   label: "Rugby",
    // },
  ];

  return (
    <div className="user-account-main">
      <div className="user-detail">
        <div style={{ width: 90 }}>
          {profileDetail.avatar !== null &&
            profileDetail.avatar !== "" &&
            imageLoaded === "" && (
              <Skeleton.Image
                active={true}
                style={{ width: "90px", height: "90px" }}
              />
            )}

          {profileDetail.avatar === null || profileDetail.avatar === "" ? (
            <Avatar
              name={profileDetail.first_name + " " + profileDetail.last_name}
              color="#F15A29"
              maxInitials={2}
              className="group-avatar"
            />
          ) : (
            <img
              src={profileDetail.avatar}
              className="group-logo"
              style={
                imageLoaded === "" ? { display: "none" } : { display: "block" }
              }
              onLoad={() => setImageLoaded("loaded")}
            />
          )}
        </div>
        <Row style={{ width: "100%" }}>
          <Col span={24} style={{ paddingLeft: 24 }}>
            <div className="name-handle">
              <h2 className="user-name">@{profileDetail.username}</h2>
              <div className="vl"></div>
              <span>ID : #{profileDetail.id}</span>
            </div>
            <div className="added-on">
              <span>Added on:</span>
              <p className="date">
                {getDateWithoutDay(profileDetail.created_at)}
              </p>
            </div>
            <Row gutter={[16, 16]} className="points-row">
              <Col
              span={3}
              >
                <p className="profile-label">Full Name</p>
                <p className="profile-value"    
                title={Object.keys(profileDetail).length > 0 ? profileDetail?.first_name + " " + profileDetail?.last_name : "N/A"}           
                style={{                    
                whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>
                  {Object.keys(profileDetail).length > 0 ? profileDetail?.first_name + " " + profileDetail?.last_name : "N/A"}
                </p>
              </Col>
              <Col span={3}>
                <p className="profile-label">Date of Birth</p>
                <p className="profile-value">
                  {profileDetail.date_of_birth === "" || profileDetail.date_of_birth === undefined ? "N/A" :  getDateWithoutDay(profileDetail.date_of_birth)}
                </p>
              </Col>
              <Col 
              span={3}
              >
                <p className="profile-label">Gender</p>
                <p className="profile-value">{profileDetail.gender}</p>
              </Col>
              <Col 
              span={2}
              >
                <p className="profile-label">Height</p>
                <p className="profile-value">
                  {profileDetail.height ? profileDetail.height : "N/A"}
                </p>
              </Col>
              <Col 
              span={2}
              >
                <p className="profile-label">Weight</p>
                <p className="profile-value">
                  {profileDetail.weight ? profileDetail.weight : "N/A"}
                </p>
              </Col>
              <Col 
              span={4}
               className="location-col">
                <p className="profile-label">Location</p>
                <p
                  className="profile-value"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 200,
                  }}
                  title={
                    profileDetail.location &&
                    profileDetail.location !== "undefined"
                      ? profileDetail.location
                      : "N/A"
                  }
                >
                  {profileDetail.location &&
                  profileDetail.location !== "undefined"
                    ? profileDetail.location
                    : "N/A"}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ position: "relative" }}>
        <Tabs
          items={gameItems}
          onChange={onChange}
          className="game-tabs"
          activeKey={activeKey}
          // defaultActiveKey={params.tab && params.tab}
          destroyInactiveTabPane={true}
        />
        {/* <Button
          icon={<img src={Add} />}
          className="add-sport"
          onClick={() => setSportModal(true)}
        >
          Add Another Sport
        </Button> */}
      </div>
      <div className="user-data">
        <Tabs
          tabPosition="left"
          onChange={onChange}
          type="card"
          className="user-tabs-coach"
          items={items}
          destroyInactiveTabPane={true}
        />
      </div>
      <SportModal sportModal={sportModal} setSportModal={setSportModal} />
    </div>
  );
};
const UserInvidualProfile = () => {
  return (
    <UserDetailContextProvider>
      <IndividualProfileDetail />
    </UserDetailContextProvider>
  );
};
export default UserInvidualProfile;
