import React, { useContext } from "react";
import { Row , Col , Input, Button, Divider, message } from "antd";
import Phone from "../../../assets/images/devices.svg"
import Mail from "../../../assets/images/communication.svg"
import EditUserModal from "../edit-user/edit-user";
import { UserDetailContext } from "../../../context/user-module-context/user-detail-context";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/user-context";
const UserOverview = ({userData }) =>{
    const {accountDetail} = useContext(UserContext)
    const navigate = useNavigate();
    const {deleteUser} = useContext(UserDetailContext)
    const info = () => {
        message.info("You don't have permissions. Contact to Admin");
      };
      const checkUserDeletePermission = () => {
        if(accountDetail.role !== null){
        for (let item of accountDetail.role?.role_permission) {
            if(item.module.name === "Users" && item.permission.code_name === "DELETE"){
              if(item.value === false){
                info();
              }
              else{
               deleteUser();
              }
            }
        }
          }else{
            deleteUser();
  
          }
    }
    const checkUserPermission = () => {
        if(accountDetail.role !== null){
        for (let item of accountDetail.role?.role_permission) {
            if(item.module.name === "Users" && item.permission.code_name === "EDIT"){
              if(item.value === false){
                info();
              }
              else{
                navigate({
                    pathname: `../edit-user`,
                    search: `?id=${userData.id}`,
                  })         
              }
            }
        }
          }else{
            navigate({
                pathname: `../edit-user`,
                search: `?id=${userData.id}`,
              })         
          }
    }
     return(
        <div className="user-overview">
            <Row>
        <h1>Overview</h1>
        <Button className="dark-base-btn" onClick={
            checkUserPermission
            // navigate(`../edit-user`)
        }
            >Edit</Button>
        </Row>
        <Divider style={{margin:0}}/>
        <Row className="user-data-overview">
        <Col span={8} className="label-col" style={{marginBottom:"32px"}}>
                <h3 style={{padding:"16px 0"}}>Email Address</h3>
            </Col>
            <Col span={16} className="data-col">
            <Input addonBefore={<img src={Mail} />} value={userData.email} disabled/>

            </Col>
        <Col span={8} className="label-col">
                <h3 style={{padding:"16px 0"}}>Phone Number</h3>
            </Col>
            <Col span={16} className="data-col">
            <Input addonBefore={<img src={Phone} />} value={userData.phone} disabled/>

            </Col>
        {/* <Col span={24} style={{margin:"80px 0 39px 0"}}>
            <h2>Subscription</h2>
            </Col>
            <Col span={8} className="label-col">
                <h3>Subscription Status</h3>
            </Col>
            <Col span={16} className="data-col">
                <div>
                    <span className="green-tag">Active</span>
                    <span className="manage-subscription">Manage Subscription</span>
                </div>
            </Col> */}
            <Col span={24} style={{margin:"71px 0px 43px"}}>
            <h2>Delete Account</h2>
            </Col>
            <Col span={8} className="label-col">
                <h3 >Delete Account</h3>
            </Col>
            <Col span={16} className="data-col">
                <span className="red-tag-btn" onClick={()=>checkUserDeletePermission()} style={{cursor:"pointer"}}>Delete Account</span>
            </Col>
        </Row>
        {/* <EditUserModal
        editUserModal={editUserModal}
        setEditUserModal={setEditUserModal}
      /> */}
        </div> 
     )
}
export default UserOverview