import React, { useRef, useEffect, useState } from "react";
import "./workouts-detail.scss";
import { Card, Col, Collapse, Progress, Row, Tabs } from "antd";
import Expand from "../../assets/images/expand.svg";
import CollapseIcon from "../../assets/images/collapse.svg";
import ApexCharts, { ApexOptions } from "apexcharts";
import { FaArrowLeftLong } from "react-icons/fa6";
import Video from "../../assets/Snail.mp4";
import Play from "../../assets/images/play.svg";
import Player from "../Player/Player";
import ReactPlayer from "react-player";
import { getDataTraining } from "../../util/api-calls";
import { getDateWithTimeWithoutDay, getDate } from "../../util/config";
import Tasks from "../Groups/workout-detail/tasks";
let chartColor = "",
  chartHeight = "";
const style = {
  padding: "5px 0",
};
const WorkoutDetails = ({
  setWorkoutDetail,
  workoutDetail,
  selectedWorkout,
  setGroupOwner,
  groupOwner,
  setSelectedWorkout,
}) => {
  const { Panel } = Collapse;
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  const [ownerWorkoutDetail, setOwnerWorkoutDetail] = useState({});
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const onChange = (key) => {
  };
  const getProfileWorkoutDetail = () => {
    getDataTraining(`admin/profile/${params.id}/workout/${selectedWorkout.id}`)
      .then((res) => {
        setWorkoutDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getOwnerWorkouts = () => {
    getDataTraining(`admin/workout/${selectedWorkout.id}`)
      .then((res) => {
        setOwnerWorkoutDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (groupOwner === false) {
      getProfileWorkoutDetail();
    } else {
      getOwnerWorkouts();
    }
  }, []);
  const tabItems = [
    {
      key: "1",
      label: `Tasks`,
      children: <Tasks tasks={ownerWorkoutDetail.tasks} />,
    },
  ];
  return (
    <div className="workout-details-main">
      {groupOwner === false ? (
        <>
          <div className="heading-row">
            <FaArrowLeftLong
              className="left-arrow"
              style={{ margin:"33px 0 8px 23px" }}
              onClick={() => {
                setWorkoutDetail(false);
                setSelectedWorkout({});
              }}
            />
            <h1>{workoutDetail.title}</h1>
          </div>
          {/* <span className="bread-crumb">Challenges - Shooting License</span> */}
          <hr />
          <Row gutter={16} className="graph-row">
            <Col className="gutter-row right-col" span={12}>
              <div style={style} className="div-1">
                <h2>Workout Information</h2>
                <div className="pending-tasks">
                  {workoutDetail.reps_done}/
                  <span>{workoutDetail.total_reps} reps</span>
                </div>
                <div className="percentage">
                  {workoutDetail.completion_percentage}%
                </div>

                <Progress
                  percent={workoutDetail.completion_percentage}
                  showInfo={false}
                  className="orange-progress-bar"
                />

                <Row
                  gutter={16}
                  className="inner-row"
                  style={{ marginBottom: 10 }}
                >
                  <Col className="gutter-row left-col" span={12}>
                    <div style={style} className="div-1">
                      <h2>{workoutDetail.total_tasks}</h2>
                      <span>Total Tasks</span>
                    </div>
                  </Col>
                  <Col className="gutter-row right-col" span={12}>
                    <div style={style} className="div-1">
                      <h2>{workoutDetail.total_reps}</h2>
                      <span>Total Reps</span>
                    </div>
                  </Col>
                </Row>

                {/* <Row gutter={16} className="inner-row" style={{ marginTop: 10 }}>
              <Col className="gutter-row left-col" span={24}>
                <div style={style} className="div-1">
                  <h2>{workoutDetail.assignee}</h2>
                  <span>Members Doing These Tasks</span>
                </div>
              </Col>
            </Row> */}
                <div className="end-date">
                  Ends:
                  <span>{getDate(workoutDetail.end_date)}</span>
                </div>
              </div>
            </Col>
          </Row>
          <h2 className="task-heading">Tasks</h2>
          <Collapse
            onChange={onChange}
            expandIconPosition="end"
            expandIcon={({ isActive }) =>
              !isActive ? <img src={Expand} /> : <img src={CollapseIcon} />
            }
            className="collapsible-rows"
          >
            {workoutDetail.tasks?.map((item, index) => {
              return (
                <Panel
                  key={index}
                  header={
                    <div
                      className="collapse-label"
                      style={{ textTransform: "capitalize" }}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col span={18} style={{ fontSize: 16 }}>
                          {item.title} ({item.skill_level})
                          <span>
                            {item.scored_task ? "Scored Task" : "Training Task"}
                          </span>
                        </Col>
                        <Col span={3}>
                          {" "}
                          {item.is_competed === true ? (
                            <div className="status">Completed</div>
                          ) : item.is_competed === false &&
                            item.completion_percentage === 0 ? (
                            <button
                              className="yellow-tag"
                              style={{ marginTop: "-3px" }}
                            >
                              Pending
                            </button>
                          ) : (
                            <Progress
                              percent={item.completion_percentage}
                              showInfo={false}
                              className="orange-progress-bar"
                            />
                          )}
                        </Col>
                        <Col span={3}>
                          <div className="reps">
                            Reps:{" "}
                            <span>
                              {item.reps_done}/{item.total_reps}
                            </span>
                          </div>
                        </Col>
                        {/* <Col span={2}>
                      <div className="points">
                        Points: <span>{item.score}</span>
                      </div>
                    </Col> */}
                      </Row>
                    </div>
                  }
                >
                  <div className="task-data">
                    <h4>
                      You have completed {item.reps_done} of your{" "}
                      {item.total_reps} reps.
                    </h4>
                    <Row gutter={16} className="task-row">
                      {item.submissions.map((detail, index) => (
                        <Col
                          className="gutter-row"
                          span={6}
                          key={index}
                          style={{ padding: "8px" }}
                        >
                          <div className="div-1">
                            <Card
                              hoverable
                              className="video-card"
                              cover={
                                // <>
                                //   {playButton && (
                                //     <button className="play-btn" onClick={handlePlayVideo}>
                                //       <img src={Play} />
                                //     </button>
                                //   )}
                                //   <video ref={vidRef}>
                                //     <source src={Video} type="video/mp4" />
                                //   </video>
                                // </>
                                detail.media_thumbnail === null ? 
                                <div className="no-video-task"></div>
                                :
                                <ReactPlayer
                                  url={detail.task_media}
                                  light={detail.media_thumbnail}
                                  controls
                                  width={"100%"}
                                  height={"100%"}
                                  style={{ objectFit: "cover" }}
                                />
                              }
                            >
                              <div className="date">
                                Submitted:{" "}
                                <span>{getDate(detail.created_at)}</span>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        </>
      ) : (
        <>
          <div className="heading-row">
            <FaArrowLeftLong
              className="left-arrow"
              style={{ margin:"33px 0 8px 23px" }}
              onClick={() => {
                setWorkoutDetail(false);
                setSelectedWorkout({});
              }}
            />
            <h1>{ownerWorkoutDetail.title}</h1>
          </div>
          {/* <span className="bread-crumb">Challenges - Shooting License</span> */}
          <hr />
          <Row gutter={16} className="graph-row">
            <Col className="gutter-row left-col" span={16}></Col>
            <Col className="gutter-row right-col" span={12}>
              <div style={style} className="div-1">
                <h2>Workout Information</h2>
                {/* <div className="pending-tasks">
                  {workoutDetail.tasks_completed}/
                  <span>{workoutDetail.total_tasks} tasks</span>
                </div>
                <div className="percentage">
                  {workoutDetail.completion_percentage}%
                </div>
    
                <Progress
                  percent={workoutDetail.completion_percentage}
                  showInfo={false}
                  className="orange-progress-bar"
                />
     */}
                <Row
                  gutter={16}
                  className="inner-row"
                  style={{ marginBottom: 10 }}
                >
                  <Col className="gutter-row left-col" span={12}>
                    <div style={style} className="div-1">
                      <h2>{ownerWorkoutDetail.total_tasks}</h2>
                      <span>Total Tasks</span>
                    </div>
                  </Col>
                  <Col className="gutter-row right-col" span={12}>
                    <div style={style} className="div-1">
                      <h2>{ownerWorkoutDetail.total_reps}</h2>
                      <span>Total Reps</span>
                    </div>
                  </Col>
                </Row>

                <Row
                  gutter={16}
                  className="inner-row"
                  style={{ marginTop: 10 }}
                >
                  <Col className="gutter-row left-col" span={24}>
                    <div style={style} className="div-1">
                      <h2>{ownerWorkoutDetail.assignee}</h2>
                      <span>Members Doing These Tasks</span>
                    </div>
                  </Col>
                </Row>
                <div className="end-date">
                  Ends:
                  <span>{getDate(ownerWorkoutDetail.end_date)}</span>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Tabs
            defaultActiveKey="1"
            items={tabItems}
            onChange={onChange}
            className="workout-detail-tabs"
          /> */}
        </>
      )}
    </div>
  );
};
export default WorkoutDetails;
