import React, { useContext, useState } from "react";
import { Modal, Input, Radio, Form, Upload, message, Select } from "antd";
import "./delete-workout-modal.scss";
import { deleteItemTraining } from "../../../util/api-calls";
import { WorkoutContext } from "../../../context/challenge-workout-context/workout-context";
const DeleteWorkoutModal = ({
  deleteWorkoutModal,
  setDeleteWorkoutModal,
  selectedWorkout,
  setSelectedWorkout,
}) => {
  const { getAllWorkouts } = useContext(WorkoutContext);
  const handleOk = () => {
    setDeleteWorkoutModal(false);
  };

  const deleteWorkout = () => {
    deleteItemTraining(`library/workout/${selectedWorkout.id}`)
      .then((res) => {
        setDeleteWorkoutModal(false);
        setSelectedWorkout({});
        getAllWorkouts();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal
      className="delete-workout-modal"
      open={deleteWorkoutModal}
      onCancel={handleOk}
      onOk={deleteWorkout}
      cancelText={"No, Keep This Workout"}
      okText={"Yes, Delete This Workout"}
      width={"608px"}
    >
      <h1>Delete Workout</h1>
      <p>Are you sure you want to delete this workout? </p>
    </Modal>
  );
};

export default DeleteWorkoutModal;
