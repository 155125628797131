import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Radio,
  Select,
  Upload,
  message,
  Divider,
  DatePicker,
  Skeleton,
  InputNumber,
  Col,
  Row,
  Switch,
} from "antd";
import "./edit-group-general-details.scss";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import Avatar from "react-avatar";
import {
  GroupDetailContextProvider,
  GroupDetailContext,
} from "../../../coach-context/group-context/group-detail-context";
import dayjs from "dayjs";
import SubscriptionHistory from "../subscription-history/subscription-history";

const formItemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};
const EditGroupGeneralDetails = ({ setGeneralDetail, cgList }) => {
  const [form] = Form.useForm();
  const {
    generalDetail,
    type,
    setSeatLimit,
    seatLimit,
    setMemberLimit,
    memberLimit,
    imgSrc,
    bsaCredit,
    setImgSrc,
    setImageUrl,
    setSport,
    setBsaCredit,
    setType,
    level,
    setLevel,
    sportsList,
    postEditGroup,
    workoutLeaderBoard,
    bsaLeaderBoard,
    combineLeaderBoard,
    bsaValue,
    setBsaValue,
    bsaValueOneTime,
    bsaValueEachMonth,
    setWorkoutLeaderBoard,
    setBsaLeaderBoard,
    setCombineLeaderBoard,
    setSubscriptionStatus,
    subscriptionStatus,
    setNewSubscription,
    setSubscriptionHistory,
    subscriptionHistory,
    newSubscription,
    tags,
    setTags,
    setLoading,
    access,
    setAccess,
    setBsaValueEachMonth,
    setBsaValueOneTime, setCurriculum, curriculum,
    imageError, setImageError, cgListGroup, setCgListGroup
  } = useContext(GroupDetailContext);
  const [imageLoaded, setImageLoaded] = useState("");
  const onChangeCgList = (e) => {
    if(cgListGroup.includes(e.target.value)) {
      setCgListGroup(cgListGroup.filter((item) => item !== e.target.value));
    }else{
   setCgListGroup([...cgListGroup, e.target.value]);
    }
  };
  useEffect(() => {
    for (let i = 0; i <= cgList?.length; i++) {
      if (cgList[i]?.followed === true) {
        console.log(cgList[i]?.id)
        setCgListGroup(oldArray => [...oldArray, cgList[i]?.id]);
      }
    }
  },[cgList])
  useEffect(() => {
    if (generalDetail.is_purchased_group === true) {
      setType(2);
    }
    form.setFieldsValue({
      name: generalDetail.name,
      display_announcements_section:
        generalDetail.display_announcements_section,
      allow_members_post:
        generalDetail.allow_members_post === false ? "owners" : "all",
      display_post_section: generalDetail.display_post_section,
      type: generalDetail.is_purchased_group === true ? 2 : 1,
      level: generalDetail.level === null ? "beginner" : generalDetail.level,
      access: generalDetail.subscription_required,
      // start_date:
      //    generalDetail.start_date !== null && dayjs(generalDetail.start_date),
      // expiry_date:
      //   generalDetail.expiry_date !== null && dayjs(generalDetail.expiry_date),
      sport: generalDetail.sport && generalDetail.sport.name,
      bsa_credit:
        generalDetail.bsa_frequency === "one time"
          ? "one-time"
          : generalDetail.bsa_frequency,
      members_limit: generalDetail.member_limit >= 10000 ? false : true,
      // tags: generalDetail.tags !== null && generalDetail.tags.split(",") ,
      comments: generalDetail.comments,
      description: generalDetail.description,
      expiry: generalDetail.member_code_expiry
    });
    if (generalDetail.display_workout_leaderboard === true) {
      setWorkoutLeaderBoard(true);
    }
    if (generalDetail.display_bsa_leaderboard === true) {
      setBsaLeaderBoard(true);
    }
    if (generalDetail.display_combine_measurement_leaderboard) {
      setCombineLeaderBoard(true);
    }
    if (generalDetail.sport !== null) {
      for (let i = 0; i < sportsList.length; i++) {
        if (sportsList[i].name === generalDetail.sport.name) {
          setSport(sportsList[i].id);
        }
      }
    }
    if (generalDetail.tags !== null) {
      setTags(generalDetail.tags && generalDetail.tags.split(","));
    }
    if (generalDetail.member_limit < 10000) {
      setSeatLimit(true);
    }
    setBsaValue(generalDetail.free_bsa);
    if (generalDetail.avatar) {
      setImgSrc(generalDetail.avatar);
    }
    if (generalDetail.is_curriculum_group) {
      setCurriculum(generalDetail.is_curriculum_group)
    }
    if (generalDetail.bsa_frequency === "one time") {
      setBsaValueOneTime(generalDetail.free_bsa)
      setBsaCredit(2);
    } else if (generalDetail.bsa_frequency === "monthly") {
      setBsaValueEachMonth(generalDetail.free_bsa)
      setBsaCredit(3);
    } else {
      setBsaCredit(1);
    }
    setMemberLimit(
      generalDetail.member_limit === 100000 ? "" : generalDetail.member_limit
    );
  }, [generalDetail]);
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    setImageError(false)
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  };
  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  const cgOptions = [];
  for (let i = 0; i < cgList.length; i++) {
    cgOptions.push({
      value: cgList[i].id,
      label: cgList[i].name,
    });
  }
  return (
    <div className="edit-group-general-details-main">
      <h1>General Details</h1>
      <Divider />
      <Form
        form={form}
        name="dynamic_rule"
        className="details-form"
        onFinish={postEditGroup}
      >
        <Form.Item
          {...formItemLayout}
          name="avatar"
          label="Display Photo"
          className="img-div"
        >
          <div className="image-previewer">
            {generalDetail.avatar !== null && imageLoaded === "" && (
              <Skeleton.Image
                active={true}
                style={{ width: "90px", height: "90px" }}
              />
            )}
            {!imgSrc ? (
              <Avatar
                name={generalDetail.name}
                style={{ marginRight: 10 }}
                color="#F15A29"
                className="initial-avatar"
              />
            ) : (
              <>
                <img
                  src={imgSrc}
                  alt="avatar"
                  className="edit-logo"
                  style={
                    imageLoaded === ""
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  onLoad={() => setImageLoaded("loaded")}
                />
              </>
            )}
            <Upload
              className="avatar-uploader"
              showUploadList={false}
              type="file"
              customRequest={dummyRequest}
              beforeUpload={handleBeforeUpload}
            >
              <img src={Edit} alt="edit" className="edit"></img>
            </Upload>
            <img
              src={Close}
              alt="close"
              className="delete"
              onClick={removeImage}
            />
          </div>
          <span className="img-cond" style={{ marginTop: 10 }}>
            Allowed file types: PNG, JPG, JPEG.
          </span>
          {imageError &&
            <span style={{ color: "red" }}> Please upload group image
            </span>}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="name"
          label="Group Name"
          className="name-row"
          rules={[
            {
              required: true,
              message: "Please enter group name",
            },
          ]}
        >
          <Input
            placeholder="Please input your name"
            className="custom-input"
          />
        </Form.Item>
        <Divider />
        <h2>Curriculum Workouts</h2>

        <Form.Item {...formItemLayout} label="Curriculum Workouts" name={"cg_follow_ids"}>
        {cgList.map((item, i) => {
        return (
          <div key={i} style={{marginBottom:5}}>
            <Checkbox defaultChecked={item.followed} onChange={onChangeCgList} value={item.id} className="custom-checkbox" key={i}>
              {item.name}
            </Checkbox>
          </div>
        );
      })}
        </Form.Item>


        <Divider />
        <h2>Subscription</h2>
        <Form.Item
          {...formItemLayout}
          label="Subscription Status"
          className="subscription-div"
        >
          {generalDetail.group_expiry === false ?
            <Button className="green-tag">Active</Button> :
            <Button className="pink-tag">Inactive</Button>
          }

          <br />
          <div style={{ marginTop: "16px", display: "flex", gap: "8px" }}>
            <Button
              className="orange-tag"
              onClick={() => setSubscriptionHistory(true)}
            >
              Subscription History
            </Button>
          </div>
        </Form.Item>

        <Divider />
        <h2>Announcements</h2>
        <Form.Item
          {...formItemLayout}
          name="display_announcements_section"
          label="Display Announcements Section"
          className="type-div"
        >
          <Radio.Group className="custom-radio">
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          // name="accessible_leaderboards"
          label="Accessible Leaderboards"
          className="sport-div"
        >
          <Checkbox
            style={{ fontSize: "13px", marginRight: "32px" }}
            className="custom-checkbox"
            checked={workoutLeaderBoard}
            onChange={(e) => setWorkoutLeaderBoard(e.target.checked)}
          >
            Workouts/Featured Workouts
          </Checkbox>
          <Checkbox
            style={{ fontSize: "13px", marginRight: "32px" }}
            className="custom-checkbox"
            checked={bsaLeaderBoard}
            onChange={(e) => setBsaLeaderBoard(e.target.checked)}
          >
            BSA
          </Checkbox>

        </Form.Item>

        <Divider style={{ marginBottom: "30px" }} />

        <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
          <Button
            type="primary"
            onClick={() => setGeneralDetail(true)}
            className="discard-btn"
          >
            Discard
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              margin: "0 8px",
            }}
            className="dark-base-btn"
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>
      <SubscriptionHistory
        setSubscriptionHistory={setSubscriptionHistory}
        subscriptionHistory={subscriptionHistory}
      />
    </div>
  );
};
export default EditGroupGeneralDetails;
