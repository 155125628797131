import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Col, Row, Select, Skeleton, Spin, Tabs } from "antd";
import "./group-detail.scss";
import User from "../../../assets/images/dual-user.svg";
import Avatar from "react-avatar";
import EditGroupGeneralDetails from "../edit-group-general-details/edit-group-general-detail";
import OwnersMembers from "../owners-members/owners-members";
// import Workouts from "../../shared/workouts/workouts";
import Workouts from "../workouts-home/workouts-home";
import WorkoutDetails from "../workout-detail/workout-detail";
import Announcements from "../announcements/announcements";
import Posts from "../posts/posts";
import ExistingWorkout from "../existing-workout/existing-workout";
import NewWorkout from "../new-workout/new-workout";
import {
  GroupDetailContextProvider,
  GroupDetailContext,
} from "../../../context/group-context/group-detail-context";
import GroupGeneralDetails from "../group-general-detail/group-general-detail";
const GroupDetailPage = () => {
  const {
    generalDetail,
    loading,
    workoutDetail,
    setWorkoutDetail,
    existingWorkout,
    newWorkout,
    setNewWorkout,
    showGeneralDetails,
    setShowGeneralDetail,
    setExistingWorkout,
    imageLoaded,
    setImageLoaded,
    selectedWorkout,
    setSelectedWorkout,
    viewSubmissions, setViewSubmissions,
    getGroupDetail


  } = useContext(GroupDetailContext);
  const [activeKey , setActiveKey] = useState("general-details")
  const onChange = (value) => {
    setActiveKey(value)
    setWorkoutDetail(false)
    setViewSubmissions(false)    
    if (params.tab === "submission-detail") {
      navigate({
        search: `?id=${generalDetail.id}`,
      });
    }
  };
  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const items = [
    getItem(
      "General Details",
      "general-details",
      showGeneralDetails ? (
        <GroupGeneralDetails
          generalDetail={generalDetail}
          setGeneralDetail={setShowGeneralDetail}
        />
      ) : (
        <EditGroupGeneralDetails
          generalDetail={generalDetail}
          setGeneralDetail={setShowGeneralDetail}
        />
      )
    ),
    getItem("Owners/Members", "owners-members", <OwnersMembers generalDetail={generalDetail} getGroupDetail={getGroupDetail} />),
    getItem(
      "Workouts",
      "workouts",
      workoutDetail || params.tab === "submission-detail" ? (
        <WorkoutDetails setWorkoutDetail={setWorkoutDetail} selectedWorkout={selectedWorkout} setViewSubmissions={setViewSubmissions} viewSubmissions={viewSubmissions} generalDetail={generalDetail} />
      ) : (
        <Workouts
          setWorkoutDetail={setWorkoutDetail}
          setNewWorkout={setNewWorkout}
          setExistingWorkout={setExistingWorkout}
          workoutData={generalDetail.workouts}
          setSelectedWorkout={setSelectedWorkout}
        />
      )
    ),
    getItem("Announcements", "announcements", <Announcements />),
    // getItem("Posts & Comments", "posts", <Posts />),
  ];
  const navigate = useNavigate();
  React.useEffect(() => {
    if (params.tab === "workouts") {
      navigate({
        search: `?id=${generalDetail.id}`,
      });
    }
  }, [params]);
  useEffect(()=>{
    if(activeKey === "general-details" && generalDetail?.length !== 0){
      navigate({
        search: `?id=${generalDetail.id}`,
      });
    }
  }, [generalDetail])
  return (
    <>
      {loading && loading == true ? (
        <Spin
          className="loader"
          size="large"
          style={{
            position: "absolute",
            left: "48%",
            top: "50%",
          }}
        />
      ) : (
        <div className="group-detail-main">
          {existingWorkout ? (
            <ExistingWorkout
              setWorkoutDetail={setWorkoutDetail}
              setExistingWorkout={setExistingWorkout}
              generalDetail={generalDetail}
            />
          ) : newWorkout ? (
            <NewWorkout
              setNewWorkout={setNewWorkout}
              setWorkoutDetail={setWorkoutDetail}
              generalDetail={generalDetail}
            />
          ) : (
            <>
              <Row className="group-detail">
                <div
                  style={{ marginRight: 24 }}
                >
                  {generalDetail.avatar !== null && imageLoaded === "" && (
                    <Skeleton.Image
                      active={true}
                      style={{ width: "120px", height: "120px" }}
                    />
                  )}

                  {generalDetail.avatar === null ? (
                    <Avatar
                      name={generalDetail.name}
                      color="#F15A29"
                      maxInitials={2}
                      className="group-avatar"
                    />
                  ) : (
                    <img
                      src={generalDetail.avatar}
                      className="group-logo"
                      style={
                        imageLoaded === ""
                          ? { display: "none" }
                          : { display: "block" }
                      }
                      onLoad={() => setImageLoaded("loaded")}
                    />
                  )}
                </div>
                <Col span={20} style={{ maxWidth: "85.5%" }}>
                  <div style={{ display: "flex", height: "30px" }}>
                    {" "}
                    <h2 className="group-name">{generalDetail.name}</h2>
                  </div>

                  <div style={{ display: "flex" }}>
                    <img src={User} alt="" />
                    <p className="group-handle">
                      {generalDetail.is_purchased_group === true
                        ? "Paid Public Training Group"
                        : "Team Training Group"}
                    </p>
                  </div>
                  <Row gutter={[16, 16]} className="points-row">
                    <Col span={5}>
                      <span className="points">
                        {generalDetail.members_joined}
                      </span>
                      <br />
                      <span className="category">Member(s)</span>
                    </Col>
                    <Col span={5}>
                      <span className="points">{generalDetail.workouts && generalDetail.workouts.upcoming + generalDetail.workouts.active + generalDetail.workouts.archive}</span>
                      <br />
                      <span className="category">Workout(s)</span>
                    </Col>
                    {/* <Col span={5}>
                      <span className="points">
                        {generalDetail.posts  ? generalDetail.posts.length : "N/A"}
                      </span>
                      <br />
                      <span className="category">Post(s)</span>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
              <div className="group-data">
                <Tabs
                  tabPosition="left"
                  onChange={onChange}
                  type="card"
                  className="group-tabs"
                  items={items}
                  activeKey={activeKey}
                  destroyInactiveTabPane={true}
                  defaultActiveKey={params.tab && params.tab}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const GroupDetail = () => {
  return (
    <GroupDetailContextProvider>
      <GroupDetailPage />
    </GroupDetailContextProvider>
  );
};
export default GroupDetail;
