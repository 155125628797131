import React, { useContext } from "react";
import { Input, Row, Col, Select, Dropdown, Space } from "antd";
import SearchIcon from "../../../assets/images/search.svg";
import Profile from "../../../assets/images/avatar.svg";
import DownArrow from "../../../assets/images/down-arrow.svg";
import { MemberContext } from "../../../context/group-context/members-context";
import "./members.scss"
const {Search }= Input
const Members = () => {
const {
  membersTotal,ownersList,setOwnersList,handleSearchClick ,setMembersTotal, searchMember , setSearch ,searchResult , search , updateMemberStatus} = useContext(MemberContext)

  const items = [
    {
      key: "1",
    //   label: "Make Owner"
    label:"1"
    },
    {
      key: "2",
    //   label: "Remove from group"
    label:"2"
    },
  ];
  return (
    <div className="owners-main">
      <div style={{ display: "flex", width: "100%" }}>
        <h1>All Members</h1>
      </div>
      <div style={{width:"100%"}}>
      <Row className="owner-row">
        <Col span={20} style={{ display: "flex" }}>
          <img src={Profile}></img>
          <div className="name-div">
            <h3 className="user-name">Jacob Jones</h3>
            <h4 className="user-handle">@jacobjones</h4>
          </div>
        </Col>
        <Col span={4}>
          <Dropdown
            className="custom-dropdown"
            menu={{
              items,
              onClick: ({ key }) => {
                if(key === "1"){
                //   updateMemberStatus(item , true);

                }
                else{
                  // updateMemberStatus(item);

                }
              }
          }}             
          overlayClassName="custom-dropdown-menu"
          >
            <a onClick={(e) => e.preventDefault()}>
                Actions
                <img src={DownArrow} />
            </a>
          </Dropdown>
        </Col>
        <hr />
      </Row>
      <Row className="owner-row">
        <Col span={20} style={{ display: "flex" }}>
          <img src={Profile}></img>
          <div className="name-div">
            <h3 className="user-name">Jacob Jones</h3>
            <h4 className="user-handle">@jacobjones</h4>
          </div>
        </Col>
        <Col span={4}>
          <Dropdown
            className="custom-dropdown"
            menu={{
              items,
              onClick: ({ key }) => {
                if(key === "1"){
                //   updateMemberStatus(item , true);

                }
                else{
                  // updateMemberStatus(item);

                }
              }
          }}             overlayClassName="custom-dropdown-menu"
          >
            <a onClick={(e) => e.preventDefault()}>
                Actions
                <img src={DownArrow} />
            </a>
          </Dropdown>
        </Col>
        <hr />
      </Row>
      <Row className="owner-row">
        <Col span={20} style={{ display: "flex" }}>
          <img src={Profile}></img>
          <div className="name-div">
            <h3 className="user-name">Jacob Jones</h3>
            <h4 className="user-handle">@jacobjones</h4>
          </div>
        </Col>
        <Col span={4}>
          <Dropdown
            className="custom-dropdown"
            menu={{
              items,
              onClick: ({ key }) => {
                if(key === "1"){
                //   updateMemberStatus(item , true);

                }
                else{
                  // updateMemberStatus(item);

                }
              }
          }}             overlayClassName="custom-dropdown-menu"
          >
            <a onClick={(e) => e.preventDefault()}>
                Actions
                <img src={DownArrow} />
            </a>
          </Dropdown>
        </Col>
        <hr />
      </Row>
      <Row className="owner-row">
        <Col span={20} style={{ display: "flex" }}>
          <img src={Profile}></img>
          <div className="name-div">
            <h3 className="user-name">Jacob Jones</h3>
            <h4 className="user-handle">@jacobjones</h4>
          </div>
        </Col>
        <Col span={4}>
          <Dropdown
            className="custom-dropdown"
            menu={{
              items,
              onClick: ({ key }) => {
                if(key === "1"){
                //   updateMemberStatus(item , true);

                }
                else{
                  // updateMemberStatus(item);

                }
              }
          }}             overlayClassName="custom-dropdown-menu"
          >
            <a onClick={(e) => e.preventDefault()}>
                Actions
                <img src={DownArrow} />
            </a>
          </Dropdown>
        </Col>
        <hr />
      </Row>
          </div>
    </div>
  );
};

export default Members;
