import React, { useContext } from "react";
import { Row, Col, Divider, Button} from "antd";
import "./group-general-detail.scss";
import { GroupDetailContext } from "../../../coach-context/group-context/group-detail-context";
import { getDateWithoutDay } from "../../../util/config";
import SubscriptionHistory from "../subscription-history/subscription-history";
const GroupGeneralDetails = ({ setGeneralDetail }) => {
  const {
    generalDetail,
    setSubscriptionHistory,
    subscriptionHistory,
  } = useContext(GroupDetailContext);
  const checkGroupPermission = () => {
    setGeneralDetail(false)
  }
  return (
    <div className="group-general-details-main">
      <div style={{ display: "flex" }}>
        <h1>General Details</h1>
        <button
          className="dark-base-btn"
          onClick={checkGroupPermission}
          disabled={generalDetail.group_expiry}
        >
          Edit
        </button>
      </div>
      <Divider orientationMargin={0} />
      <Row className="data-row">
        <Col span={24} style={{ margin: "14px 0" }}>
          <h2>General</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Group Name</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail?.name}
          </h3>
        </Col>
        {/* <Col span={10} className="label-col">
          <h3>Starting on</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {getDateWithoutDay(generalDetail?.subscription?.subscription_start)}
          </h3>
        </Col>     <Col span={10} className="label-col">
          <h3>Ending on</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {getDateWithoutDay(generalDetail?.subscription?.subscription_end)}

          </h3>
        </Col> */}
        <Col span={24} style={{ margin: "40px 0 14px 0" }}>
          <h2>Subscription</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Subscription Status</h3>
        </Col>
        <Col span={14} className="data-col">
          {generalDetail.group_expiry === false ?
            <Button className="green-tag">Active</Button> :
            <Button className="pink-tag">Subscription Expired</Button>
          }
          <br />
          {generalDetail.subscription_required === true && (
            <div style={{ marginTop: "16px", display: "block", gap: "8px" }}>
              <Button
                className="orange-tag"
                onClick={() => setSubscriptionHistory(true)}
                style={{ marginRight: 8, marginBottom: 8 }}
              >
                Subscription History
              </Button>
            </div>
          )}
        </Col>
        <Col span={24} style={{ margin: "40px 0 14px 0" }}>
          <h2>Announcements</h2>
        </Col>
        <Col span={10} className="label-col">
          <h3>Display Announcements Section</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.display_announcements_section === true
              ? "Yes"
              : "No"}
          </h3>
        </Col>
        <Col span={10} className="label-col">
          <h3>Accessible Leaderboards</h3>
        </Col>
        <Col span={14} className="data-col">
          <h3>
            {generalDetail.display_bsa_leaderboard === true ? "BSA," : ""}
            {generalDetail.display_workout_leaderboard === true
              ? "Workouts/Featured Workouts"
              : ""}
          </h3>
        </Col>
      </Row>
      <SubscriptionHistory
        setSubscriptionHistory={setSubscriptionHistory}
        subscriptionHistory={subscriptionHistory}
      />
    </div>
  );
};
export default GroupGeneralDetails;
